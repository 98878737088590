export const navigations = navigationItems()

function navigationItems() {
    
  const menuFromStorage = window.localStorage.getItem('menu')
  const menuArray = [{ name: 'Dashboard', path: '/dashboard', icon: 'dashboard' }]

  if (menuFromStorage === undefined || menuFromStorage === null) { return menuArray } 
  const menu = JSON.parse(menuFromStorage)
  

  for (const [key, value] of Object.entries(menu)) {

    if (value.length >= 0) {
      const childMenuArray = []
      
      {value.map((menu, index) => (
        childMenuArray.push({ name: menu.menu_name, iconText: '', path: menu?.menu_link ?? ""})
      ))}

      menuArray.push({
        name: key,
        icon: '',
        children: childMenuArray})

    } else {
       menuArray.push({ name: key, iconText: '' })
    }
  }
    return menuArray
 
};