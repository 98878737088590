import { Tooltip } from "@material-ui/core";
import { Button, Card, Dialog, Divider, Fab, Grid, Icon, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, ThemeProvider, createTheme, styled } from "@mui/material";
import { Box } from "@mui/system";
import { ConfirmationDialog, Loader } from "app/components";
import { FlexBox } from "app/components/FlexBox";
import { getRABillItemsForVerification } from "app/redux/actions/ImplementationActions";
import { deleteRaBillRecoveryDetails, deleteRaBillRecoveryDetailsDom, getAllRaBillRecoveryList, getApprovedRaBillForRecovery, saveOrUpdateRaBillRecovery, saveOrUpdateRaBillRecoveryDetailsDoM, updateBalanceQuantity } from "app/redux/actions/RABillActions";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { DecimalInputField, NumberFormatCustom, checkValidation, currencyFormat, getTwoDigitDecimal, goToViolation, validateTwoDigitDecimal } from "app/utils/utils";
import { H3, H4 } from "components/Typography";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";

const theme = createTheme({

  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#87CEEB",
        }
      }
    }
  }
});

const MyTable = styled(Table)(() => ({
  minWidth: 750,
  whiteSpace: "pre",
  "& thead": { "& th:first-of-type": { paddingLeft: 16 } },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" },
}));


const RABillRecoveryDialog = ({ open, raBill, project, handleSuccess, handleClose }) => {

  const dispatch = useDispatch();
  const [dialogTitle, setdialogTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showRABillList, setShowRABillList] = useState()
  const [raBillDetailsList, setRaBillDetailsList] = useState()
  const [shouldOpenRaBillDetailsList, setShouldOpenRaBillDetailsList] = useState(false)
  const [shouldShowDOM, setShouldShowDOM] = useState(false)
  const [domList, setDomList] = useState([])
  const [domDetailsData, setDomDetailsData] = useState(null)
  const [raBillDetails, setRaBillDetails] = useState()
  const [shouldOpenDOMDeleteDialog, setShouldOpenDOMDeleteDialog] = useState(false)

  const { approvedRaBillList, recoveredRaBillList } = useSelector((state) => state.raBill);
  const [selectedRaBill, setSelectedRaBill] = useState()
  const [subTotalDomQuantity, setSubTotalDomQuantity] = useState(0)
  const [deductionsList, setDeductionsList] = useState(['+', '-'])

  const [previousQuantity, setPreviousQuantity] = useState(0)

  const [shouldOpenApprovedRaBillDetailsList, setShouldOpenApprovedRaBillDetailsList] = useState(false)
  const [allRowsSelected, setAllRowSelected] = useState([])
  const [shouldOpenRecoveryDetailsDeleteDialog, setShouldOpenRecoveryDetailsDeleteDialog] = useState(false)



  const [state, setState] = useState({
    project_name: '',
    project_location_id: '',
    project_location: '',
    project_id: '',
  })

  const raBillDetailsColumns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (rowIndex) => rowIndex + 1
      },
    }, {
      name: "constructiontype_name",
      label: "Construction Type",
      options: { filter: true, sort: false, },
    }, {
      name: "construction_name",
      label: "Construction Name",
      options: { filter: true, sort: false, },
    },
    {
      name: "boq_name",
      label: "Name",
      options: { filter: true, sort: false, },
    },
    {
      name: "item_code",
      label: "Item Code",
      options: { filter: true, sort: false, },
    }, {
      name: "contract_quantity",
      label: "Con. Quantity",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.contract_quantity)
       },
    }, {
      name: "contract_rate",
      label: "Con. Rate(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.contract_rate)
       },
    },
    {
      name: "new_quantity",
      label: "New Quantity",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.new_quantity)
      }
    }, {
      name: "deviation_quantity",
      label: "Dev. Quantity",
      options: {
        filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => (
          <p style={{ color: (raBillDetailsList[rowIndex]?.deviation_quantity ?? 0) > 0 ? "#D2042D" : "#00000" }}>{currencyFormat(raBillDetailsList[rowIndex]?.deviation_quantity)}</p>
        )
      }
    }, {
      name: "revised_rate",
      label: "Rev. Rate(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.revised_rate)
       },
    }, {
      name: "contract_amount",
      label: "Con. Amount(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.contract_amount)
       },
    }, {
      name: "deviation_amount",
      label: "Dev. Amount(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.deviation_amount)
       },
    }
  ]


  const raBillRecoveryColumns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (rowIndex) => rowIndex + 1
      },
    }, {
      name: "constructiontype_name",
      label: "Construction Type",
      options: { filter: true, sort: false, },
    }, {
      name: "construction_name",
      label: "Construction Name",
      options: { filter: true, sort: false, },
    },
    {
      name: "boq_name",
      label: "Name",
      options: { filter: true, sort: false, },
    },
    {
      name: "item_code",
      label: "Item Code",
      options: { filter: true, sort: false, },
    }, {
      name: "contract_rate",
      label: "Con. Rate(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.contract_rate)
       },
    }, {
      name: "executed_quantity",
      label: "Executed Quantity",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.executed_quantity)
      }
    }, {
      name: "new_quantity",
      label: "New Quantity",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.new_quantity)
       }
    }, {
      name: "deviation_quantity",
      label: "Dev. Quantity",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.deviation_quantity)
       }
    }, {
      name: "revised_rate",
      label: "Rev. Rate(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.revised_rate)
       },
    }, {
      name: "deviation_amount",
      label: "Dev. Amount(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.deviation_amount)
       }
    },
    {
      name: "recovered_quantity",
      label: "Rec. Quantity",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.recovered_quantity)
       },
    }, {
      name: "recovered_amount",
      label: "Rec. Amount(Nu)",
      options: { filter: true, sort: false,
        customBodyRenderLite: (rowIndex) => currencyFormat(raBillDetailsList[rowIndex]?.recovered_amount)
       }
    },
    {
      name: "dom",
      label: "DoM",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex) => (
          <FlexBox alignItems="center">
            <Tooltip title="Dom">
              <IconButton onClick={() => handleClickManageDom(recoveredRaBillList?.raBillRecoveryDetailsList[rowIndex], rowIndex)}>
                <Icon color="primary" fontSize="small">
                  info
                </Icon>
              </IconButton>
            </Tooltip>
          </FlexBox>

        ),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex) => (
          <FlexBox alignItems="center">
            <Tooltip title="Action">
              <IconButton onClick={() => handleClickRecoveryDelete(recoveredRaBillList?.raBillRecoveryDetailsList[rowIndex], rowIndex)}>
                <Icon color="error" fontSize="small">
                  delete
                </Icon>
              </IconButton>
            </Tooltip>
          </FlexBox>

        ),
      },
    }

  ]

  const handleClickRecoveryDelete = (element, index) => {
    setRaBillDetails(element)
    setPreviousQuantity(element?.recovered_quantity)
    setShouldOpenRecoveryDetailsDeleteDialog(true)
  }

  useEffect(() => {
    fetchApiDatas()
  }, []);

  const fetchApiDatas = async () => {
    dispatch(getAllRaBillRecoveryList(raBill?.runningaccountbill_id, showLoader, false, showToast, handleRaBillRecoverySuccess))
    await new Promise((resolve) => setTimeout(resolve, 1000));

    dispatch(getApprovedRaBillForRecovery(project?.project_location_id, false, hideLoader, showToast))

  }

  const handleRaBillRecoverySuccess = () => {

  }

  const showLoader = () => {
    setLoading(true)
  }

  const hideLoader = () => {
    setLoading(false)
  }

  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }


  const clearDatas = () => {
    setShowRABillList(false)
    setShouldOpenRaBillDetailsList(false)
    setShouldShowDOM(false)
  }

  const handleChange = (event) => {
    clearDatas()
    if (event.target.name === "rabill_no") {
      const filteredArray = approvedRaBillList.filter(ra => {
        return ra.rabill_no === event.target.value
      });
      setSelectedRaBill(filteredArray[0])
      console.log(filteredArray[0])
      getAlRunningAccountBillDetailsListByParams(filteredArray[0]?.runningaccountbill_id, filteredArray[0]?.project_location_id)
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }

  function checkIfOtherDeviationPresent(item_code, constructiontype_id, deviation_quantity) {
    if (deviation_quantity === 0) {
      const filteredArray = raBillDetailsList?.filter((element) => {
        return element.constructiontype_id === constructiontype_id && element.item_code === item_code && element.deviation_quantity > 0
      })
      if (filteredArray.length > 0) {
        const key = enqueueSnackbar("There is deviation for this item. Please recover first from deviated quantity", { variant: 'info' });
        SnackBarProperty.info(key, closeSnackbar);
        return
      }
    }
  }

  const onClickSaveButton = () => {
    if (recoveredRaBillList?.raBillRecoveryDetailsList?.length === 0) {
      const key = enqueueSnackbar("Please add at least one item to proceed", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }

    saveOrUpdateRaBillRecoveryByParams('save')
  }

  function createParamsFoRABillRecovery(type = 'save') {
    const totalsRowsSelections = allRowsSelected?.map((e) => e.index)

    const recoveredRaBillDetailsList = recoveredRaBillList?.raBillRecoveryDetailsList?.map((e) => e.runningaccountbill_details_id)

    const filteredDetailsList = raBillDetailsList?.filter((item, itemIndex) => {
      return totalsRowsSelections?.includes(itemIndex) && !recoveredRaBillDetailsList?.includes(item?.runningaccountbill_details_id)
    });

    var i = 0;
    while (i < filteredDetailsList?.length) {
      filteredDetailsList[i].rabillrecovery_id = recoveredRaBillList?.rabillrecovery_id
      i++;
    }

    const params = {
      runningaccountbill_id: raBill?.runningaccountbill_id,
      project_location_id: raBill?.project_location_id,
      recovery_amount: calculateTotalRecoveryAmount(),
      raBillRecoveryDetailsList: type === 'save' ? recoveredRaBillList?.raBillRecoveryDetailsList : filteredDetailsList
    }
    if (checkValidation(recoveredRaBillList?.rabillrecovery_id)) {
      params.rabillrecovery_id = recoveredRaBillList?.rabillrecovery_id
    }
    return params
  }

  function createParamsFoRABillRecoveryDomDelete(id, quantity, newDomArray) {
    const newRecoveredRaBillList = cloneDeep(recoveredRaBillList)
    if (checkValidation(newRecoveredRaBillList?.raBillRecoveryDetailsList)) {
        console.log('china', newRecoveredRaBillList)
        if (newRecoveredRaBillList?.raBillRecoveryDetailsList?.length > 0) {
          console.log('india', id)

          newRecoveredRaBillList?.raBillRecoveryDetailsList?.forEach((element, index) => {
              if (element?.rabillrecovery_details_id === id) {
                console.log('yo')
                newRecoveredRaBillList.raBillRecoveryDetailsList[index].recovered_quantity = quantity
                newRecoveredRaBillList.raBillRecoveryDetailsList[index].raBillRecoveryDetailsDomList = newDomArray
              }
          })
        }
    }
    console.log(newRecoveredRaBillList)
    return newRecoveredRaBillList
  }

  const saveOrUpdateRaBillRecoveryForDomByParams = (id, quantity, newDomArray) => {
    setLoading(true)
    saveOrUpdateRaBillRecovery(createParamsFoRABillRecoveryDomDelete(id, quantity, newDomArray)).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success) {
        updateBalanceDatas(quantity)
        // dispatch(getAllRaBillRecoveryList(raBill?.runningaccountbill_id, showLoader, hideLoader, showToast, handleRaBillRecoverySuccess))
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  function calculateTotalRecoveryAmount() {
    if (checkValidation(recoveredRaBillList?.raBillRecoveryDetailsList) === false) {
      return 0
    }
    if (recoveredRaBillList?.raBillRecoveryDetailsList?.length === 0) {
      return 0
    }
    const totalCost = recoveredRaBillList?.raBillRecoveryDetailsList?.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue?.recovered_amount, 0);
    return getTwoDigitDecimal(totalCost)
  }

  const saveOrUpdateRaBillRecoveryByParams = (type) => {
    setLoading(true)
    saveOrUpdateRaBillRecovery(createParamsFoRABillRecovery(type)).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success) {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

        setState({ ...state, ["rabill_no"]: '' });
        setShouldOpenApprovedRaBillDetailsList(false)
        if (type === 'save') {
          // updateBalanceDatas()
          handleSuccess()
        } else {
          dispatch(getAllRaBillRecoveryList(raBill?.runningaccountbill_id, showLoader, hideLoader, showToast, handleRaBillRecoverySuccess))
        }
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const onClickCancelButton = () => {
    handleClose()
  }


  const getAlRunningAccountBillDetailsListByParams = (rabill_id, project_location_id) => {
    setLoading(true)
    getRABillItemsForVerification(rabill_id, project_location_id).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success) {
        // setRaBillDetailsList(res?.data?.obj)
        calculateRaBillValues(res?.data?.obj)
        setShouldOpenApprovedRaBillDetailsList(true)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    });;
  }


  useEffect(() => {
    if (shouldShowDOM === true) {
      goToViolation("manage_dom")
    }
  }, [shouldShowDOM]);


  const onClickCloseDom = () => {
    setShouldShowDOM(false)
  }


  const deleteDomItem = (index, type = 'calculation', recovered_details_id) => {
    const newDomArray = [...domList]
    const filteredArray = newDomArray?.filter((item, itemIndex) => {
      return itemIndex !== index
    });
    setDomList(filteredArray)
    if (type === 'domDelete') {
      console.log(filteredArray, domList)
      calculateSubTotalQuantity(filteredArray, 'domDelete', recovered_details_id)
    }
  }

  const handleDOMDialogClose = () => {
    setShouldOpenDOMDeleteDialog(false)
  }

  const handleRecoveryDetailsDialogClose = () => {
    setShouldOpenRecoveryDetailsDeleteDialog(false)
  }

  const handleRecoveryDetailsConfirmationResponse = () => {
    deleteRaBillRecoveryDetailsByParams(raBillDetails?.rabillrecovery_details_id)
  }

  const handleDOMConfirmationResponse = () => {
    deleteBoqDOM(domDetailsData?.id, domDetailsData?.index, domDetailsData?.rabillrecovery_details_id)
  }

  const deleteBoqDOM = (id, index, recovered_details_id) => {
    setLoading(true)
    deleteRaBillRecoveryDetailsDom(id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        deleteDomItem(index, 'domDelete', recovered_details_id)
        setShouldOpenDOMDeleteDialog(false)
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (err) {
      setLoading(false)
      const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const deleteRaBillRecoveryDetailsByParams = (id) => {
    setLoading(true)
    deleteRaBillRecoveryDetails(id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        setShouldOpenRecoveryDetailsDeleteDialog(false)
        updateBalanceDatas(0)
        // dispatch(getAllRaBillRecoveryList(raBill?.runningaccountbill_id, showLoader, hideLoader, showToast, handleRaBillRecoverySuccess))
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (err) {
      setLoading(false)
      const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  const handleDomItemChange = (type, index, value) => {

    const newDomArray = [...domList]

    if (type === 'item_no') {
      if (validateTwoDigitDecimal(value) === false || value === '0') { return }
      newDomArray[index].item_no = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'length') {
      if (validateTwoDigitDecimal(value) === false || value === '0') { return }
      newDomArray[index].length = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'breadth') {
      if (validateTwoDigitDecimal(value) === false || value === '0') { return }
      newDomArray[index].breadth = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'height') {
      if (validateTwoDigitDecimal(value) === false || value === '0') { return }
      newDomArray[index].height = value
      newDomArray[index].quantity = getTwoDigitDecimal(multiplyValues(newDomArray[index].item_no, newDomArray[index].length, newDomArray[index].breadth, newDomArray[index].height))
    } else if (type === 'remarks') {
      newDomArray[index].remarks = value
    } else if (type === 'item_sign') {
      newDomArray[index].item_sign = value
    }
    calculateSubTotalQuantity(newDomArray)
    setDomList(newDomArray)
  }

  const onClickAddNewDomRow = () => {
    if (checkValidation(domList) === false) {
      setDomList([{}])
    } else {
      setDomList([...domList, {}])
    }
  }

  const calculateSubTotalQuantity = (newDomArray, type = 'calculation', recovered_details_id, time = 'others') => {
    var amount = 0
    newDomArray?.forEach((dom, index) => {
      if (dom?.item_sign === "-") {
        amount += -(dom?.quantity)
      } else {
        amount += dom?.quantity
      }
    });
    setSubTotalDomQuantity(getTwoDigitDecimal(amount))
    if (time === 'first') {
      setPreviousQuantity(getTwoDigitDecimal(amount))
    }
    if (type === 'domDelete') {
      saveOrUpdateRaBillRecoveryForDomByParams(recovered_details_id, amount, newDomArray)
    }

  }

  function multiplyValues(item_no, length, breadth, height) {

    if (checkValidation(height) === false && (checkValidation(item_no) === false)
      && (checkValidation(length) === false) && (checkValidation(breadth) === false)) {
      return ""
    }

    var itemNoFloated = 0
    if (checkValidation(item_no) === false) {
      itemNoFloated = 1
    } else {
      itemNoFloated = parseFloat(item_no)
    }

    var heightFloated = 0
    if (checkValidation(height) === false) {
      heightFloated = 1
    } else {
      heightFloated = parseFloat(height)
    }


    var lengthFloated = 0
    if (checkValidation(length) === false) {
      lengthFloated = 1
    } else {
      lengthFloated = parseFloat(length)
    }

    var breadthFloated = 0
    if (checkValidation(breadth) === false) {
      breadthFloated = 1
    } else {
      breadthFloated = parseFloat(breadth)
    }

    return (itemNoFloated * heightFloated * lengthFloated * breadthFloated)


  }

  const onClickSaveDOMDetails = () => {
    console.log('subTotalDomQuantity', subTotalDomQuantity)
    console.log('executed_quantity', raBillDetails?.executed_quantity)
    if (subTotalDomQuantity > raBillDetails?.executed_quantity) {
      const key = enqueueSnackbar("Dom quantity should be less than executed quantity", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }

    const newDomArray = [...domList]

    if (newDomArray?.length === 0) {
      const key = enqueueSnackbar("Please add at least one item to proceed", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }

    const filteredArray = newDomArray?.filter(dom => {
      return ((checkValidation(dom.height) === false)
        && (checkValidation(dom.item_no) === false)
        && (checkValidation(dom.length) === false)
        && (checkValidation(dom.breadth) === false))
    });
    console.log('obsolete filtered arrya', filteredArray)
    if (filteredArray.length > 0) {
      const key = enqueueSnackbar("PLease fill all DOM details", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }

    if (subTotalDomQuantity <= 0) {
      const key = enqueueSnackbar("Please check your dom quantity", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
      return
    }
    console.log('rabill details', raBillDetails)

    var i = 0;
    while (i < newDomArray?.length) {
      newDomArray[i].rabillrecovery_details_id = raBillDetails?.rabillrecovery_details_id
      newDomArray[i].rabillrecovery_id = raBillDetails?.rabillrecovery_id
      newDomArray[i].item_sign = checkValidation(newDomArray[i].item_sign) ? newDomArray[i].item_sign : "+"
      i++;
    }

    saveUpdateBoqDOM(newDomArray)
  }

  const saveUpdateBoqDOM = (params) => {
    setLoading(true)
    saveOrUpdateRaBillRecoveryDetailsDoM(params).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        setShouldShowDOM(false)
        updateBalanceDatas(subTotalDomQuantity)
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  function calculateQuantities(totalQuantity) {

    var balance_dev_quantity = raBillDetails?.balance_dev_quantity ?? 0
    var balance_new_quantity = raBillDetails?.balance_new_quantity ?? 0

    if (previousQuantity > 0) {
      if (balance_dev_quantity === 0 && balance_new_quantity === 0) {}
      else {
      if (raBillDetails?.deviation_quantity > 0) {
        balance_dev_quantity = balance_dev_quantity - raBillDetails?.deviation_quantity
      }
      if (raBillDetails?.new_quantity > 0) {
        balance_new_quantity = balance_new_quantity - (previousQuantity - raBillDetails?.deviation_quantity ?? 0)
        }
      }
    }
        
    if (totalQuantity === 0) {
    } else if (totalQuantity <= raBillDetails?.deviation_quantity) {
      balance_dev_quantity = balance_dev_quantity + totalQuantity
      // balance_new_quantity = balance_new_quantity
    } else {
      balance_dev_quantity = balance_dev_quantity + raBillDetails?.deviation_quantity
      balance_new_quantity = balance_new_quantity + (totalQuantity - raBillDetails?.deviation_quantity)
    }

    return {balance_new_quantity: balance_new_quantity, balance_dev_quantity: balance_dev_quantity}
  } 

  const updateBalanceDatas = (quantity) => {
    // const params = []
    // recoveredRaBillList?.raBillRecoveryDetailsList?.forEach((element, index) => {
    //   const paramsElement = {
    //     runningaccountbill_details_id: element?.runningaccountbill_details_id,
    //     balance_new_quantity: element?.new_quantity,
    //     balance_dev_quantity: element?.deviation_quantity,
    //     balance_executed_quantity: element?.executed_quantity
    //   }
    //   params?.push(paramsElement)
    // })
    const quantityDict = calculateQuantities(quantity)
    const params = [{
      runningaccountbill_details_id: raBillDetails?.runningaccountbill_details_id,
      balance_new_quantity: quantityDict?.balance_new_quantity,
      balance_dev_quantity: quantityDict?.balance_dev_quantity,
      balance_executed_quantity: quantityDict?.balance_executed_quantity
    }]
    
    updateBalanceQuantityByParams(params)
  }

  const updateBalanceQuantityByParams = (params) => {
    setLoading(true)
    updateBalanceQuantity(params).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        setShouldShowDOM(false)
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);
        dispatch(getAllRaBillRecoveryList(raBill?.runningaccountbill_id, showLoader, hideLoader, showToast, handleRaBillRecoverySuccess))
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const calculateRaBillValues = (raBillDetailsList) => {
    const newRaBillDetails = [...raBillDetailsList]

    newRaBillDetails?.forEach((element, index) => {
        const balance_dev_quantity = element?.balance_dev_quantity ?? 0
        const balance_new_quantity = element?.balance_new_quantity ?? 0

        const latest_new_quantity =  element?.new_quantity - balance_new_quantity
        const latest_deviation_quantity =  element?.deviation_quantity - balance_dev_quantity
        console.log('new quantity', latest_new_quantity, latest_deviation_quantity)

        newRaBillDetails[index].new_quantity = latest_new_quantity
        newRaBillDetails[index].deviation_quantity = latest_deviation_quantity
        
    })
    console.log('new details', newRaBillDetails)
    setRaBillDetailsList(newRaBillDetails)
  }

  const handleClickManageDom = (raBill) => {
    setRaBillDetails(raBill)
    setShouldShowDOM(true)
    setDomList(raBill?.raBillRecoveryDetailsDomList)
    calculateSubTotalQuantity(raBill?.raBillRecoveryDetailsDomList, 'calculation', null, 'first' )
  }

  const onClickFirstSaveButton = () => {
    saveOrUpdateRaBillRecoveryByParams('others')
  }

  const onClickFirstCancelButton = () => {
    setState({ ...state, ["rabill_no"]: '' });
    setShouldOpenApprovedRaBillDetailsList(false)
  }

  const handleDomDeleteClick = (id, index, details_id) => {
    if (id !== null && id !== undefined && id !== "") {
      setShouldOpenDOMDeleteDialog(true)
      setDomDetailsData({ id: id, index: index, rabillrecovery_details_id: details_id })
    } else {
      deleteDomItem(index)
    }
  }


  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <Box p={3}>
        <H4 sx={{ mb: "20px" }}><b>RA Bill Recovery Details</b></H4>
        <Divider sx={{ mb: 3 }} />
        <H3 sx={{ mb: "20px" }}>RA Bill No: {raBill?.rabill_no}</H3>
        <TextField
          select
          size="small"
          sx={{ width: '100px', mb: 3 }}
          label="RA Bill No."
          id="rabill_no"
          variant="outlined"
          name="rabill_no"
          value={state?.rabill_no || ''}
          onChange={handleChange}
        >
          {approvedRaBillList?.map((item) => (
            <MenuItem value={item?.rabill_no} key={item?.runningaccountbill_id}>
              {item?.rabill_no}
            </MenuItem>
          ))}
        </TextField>

        {shouldOpenApprovedRaBillDetailsList && (
          <Card sx={{ width: "100%", overflow: "auto", mb: 3 }} elevation={6}>
            <H3 style={{ marginLeft: 10, marginBottom: 10, marginTop: 15 }}><b style={{ color: 'red' }}>Recovery has to be done from deviated quantity first!!!</b></H3>
            <Divider sx={{ mb: 3 }} />
            <H4 style={{ marginLeft: 10, marginBottom: 10, marginTop: 15 }}>RA Bill No: {state?.rabill_no}</H4>

            <Divider></Divider>
            <div style={{ overflowX: "auto", marginBottom: 15 }}>
              <ThemeProvider theme={theme}>
                <MUIDataTable
                  sx={{
                    backgroundColor: "yellow",
                    borderBottom: "2px solid black",
                    "& th": {
                      fontSize: "1.25rem",
                      color: "rgba(96, 96, 96)"
                    }
                  }}
                  data={raBillDetailsList}
                  columns={raBillDetailsColumns}
                  title={""}
                  options={{
                    fixedHeader: true,
                    responsive: 'scroll',
                    filterType: "textField",
                    pagination: false, //set pagination option
                    rowsPerPageOptions: [10, 15, 20, 25, 50, 100],
                    search: false, // set search option
                    filter: false, // set data filter option
                    download: false, // set download option
                    print: false, // set print option
                    delete: false,
                    viewColumns: false,
                    selectableRows: 'multiple',
                    selectableRowsHeader: true,
                    selectToolbarPlacement: 'none',
                    onRowsSelect: (curRowSelected, allRowsSelected) => {
                      if (curRowSelected.length === 1) {
                        const currentIndex = curRowSelected[0]?.index ?? 0
                        checkIfOtherDeviationPresent(raBillDetailsList[currentIndex]?.item_code, raBillDetailsList[currentIndex]?.constructiontype_id, raBillDetailsList[currentIndex]?.deviation_quantity)
                      }
                      setAllRowSelected(allRowsSelected)
                    },
                    setRowProps: (row, dataIndex) => {
                      // Access row data using the dataIndex to get the correct row object
                      const currentRow = raBillDetailsList[dataIndex];
  
                      // Highlight row if deviation_quantity is greater than 0
                      if (currentRow.deviation_quantity > 0) {
                        return {
                          style: {
                            backgroundColor: '#FFD580', // Highlight color
                          },
                        };
                      }
                      return {};
                    },
                  }}
                />
              </ThemeProvider>
            </div>
            <Box style={{ marginBottom: 15 }} component="span" display="flex" justifyContent="flex-end" >
              <Button style={{ marginLeft: 10 }} size="medium" color="primary" variant="contained" type="submit" onClick={() => onClickFirstSaveButton("save")}>Save</Button>
              &nbsp;&nbsp;&nbsp;
              <Button size="medium" color="error" variant="contained" onClick={() => onClickFirstCancelButton()}>Close</Button>&nbsp;
            </Box>
          </Card>
        )}

        <Card sx={{ width: "100%", overflow: "auto" }} elevation={6}>
          <H3 style={{ marginLeft: 10, marginBottom: 10, marginTop: 15 }}><b>Recovered RA Bill Details</b></H3>
          <Divider sx={{ mb: 3 }} />
          <div style={{ overflowX: "auto", marginBottom: 15 }}>
            <ThemeProvider theme={theme}>
              <MUIDataTable
                sx={{
                  backgroundColor: "yellow",
                  borderBottom: "2px solid black",
                  "& th": {
                    fontSize: "1.25rem",
                    color: "rgba(96, 96, 96)"
                  }
                }}
                data={recoveredRaBillList?.raBillRecoveryDetailsList}
                columns={raBillRecoveryColumns}
                title={""}
                options={{
                  fixedHeader: true,
                  responsive: 'scroll',
                  selectableRows: "none", // set checkbox for each row
                  filterType: "textField",
                  pagination: false, //set pagination option
                  rowsPerPageOptions: [10, 15, 20, 25, 50, 100],
                  search: false, // set search option
                  filter: false, // set data filter option
                  download: false, // set download option
                  print: false, // set print option
                  viewColumns: false
                }}
              />
            </ThemeProvider>
            <h3 style={{ margin: 15 }}>Total Recovered Amount: {calculateTotalRecoveryAmount()}</h3>
          </div>

          {/* <Divider sx={{ mb: 3 }} />
          <div>
            <MyTable>
              <div style={{ pageBreakAfter: 'auto' }}></div>
              <TableHead style={{ backgroundColor: '#efefef' }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell rows="4" colSpan={"4"} align="center">Sub Total Contract Amount</TableCell>
                  <TableCell colSpan={"1"} align="right">{calculateSubTotalContractAmount()}</TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell rows="4" colSpan={"4"} align="center">Sub Total Dev. Amount</TableCell>
                  <TableCell colSpan={"1"} align="right">{calculateSubTotalDeviationAmount()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell rows="4" colSpan={"4"} align="center">Amendment Amount</TableCell>
                  <TableCell colSpan={"1"} align="right">{raBill?.total_amendment_amount ?? 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell rows="4" colSpan={"4"} align="center">Recovery Amount</TableCell>
                  <TableCell colSpan={"1"} align="right">{raBill?.total_recovery_amount ?? 0}</TableCell>
                </TableRow>
              </TableHead>
              <TableHead style={{ backgroundColor: '#efefef' }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell rows="4" colSpan={"4"} align="center">Total Amount</TableCell>
                  <TableCell colSpan={"1"} align="right">{calculateTotalAmount()}</TableCell>
                </TableRow>
              </TableHead>
            </MyTable>
            <br />
            {raBill.status === ProjectStatus.CREATED && (

              <Box component="span" display="flex" justifyContent="flex-start" >
                <Button size="medium" color="success" variant="contained" onClick={() => handleShowRABillAmendmentDialog()}>Amendment</Button>&nbsp;&nbsp;
                <Button size="medium" color="success" variant="contained" onClick={() => handleShowRABillRecoveryDialog()}>Recovery</Button>&nbsp;
              </Box>
            )}
            <br /> */}
          <Box style={{ marginBottom: 15 }} component="span" display="flex" justifyContent="flex-end" >
            <Button style={{ marginLeft: 10 }} size="medium" color="primary" variant="contained" type="submit" onClick={() => onClickSaveButton("save")}>Save</Button>
            &nbsp;&nbsp;&nbsp;
            <Button size="medium" color="error" variant="contained" onClick={() => onClickCancelButton()}>Close</Button>&nbsp;
          </Box>
          {/* </div> */}
          {/* <br /> */}
        </Card>

        {
          shouldShowDOM && (

            <div className="manage_dom">
              <Card sx={{ width: "100%", marginTop: 5, overflow: "auto" }} elevation={6}>
                <Grid container spacing={5}>
                  <Grid sx={{ marginLeft: 2 }} item md={12} sm={12} xs={12}>
                    <h2 >Manage DOM</h2>
                    <h4>Item Code: {raBillDetails?.item_code}</h4>
                  </Grid>
                </Grid>
                <Box width="100%" overflow="auto">
                  <MyTable>
                    <TableHead style={{ backgroundColor: "#87CEEB" }} >
                      <TableRow >
                        <TableCell align="center" width="2%">Sl No.</TableCell>
                        <TableCell align="center" width="8%">No.</TableCell>
                        <TableCell align="center" width="12%">Length</TableCell>
                        <TableCell align="center" width="10%">Breadth</TableCell>
                        <TableCell align="center" width="10%">Height</TableCell>
                        <TableCell align="center" width="4%">Deductions</TableCell>
                        <TableCell align="center" width="8%">Quantity</TableCell>
                        <TableCell align="center" width="8%">Remarks</TableCell>
                        <TableCell align="center" width="8%">Action</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {domList?.map((dom, index) => (
                        <TableRow>
                          <TableCell sx={{ px: 0 }} align="center">
                            {index + 1}
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="No."
                              id="item_no"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              name="item_no"
                              value={dom?.item_no || ""}
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e" || event?.key?.toLowerCase() === ".") {
                                  event.preventDefault();
                                }

                              }}
                              onChange={e =>
                                handleDomItemChange('item_no', index, e.target.value)
                              }
                            ></DecimalInputField>
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="Length"
                              id="length"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              name="length"
                              value={dom?.length || ""}
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                                  event.preventDefault();
                                }

                              }}
                              onChange={e =>
                                handleDomItemChange('length', index, e.target.value)
                              }
                            >
                            </DecimalInputField>
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="Breadth"
                              id="breadth"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              name="breadth"
                              value={dom?.breadth || ""}
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                                  event.preventDefault();
                                }

                              }}
                              onChange={e =>
                                handleDomItemChange('breadth', index, e.target.value)
                              }
                            >
                            </DecimalInputField>
                          </TableCell>

                          <TableCell sx={{ ml: 0 }} align="center">
                            <DecimalInputField
                              size="small"
                              label="Height"

                              id="height"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              name="height"
                              value={dom?.height || ""}
                              onKeyDown={(event) => {
                                if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                                  event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                                  event.preventDefault();
                                }

                              }}
                              onChange={e =>
                                handleDomItemChange('height', index, e.target.value)
                              }
                            >
                            </DecimalInputField>
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <TextField
                              select
                              size="small"
                              id="item_sign"
                              variant="outlined"
                              label=""
                              fullWidth
                              name="item_sign"
                              value={dom?.item_sign}
                              defaultValue="+"
                              onChange={e =>
                                handleDomItemChange('item_sign', index, e.target.value)
                              }
                            >
                              {deductionsList?.map((item) => (
                                <MenuItem value={item} key={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            {dom?.quantity}
                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <TextField
                              size="small"
                              label="Remarks"
                              id="remarks"
                              variant="outlined"
                              fullWidth
                              name="remarks"
                              value={dom?.remarks || ""}
                              onChange={e =>
                                handleDomItemChange('remarks', index, e.target.value)
                              }
                            >
                            </TextField>

                          </TableCell>

                          <TableCell sx={{ px: 0 }} align="center">
                            <Tooltip title="Remove">
                              <IconButton onClick={() => handleDomDeleteClick(dom?.rabillrecovery_details_dom_id, index, dom?.rabillrecovery_details_id)}>
                                <Icon color="error" fontSize="small">
                                  delete
                                </Icon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </MyTable>
                  <h4 style={{ margin: 15 }}>Sub Total Quantity: {subTotalDomQuantity}</h4>
                  <Box component="span" m={1} display="flex" justifyContent="flex-end" >
                    <Fab size="small" color="secondary" variant="contained" title="Add new row" onClick={() => onClickAddNewDomRow()}>
                      <Icon>add</Icon>
                    </Fab>
                  </Box>
                  <Box component="span" m={1} display="flex" justifyContent="flex-end" >
                    <Button size="medium" color="primary" variant="contained" onClick={() => onClickSaveDOMDetails()}>Save</Button>&nbsp;
                    <Button size="medium" color="error" variant="contained" onClick={() => onClickCloseDom()}>Close</Button>
                  </Box>
                </Box>
              </Card>
            </div>

          )
        }

        {
          shouldOpenDOMDeleteDialog && (
            <ConfirmationDialog
              text="Are you sure to delete?"
              open={shouldOpenDOMDeleteDialog}
              onConfirmDialogClose={handleDOMDialogClose}
              onYesClick={handleDOMConfirmationResponse}
            />
          )
        }

        {
          shouldOpenRecoveryDetailsDeleteDialog && (
            <ConfirmationDialog
              text="Are you sure to delete?"
              open={shouldOpenRecoveryDetailsDeleteDialog}
              onConfirmDialogClose={handleRecoveryDetailsDialogClose}
              onYesClick={handleRecoveryDetailsConfirmationResponse}
            />
          )
        }

        {
          loading && (
            <Loader
              show={loading}

            />
          )
        }
      </Box >
    </Dialog >
  );
};

export default RABillRecoveryDialog


