
import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';
import { checkValidation, getCurrentRole, getTwoDigitDecimal } from 'app/utils/utils';
import { ProjectStatus } from 'app/utils/ProjectStatus';

export const RA_BILLS_LIST = 'RA_BILLS_LIST';
export const PRICE_ADJUSTMENT_LIST_BILL = 'PRICE_ADJUSTMENT_LIST_BILL';
export const GET_PROJECT_LOCATION_LIST_BILL = 'GET_PROJECT_LOCATION_LIST_BILL';
export const CREDIT_DEBIT_LIST = 'CREDIT_DEBIT_LIST'
export const RECLAIM_ADVANCES_LIST = 'RECLAIM_ADVANCES_LIST'
export const APPROVED_RA_BILLS_LIST = "APPROVED_RA_BILLS_LIST"
export const RECOVERED_RA_BILLS_LIST = "RECOVERED_RA_BILLS_LIST"
export const RETENTION_LIST = "RETENTION_LIST"
export const GET_CONSTRUCTION_TYPE_RABILL = "GET_CONSTRUCTION_TYPE_RABILL"

export const getAllProjectLocationListForBill = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getAllProjectLocationForContractDetails + `?role_id=${getCurrentRole()}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST_BILL,
        payload: res?.data ?? [],
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getApprovedRaBillForRecovery = (project_location_id, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${project_location_id}${ServiceUrl.getApprovedRaBillForRecovery}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({ type: APPROVED_RA_BILLS_LIST, payload: res?.data });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};

export const getAllRaBillRecoveryList = (runningaccountbill_id, showLoader, hideLoader, showToast, handleRABillSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_id}${ServiceUrl.getAllRaBillRecoveryList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      var newObj = res?.data?.obj
      if (checkValidation(newObj)) {
        if (newObj?.raBillRecoveryDetailsList?.length > 0) {
          newObj?.raBillRecoveryDetailsList?.forEach((element, index) => {
            // var calculatedDevQuantity = getTwoDigitDecimal((element?.deviation_quantity ?? 0) - (element?.recovered_quantity ?? 0))
            // element.deviation_quantity = calculatedDevQuantity > 0 ? calculatedDevQuantity : 0
            element.deviation_amount = getTwoDigitDecimal(element?.deviation_quantity * element?.revised_rate)
            element.recovered_amount = getTwoDigitDecimal(element?.recovered_quantity * element?.revised_rate)
            // var calculatedNewQuantity = calculatedDevQuantity > 0 ? element?.new_quantity : (element?.new_quantity + calculatedDevQuantity)
            // element.new_quantity = getTwoDigitDecimal(calculatedNewQuantity)
            element.executed_quantity = getTwoDigitDecimal((element?.new_quantity ?? 0) + (element?.deviation_quantity ?? 0))

            const balance_dev_quantity = element.balance_dev_quantity ?? 0
            const balance_new_quantity = element.balance_new_quantity ?? 0
            const newDevAmount = balance_dev_quantity * (element?.revised_rate ?? 0)
            const newAmount = balance_new_quantity * (element?.contract_rate ?? 0)
            if (balance_dev_quantity < element?.deviation_quantity) {
              element.recovered_amount = getTwoDigitDecimal(newDevAmount)
            } else {
              element.recovered_amount = getTwoDigitDecimal(newDevAmount + newAmount)
            }
          })
        }
      }
      dispatch({ type: RECOVERED_RA_BILLS_LIST, payload: newObj ?? [] });
      handleRABillSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};

export const getRetentionReleaseList = (status, showLoader, hideLoader, showToast, handleRetentionListSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${status}/${getCurrentRole()}${ServiceUrl.getRetentionReleaseList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({ type: RETENTION_LIST, payload: res?.data ?? [] });
      handleRetentionListSucces && handleRetentionListSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};



export const getAllRunningAccountBillByProjectLocation = (project_location_id, statusType, type, showLoader, hideLoader, showToast, handleRABillSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${project_location_id}/${statusType}/${type}${ServiceUrl.getAllRunningAccountBillByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({ type: RA_BILLS_LIST, payload: res?.data });
      handleRABillSucces && handleRABillSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};

export const getRunningAccountBillByProjectLocationId = (project_location_id, statusId, showLoader, hideLoader, showToast, handleRABillSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(`/api/implementation/runningAccountBill/${project_location_id}${ServiceUrl.getRunningAccountBillByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      if (res?.data?.length > 0) {
        if (statusId === ProjectStatus.CREATED) {
          dispatch({ type: RA_BILLS_LIST, payload: res?.data });
        } else if (statusId === ProjectStatus.SUBMITTED) {
          const filteredArray = res?.data?.filter((element) => element.status >= statusId && element.status !== ProjectStatus.REJECTED)
          dispatch({ type: RA_BILLS_LIST, payload: filteredArray });
        }
        else {
          const filteredArray = res?.data?.filter((element) => element.status >= statusId && element.status !== ProjectStatus.REJECTED && element.status !== ProjectStatus.SENT_FOR_REVIEW)
          dispatch({ type: RA_BILLS_LIST, payload: filteredArray });
        }
      } else {
        dispatch({ type: RA_BILLS_LIST, payload: res?.data });
      }
      handleRABillSucces && handleRABillSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.message ?? error?.message);
  })
};

export const getAllRecommededBillsByProjectLocation = (project_location_id, statusType, type) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${project_location_id}/${statusType}/${type}${ServiceUrl.getAllRunningAccountBillByProjectLocationId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRABillWorkDoneByBillId = (bill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`api/implementation/runningAccountBill/${bill_id}/getWorkDoneAmountForPriceAdjustment`, {}, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}


export const saveOrUpdateRunningAccountBill = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRunningAccountBill, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const saveOrUpdateRetentionRelease = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRetentionRelease, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const sendRaBillReportEmail = ({ ra_bill_id, data }) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`api/implementation/runningAccountBill/${ra_bill_id}/saveOrUpdateRunningAccountBill`, data, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getRetentionAmountByProjectLocationId = (project_location_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`api/implementation/runningAccountBill/${project_location_id}${ServiceUrl.getRetentionAmountByProjectLocationId}`, {}, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  })
}

export const saveOrUpdateRaBillRecovery = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRaBillRecovery, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}


export const saveOrUpdateRaBillAmendment = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRaBillAmendment, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const updateBalanceQuantity = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.updateBalanceQuantity, params, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export const deleteRunningAccountBillDetailsDOM = (runningaccountbill_details_dom_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${runningaccountbill_details_dom_id}${ServiceUrl.deleteRunningAccountBillDetailsDOM}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRunningAccountBillId = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${runningaccountbill_id}${ServiceUrl.deleteRunningAccountBillId}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillAmendmentDetailsDOM = (rabillamendment_details_dom_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillamendment_details_dom_id}${ServiceUrl.deleteRaBillAmendmentDetailsDOM}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillAmendmentDetails = (rabillamendment_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillamendment_details_id}${ServiceUrl.deleteRaBillAmendmentDetails}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillRecoveryDetails = (rabillrecovery_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillrecovery_details_id}${ServiceUrl.deleteRaBillRecoveryDetails}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRaBillRecoveryDetailsDom = (rabillrecovery_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/runningAccountBill/${rabillrecovery_details_id}${ServiceUrl.deleteRaBillRecoveryDetailsDom}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveRunningAccountBillDetailsDOM = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveRunningAccountBillDetailsDOM, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateRaBillRecoveryDetailsDoM = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRaBillRecoveryDetailsDoM, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getRABillDoMList = (runningaccountbill_details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_details_id}${ServiceUrl.getRABillDoMList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveRaBillAmendmentDetailsDOM = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveRaBillAmendmentDetailsDOM, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllRaBillAmendmentByRunningAccountBillId = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/implementation/runningAccountBill/${runningaccountbill_id}${ServiceUrl.getAllRaBillAmendmentByRunningAccountBillId}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const checkItemPresentInRunningAccountBill = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.checkItemPresentInRunningAccountBill, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


//, showLoader, hideLoader, showToast
export const getAllPriceAdjustmentReport = (runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  // showLoader()
  return axios.post(`/api/implementation/report/${runningaccountbill_id}${ServiceUrl.getAllPriceAdjustmentReport}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  // .then((res) => {
  //   hideLoader()
  //   if (res?.status < 300) {
  // dispatch({ type: PRICE_ADJUSTMENT_LIST, payload: res?.data?.obj?.price_adjustment });
  //   } else {
  //     showToast(res?.data?.message)
  //   }
  // }).catch(function (error) {
  //   hideLoader()
  //   showToast(error?.response?.message ?? error?.message);
  // })
};
//, showLoader, hideLoader, showToast
export const getAllAdvanceDetailsReport = (project_location_id, runningaccountbill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  // showLoader()
  return axios.post(`/api/implementation/report/${project_location_id}/${runningaccountbill_id}${ServiceUrl.getAllAdvanceDetailsReport}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  // .then((res) => {
  //     hideLoader()
  //     if (res?.status < 300) {
  //       dispatch({ type: RECLAIM_ADVANCES_LIST, payload: res?.data?.obj?.advance_details });
  //     } else {
  //       showToast(res?.data?.message)
  //     }
  //   }).catch(function (error) {
  //     hideLoader()
  //     showToast(error?.response?.message ?? error?.message);
  //   })
};
//, showLoader, hideLoader, showToast
export const getDebitCreditDetailsForRABillReport = (project_location_id, ra_bill_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  // showLoader()
  return axios.post(`/api/implementation/report/${project_location_id}/${ra_bill_id}${ServiceUrl.getDebitCreditDetailsForRABillReport}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  // .then((res) => {
  //   hideLoader()
  //   if (res?.status < 300) {
  //     dispatch({ type: CREDIT_DEBIT_LIST, payload: res?.data?.obj?.debit_credit_details });
  //   } else {
  //     showToast(res?.data?.message)
  //   }
  // }).catch(function (error) {
  //   hideLoader()
  //   showToast(error?.response?.message ?? error?.message);
  // })
};