import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Roles = Loadable(lazy(() => import('./Roles')));
const Permission = Loadable(lazy(() => import('./Permission')));
const AssignReport = Loadable(lazy(() => import('./AssignReport')));

const roleRoutes = [
  {
    path: '/roles',
    element: <Roles/>,
  },
  {
    path: '/Permission',
    element: <Permission/>,
  },
  {
    path: '/AssignReport',
    element: <AssignReport/>,
  }
];

export default roleRoutes;
