import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ProjectLocation = Loadable(lazy(() => import('./ProjectLocation')));
const ProjectLocationBSR = Loadable(lazy(() => import('./ProjectLocationBSR')));
const ProjectLocationRateAnalysis = Loadable(lazy(() => import('./ProjectLocationRateAnalysis')));
const EvaluateRateAnalysis = Loadable(lazy(() => import('./EvaluateRateAnalysis')));
const ManageBSR = Loadable(lazy(() => import('./ManageBSR')));
const QuickCostGeneral = Loadable(lazy(() => import('../QuickCostGeneral/QuickCostGeneral')))

const projectLocationRoutes = [
  {
    path: '/projectLocation',
    element: <ProjectLocation />,
  },
  {
    path: '/projectLocationBSR',
    element: <ProjectLocationBSR />,
  },
  {
    path: '/RateAnalysis',
    element: <ProjectLocationRateAnalysis />,
  }, {
    path: '/EvaluateRateAnalysis',
    element: <EvaluateRateAnalysis />,
  },
  {
    path: '/ManageBSR',
    element: <ManageBSR />,
  },
  {
    path: '/QuickCostGeneral',
    element: <QuickCostGeneral />,
  }
];

export default projectLocationRoutes;