import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';
import { SHOW_LOADING } from './RoleActions';
import { AppConstants } from "app/utils/AppConstants";

export const GET_STAGE_INFORMATION_LIST = 'GET_STAGE_INFORMATION_LIST';



export const getStageInformationList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  dispatch({ type: SHOW_LOADING, payload: true });
  if (showLoader) {
    showLoader()
  }
  axios.post(ServiceUrl.getAllStageInformation, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    if (hideLoader) {
      hideLoader()
    }
    if (res?.status < 300) {
      dispatch({
        type: GET_STAGE_INFORMATION_LIST,
        payload: res.data.obj,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(err => {
    if (hideLoader) {
      hideLoader()
    }
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};

export const saveUpdateAndDeleteStageInformation = (stageInformation) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateAndDeleteStageInformation, stageInformation, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
}