import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PartIIISubmission = Loadable(lazy(() => import('./Part3Submission')));
const Part3Verification = Loadable(lazy(() => import('./Part3Verification')));
const Part3Recommendation = Loadable(lazy(() => import('./Part3Recommendation')));
const Part3Approval = Loadable(lazy(() => import('./Part3Approval')));


const part3Routes = [
    {
        path: '/PartIIISubmission',
        element: <PartIIISubmission />
    },
    {
        path: '/PartIIIVerification',
        element: <Part3Verification />
    },

    {
        path: '/PartIIIRecommendation',
        element: <Part3Recommendation />
    },
    {
        path: '/PartIIIApproval',
        element: <Part3Approval />
    }
];

export default part3Routes;
