import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForBill } from "app/redux/actions/RABillActions";
import { getAllRaBillNoForReport, getStatementOfPriceAdjustmentReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const PriceAdjustmentStatementReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const { projectLocationList } = useSelector((state) => state.raBill);
  const [raBillList, setRaBillList] = useState([])

  const [tableData, setTableData] = useState()
  const [grandTotal, setGrandTotal] = useState()

  const [state, setState] = useState({
    project_location_id: '',
    ra_bill_id: ""
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForBill(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {
    setTableData()

    if (event.target.name === "project_location_id") {
      setRaBillList([])
      const filteredArray = projectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id
      setLoading(true)
      setState({ ...state, [event.target.name]: event.target.value, ra_bill_id: "" });
      getAllRaBillNoForReport({ project_location_id: filteredArray[0].project_location_id }).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj?.rabill_details?.length > 0) {
            setRaBillList(res?.data?.obj?.rabill_details)
          }
          else {
            const key = enqueueSnackbar("Could not find any RA Bill", { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);
          }

        } else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    } else {
      setState({ ...state, [event.target.name]: parseInt(event.target.value) });
    }
  }


  const handleFormSubmit = async () => {
    if (state?.project_location_id === "") {
      const key = enqueueSnackbar("Please select project location", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.ra_bill_id === "") {
      const key = enqueueSnackbar("Please select RA Bill No", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else {
      setLoading(true)
      setTableData()

      getStatementOfPriceAdjustmentReport({ project_location_id: state?.project_location_id, ra_bill_id: state?.ra_bill_id })
        .then((res) => {
          setLoading(false)
          console.log("response:", res)
          if (res?.status < 300) {

            //    if(res?.data?.obj){
            //    if(res?.data?.obj&&res?.data?.obj?.item)
            //    {
            //         if(res?.data?.obj?.item?.item&&Object.keys(res?.data?.obj?.item?.item).length>0)
            //         {
            //             const table=[]
            //             Object.keys(res?.data?.obj?.item?.item).forEach(key => {
            //                 let obj={}
            //                 const constructionName=key
            //                 const itemList = res?.data?.obj?.item?.item[key];
            //                 if(res?.data?.obj?.item?.item[key]?.length>0){
            //                   const subTotal=  res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat(item.contract_amount), 0);
            //                   const subTotalRevised=  res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat(item.revised_amount), 0);
            //                   obj={
            //                     construction_name:constructionName,
            //                     item_list:itemList,
            //                     sub_total_tender:parseFloat(subTotal?subTotal:0).toFixed(2),
            //                     sub_total_revised:parseFloat(subTotalRevised?subTotalRevised:0).toFixed(2)
            //                   }
            //                 }
            //                // console.log(`Key: ${key}, Value: ${value}`);
            //                 table.push(obj)
            //               });
            //               console.log("table: ",table)
            //               const grand_total_tender=table.reduce((sum,item)=>sum+parseFloat(item?.sub_total_tender),0)
            //               const grand_total_revised=table.reduce((sum,item)=>sum+parseFloat(item?.sub_total_revised),0)
            //               const obj={
            //                 grand_tender_amount:parseFloat(grand_total_tender?grand_total_tender:0).toFixed(2),
            //                 grand_revised_amount:parseFloat(grand_total_revised?grand_total_revised:0).toFixed(2)
            //             }
            //               setGrandTotal(obj)
            //             setTableData(table)
            //         }


            //     setProjectDetails({
            //                 company:res?.data?.obj?.item?.company,
            //                 contract_no:res?.data?.obj?.item?.tender_id,
            //                 project_name:res?.data?.obj?.item?.project_name,
            //                 dzongkhag_name:res?.data?.obj?.item?.dzongkhagName,
            //                 header:res?.data?.obj?.header_logo,
            //                 footer:res?.data?.obj?.footer_logo

            //             })}
            //    }  
            if (res?.data?.success) {
              if (res?.data?.obj?.item?.item && Object.keys(res?.data?.obj?.item?.item).length > 0) {
                const table = []
                Object.keys(res?.data?.obj?.item?.item).forEach(key => {
                  let obj = {}
                  const constructionName = key
                  const itemList = res?.data?.obj?.item?.item[key];
                  if (res?.data?.obj?.item?.item[key]?.length > 0) {
                    const subTotal = res?.data?.obj?.item?.item[key]?.reduce((sum, item) => sum + parseFloat(item.adjustment_amount), 0);

                    obj = {
                      construction_name: constructionName,
                      item_list: itemList,
                      sub_total: parseFloat(subTotal ? subTotal : 0).toFixed(2),
                    }
                  }
                  // console.log(`Key: ${key}, Value: ${value}`);
                  table.push(obj)
                });
                console.log("table: ", table)
                const grand_total = table.reduce((sum, item) => sum + parseFloat(item?.sub_total), 0)

                setGrandTotal(grand_total)
                setTableData(table)
                setProjectDetails({
                  from: moment(res?.data?.obj?.item?.work_start_date)?.format("DD-MM-YYYY"),
                  to: moment(res?.data?.obj?.item?.work_end_date)?.format("DD-MM-YYYY"),
                  company: res?.data?.obj?.agency_name,
                  amount: res?.data?.obj?.item?.amount,
                  material_variance: res?.data?.obj?.item?.material_variance,
                  material_constant: res?.data?.obj?.item?.material_constant,
                  labour_variance: res?.data?.obj?.item?.labour_variance,
                  labour_constant: res?.data?.obj?.item?.labour_constant,
                  contract_no: res?.data?.obj?.item?.tender_id,
                  project_id: res?.data?.obj?.item?.project_id,
                  project_name: res?.data?.obj?.item?.project_name,
                  header: res?.data?.obj?.header_logo,
                  footer: res?.data?.obj?.footer_logo

                })
              }

              else {
                const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
            }


            else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            }


          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(error => {
          setLoading(false)
          const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })

    }








  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
        scaledHeight = pdfHeight;
        scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10;

      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("STATEMENT OF PRICE ADJUSTMENT", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("STATEMENT OF PRICE ADJUSTMENT") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      doc.setFontSize(8); // Set font size
      const textWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company, textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.contract_no}`, textX + textWidth + 5, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("Period:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`From: ${projectDetails?.from}`, textX + textWidth + 5, textY + 25);
      doc.text(`To: ${projectDetails?.to}`, textX + textWidth + 40, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(5);

      const headerSectionHeight = scaledHeight + 40; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');

      const body = []

      tableData?.forEach((elem, index) => {
        body.push([
          { content: index + 1, styles: { fontSize: 8 } },
          { content: elem?.item_list[0]?.category_name, styles: { fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.item_list[0]?.base_price ? elem?.item_list[0]?.base_price : 0).toFixed(2)), styles: { halign: "right", fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.item_list[0]?.current_price ? elem?.item_list[0]?.current_price : 0).toFixed(2)), styles: { halign: "right", fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.item_list[0]?.adjustment_amount ? elem?.item_list[0]?.adjustment_amount : 0).toFixed(2)), styles: { halign: "right", fontSize: 8 } }
        ])
      })


      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "Sl No.", styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Item", styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Base Index", styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Current Index", styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Amount Payable/Recoverable", styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
          fontSize: 7
        },
        columnStyles: {
          // fontSize:5

          // 8:{columnWidth:15},
          // 9:{columnWidth:15},
          // 10:{columnWidth:15},
          // 11:{columnWidth:15}
        },
      });
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');// Set font size
      doc.text(`Value of work done during the period under review excluding amounts paid in market rate (W) = ${formatNumberWithCommasAndDecimals(projectDetails?.amount)}`, 15, doc.autoTable.previous.finalY + 10);
      doc.text(`Material Variance Limit = ${projectDetails?.material_variance}%`, 15, doc.autoTable.previous.finalY + 15);
      doc.text(`Material Constant = ${projectDetails?.material_constant}%`, 15, doc.autoTable.previous.finalY + 20);
      doc.text(`Labour Variance Limit = ${projectDetails?.labour_variance}%`, 15, doc.autoTable.previous.finalY + 25);
      doc.text(`Labour Constant = ${projectDetails?.labour_constant}%`, 15, doc.autoTable.previous.finalY + 30);
      doc.text(`Total Price Adjustment Amount in this Bill = ${formatNumberWithCommasAndDecimals(grandTotal)}`, 15, doc.autoTable.previous.finalY + 35);


      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save("Statement_Report_of_Price_Adjustment.pdf");

    });

  };







  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Statement of Price Adjustment</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {projectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="RA Bill No."
                  id="rabill_is"
                  variant="outlined"
                  name="ra_bill_id"
                  value={state?.ra_bill_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["RA Bill No. is required"]}
                >
                  {/* {raBillList?.map((item) => (
                    <MenuItem value={item?.runningaccountbill_id} key={item?.rabill_no}>
                      {item?.rabill_no}
                    </MenuItem>
                  ))} */}
                  {raBillList?.map((item) => (
                    item?.rabillid > 0 && (
                      <MenuItem value={item?.runningaccountbill_id} key={item?.rabill_no}>
                        {item?.rabill_no}
                      </MenuItem>
                    )
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {
          tableData && tableData?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}

        {/* table */}
        {
          tableData && tableData?.length > 0 && projectDetails &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>
              </div>

              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>STATEMENT OF PRICE ADJUSTMENT</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Period:   </b><b style={{ marginLeft: "20px" }}>From: </b> {projectDetails?.from} <b style={{ marginLeft: "30px" }}>To: </b> {projectDetails?.to}</FormLabel>
                </Grid>


              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell align="center">SL No.</TableCell>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="right">Base Index</TableCell>
                      <TableCell align="right">Current Index</TableCell>
                      <TableCell align="right">Amount Payable/Recoverable (Nu)</TableCell>

                    </TableRow>

                  </TableHead>
                  <TableBody>

                    {tableData && tableData?.map((data, index) => {
                      return (
                        <>
                          {
                            data?.item_list?.map((elem) => {
                              return (
                                <TableRow>
                                  <TableCell align="center">{index + 1}</TableCell>
                                  <TableCell align="center">{elem?.category_name}</TableCell>
                                  <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(elem?.base_price ? elem?.base_price : 0).toFixed(2))}</TableCell>
                                  <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(elem?.base_price ? elem?.base_price : 0).toFixed(2))}</TableCell>
                                  <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(elem?.adjustment_amount ? elem?.adjustment_amount : 0).toFixed(2))}</TableCell>

                                </TableRow>
                              )
                            })
                          }
                        </>
                      )
                    })}
                    {/* <TableRow>
            <TableCell colSpan={4} align="right"><b>Grand Total</b></TableCell>
            <TableCell  align="right"><b>{parseFloat(grandTotal).toFixed(2)}</b></TableCell>
          </TableRow> */}

                  </TableBody>


                </Table>

              </TableContainer>
              <Grid container>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Value of work done during the period under review excluding amounts paid in market rate (W) = </b>{formatNumberWithCommasAndDecimals(projectDetails?.amount)}</FormLabel>
                </Grid>
                {/* <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>Sum of the product of the weightages and the corresponding Labour Current Price (L) =</b>{projectDetails?.amount}</FormLabel>
            </Grid>
            <Grid item  xs={12} sx={{marginTop:2}}>
                <FormLabel sx={{fontSize:18}}><b>Sum of the product of the weightages and the corresponding Labour Base Price (L) = =</b>{projectDetails?.amount}</FormLabel>
            </Grid> */}
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Material Variance Limit =</b>{projectDetails?.material_variance}%</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Material Constant =</b>{projectDetails?.material_constant}%</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Labour Variance Limit =</b>{projectDetails?.labour_variance}%</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Labour Constant =</b>{projectDetails?.labour_constant}%</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Total Price Adjustment Amount in this Bill = </b>{formatNumberWithCommasAndDecimals(grandTotal)}</FormLabel>
                </Grid>
              </Grid>
            </div>
          </Card>}


      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}