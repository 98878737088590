import { GET_PROJECT_LOCATION_LIST_CONTRACT } from "../actions/ImplementationActions";
import { GET_MACHINERY_TYPE_LIST, GET_MUSTER_ROLL_PAYMENT_REQUEST_LIST, GET_MUSTER_ROLL_PAYMENT_TYPE_LIST } from "../actions/MusterRollActions";

  
  const initialState = {
    paymentRequestList: [],
    paymentTypeList: [],
    projectLocationList: [],
    machineryTypeList: []
  };
  
  const MusterRollReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_MACHINERY_TYPE_LIST: {
        return {
            ...state,
            machineryTypeList: [...action.payload],
          };
      }

      case GET_MUSTER_ROLL_PAYMENT_REQUEST_LIST: {
        return {
            ...state,
            paymentRequestList: [...action.payload],
          };
      }

      case GET_PROJECT_LOCATION_LIST_CONTRACT: {
        return {
            ...state,
            projectLocationList: [...action.payload],
          };
      }


      case GET_MUSTER_ROLL_PAYMENT_TYPE_LIST: {
        return {
            ...state,
            paymentTypeList: [...action.payload],
          };
      }

      default: {
        return {...state}
      }
    }
  };
  
  export default MusterRollReducer;
  