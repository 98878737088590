import {
  CREATE_ROLE,
  GET_PERMISSION_MENU,
  GET_REPORTS_ROLES,
  GET_ROLES,
  SHOW_LOADING,
  UPDATE_ROLE
} from '../actions/RoleActions';

const initialState = {
  roleList: [],
  isLoading: true,
  menuPermission: null,
  reportRoleList: []
};

const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS_ROLES: {
      return {
        ...state,
        reportRoleList: [...action.payload],
      };
    }
    case GET_ROLES: {
      return {
        ...state,
        isLoading: false,
        roleList: [...action.payload],
      };
    }
    case GET_PERMISSION_MENU: {
      console.log('my action payload', action.payload)
      let menuListResponse = action.payload
      var lastIndex = 0
      menuListResponse?.menuList?.forEach((menu, index) => {
        if (index === 0) {
          lastIndex = lastIndex + 1
          menu.menuIndex = lastIndex
        } else {
          if (menuListResponse?.menuList[index - 1]?.parent_menu === menu?.parent_menu) {
            menu.menuIndex = ""
          } else {
            lastIndex = lastIndex + 1
            menu.menuIndex = lastIndex
          }
        }
        menuListResponse?.menuPermissionList?.forEach(permission => {
          if (permission?.menuId === menu?.menu_id) {
            menu.permission = permission
          }
        });
      });

      return {
        ...state,
        isLoading: false,
        menuPermission: { ...menuListResponse },
      };
    }
    case UPDATE_ROLE: {
      return { ...action.payload };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case CREATE_ROLE: {
      return { ...action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default RoleReducer;
