import {
  GET_USERS,
  SHOW_LOADING
} from '../actions/UserActions';

  const initialState = {
    userList: [],
    isLoading: true
  };

  const UserReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_USERS: {
        return {
            ...state,
            isLoading: false,
            userList: [...action.payload],
          };
      }
      
      case SHOW_LOADING: {
        return {
          ...state,
          isLoading: action.payload,
        };
      }
      
      default: {
        return {...state};
      }
    }
  };
  
  export default UserReducer;