import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';

export const UPDATE_CURRENT_QUICK_COST_GENERAL_TAB = "UPDATE_CURRENT_QUICK_COST_GENERAL_TAB"


export const getAllProjectConstructionNameList = (project_location_id,buildingtype_id) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(`/api/bsr/projectConstruction/${project_location_id}/${buildingtype_id}${ServiceUrl.getAllProjectConstructionNameList}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};


export const fetchAvgRoomSizeAndRomSizeFactor = (projectconstructionname_id) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(`/api/bsr/${projectconstructionname_id}${ServiceUrl.fetchAvgRoomSizeAndRomSizeFactor}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};

export const saveOrUpdateProjectConstructionName = (params) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(ServiceUrl.saveOrUpdateProjectConstructionName, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};
export const getQuickCostRate = (params) => {
    const accessToken = window.localStorage.getItem('accessToken')
    return axios.post(`/api/bsr/${params?.buildingtype_id}/${params?.baselocation_id}/${params?.bsr_year}${ServiceUrl.getQuickCostRate}`, params, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
};

