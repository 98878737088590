import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';
import { AppConstants } from "app/utils/AppConstants";

export const GET_ROLES = 'GET_ROLES';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const GET_PERMISSION_MENU = 'GET_PERMISSION_MENU';
export const SHOW_LOADING = 'SHOW_LOADING'
export const GET_REPORTS_ROLES = 'GET_REPORTS_ROLES';



export const getRoles = (userID, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getRoles + '?id=' + userID, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ROLES,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  });
};

export const getAllRoles = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getRoles, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ROLES,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  });
};

export const getAllAssignedReportDetailList = (roleId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/roles/${roleId}${ServiceUrl.getAllAssignedReportDetailList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdateRoleReportMap = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateRoleReportMap, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const deleteRoleById = (roleId) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/roles/${roleId}${ServiceUrl.deleteRoleById}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const createRole = (id, description, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.addOrUpdateRole + '?id=' + id, { description }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch(getRoles(id, showLoader, hideLoader, showToast))
    } else {
      showToast(res?.data?.message)
    }
  });
};

export const updateRole = (id, roleId, description, showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.addOrUpdateRole + '?id=' + id, { roleId, description }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch(getRoles(id, showLoader, hideLoader, showToast))
    } else {
      showToast(res?.data?.message)
    }

  });
};

export const getPermissionsMenu = (id, roleId, showLoader, hideLoader, showToast) => (dispatch) => {
  showLoader()
  const accessToken = window.localStorage.getItem('accessToken')
  axios.post(ServiceUrl.menuPermission + roleId + '/getAllChildMenuList', {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PERMISSION_MENU,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }

  });
};

export const updatePermissionsMenu = (array, showLoader, hideLoader, showToast, handleSuccess) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.permissionUpdate, array, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      handleSuccess(res?.data?.message)
    } else {
      showToast(res?.data?.message)
    }
  });
};
export const getReportCategories = () => {
  const accessToken = window.localStorage.getItem('accessToken')

  return axios.get(ServiceUrl.getReportCategories, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};


export const getReportsRoles = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.getReportsRoles, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_REPORTS_ROLES,
        payload: res?.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(err => {
    hideLoader()
    showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
  });
};
