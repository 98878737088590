import { GET_STAGE_INFORMATION_LIST } from "../actions/StageInformationAction";

import { CONSTRUCTION_TYPE_LIST_PBOQ, GET_ALL_BASELOCATIONS_CONSTRUCTION, GET_ALL_CLASSIFICATIONS_CONSTRUCTION, GET_CONSTRUCTION_NAME_LIST, GET_CONSTRUCTION_TYPE, GET_PROJECT_LOCATION } from "../actions/ConstructionActions";
import { SHOW_LOADING } from '../actions/RoleActions';


const initialState = {
  constructionTypeList: [],
  projectLocationList: [],
  constructionNameList: [],
  stageInformationList: [],
  classificationList: [],
  baseLocationList: [],
  constructionTypeListPBOQ:[]

};

const ConstructionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BASELOCATIONS_CONSTRUCTION: {
      return {
        ...state,
        baseLocationList: [...action.payload],
      };
    }
    case GET_ALL_CLASSIFICATIONS_CONSTRUCTION: {
      return {
        ...state,
        classificationList: [...action.payload],
      };
    }
    case GET_CONSTRUCTION_TYPE: {
      return {
        ...state,
        constructionTypeList: [...action.payload],
      };
    }

    case GET_STAGE_INFORMATION_LIST: {
      return {
        ...state,
        isLoading: false,
        stageInformationList: [...action.payload],
      };
    }

    case GET_CONSTRUCTION_NAME_LIST: {
      return {
        ...state,
        constructionNameList: [...action.payload],
      };
    }
    case CONSTRUCTION_TYPE_LIST_PBOQ: {
      return {
        ...state,
        constructionTypeListPBOQ: [...action.payload],
      };
    }

    case GET_PROJECT_LOCATION: {
      return {
        ...state,
        projectLocationList: [...action.payload],
      };
    }

    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    default: {
      return { ...state }
    }
  }
}

export default ConstructionReducer