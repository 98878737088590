import axios from 'axios';
import { ServiceUrl } from 'app/utils/Constants';

export const UPDATE_CURRENT_QUICK_COST_TAB = "UPDATE_CURRENT_QUICK_COST_TAB";
export const GET_ALL_BUILDING_CONSTRUCTION = "GET_ALL_BUILDING_CONSTRUCTION"
export const GET_ALL_BUILDING_TYPE = "GET_ALL_BUILDING_TYPE"

export const saveUpdateBuildingConstructionName = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  //   showLoader && showLoader()
  return axios.post(ServiceUrl.saveUpdateBuildingConstructionName, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteBuildingConstructionNameDetailsById = (details_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  //   showLoader && showLoader()
  return axios.delete(`/api/bsr/${details_id}${ServiceUrl.deleteBuildingConstructionNameDetailsById}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBuildingConstructionName = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/bsr/${params?.baselocation_id}/${params?.bsr_year}/${params?.buildingtype_id}${ServiceUrl.getAllBuildingConstructionName}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateQuickCostRateDetails = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateQuickCostRateDetails, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const getQuickCostRateReport = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.getQuickCostRateReport, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};
export const deleteBuildingConstructionNameById = (buildingconstructionname_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/bsr/${buildingconstructionname_id}${ServiceUrl.deleteBuildingConstructionNameById}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveUpdateQuickCost = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveUpdateQuickCost, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const calculatePaRate = (buildingconstructionname_id) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(`/api/bsr/${buildingconstructionname_id}${ServiceUrl.calculatePaRate}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const getAllBuildingType = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader && showLoader()
  axios.post(ServiceUrl.getAllBuildingType, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_ALL_BUILDING_TYPE,
        payload: res?.data ?? [],
      });
    } else {
      showToast(res?.data?.message, "error")
    }
  }).catch(function (error) {
    hideLoader && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message, "error");;
  })
};