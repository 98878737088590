import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Agency = Loadable(lazy(() => import('./Agency')));
const NewAgency = Loadable(lazy(() => import('./NewAgency')));
const NewLogo = Loadable(lazy(() => import('./NewLogo')));
const NewHeader = Loadable(lazy(() => import('./NewHeader')));
const NewFooter = Loadable(lazy(() => import('./NewFooter')));




const agencyRoutes = [
  {
    path: '/Agency',
    element: <Agency />
  }
  // ,
  // {
  //   path: '/Agency/newAgency',
  //   element: <NewAgency/>
  // },
  // {
  //   path: '/Agency/newAgency/newLogo',
  //   element: <NewLogo/>
  // },
  // {
  //   path: '/Agency/newAgency/newHeader',
  //   element: <NewHeader/>
  // },
  // {
  //   path: '/Agency/newAgency/newFooter',
  //   element: <NewFooter/>
  // }

];

export default agencyRoutes;
