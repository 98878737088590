import { GET_BSR_RATE_WEIGHT_DETAILS_LIST, GET_CONSTRUCTION_NAME_LIST_BY_PARAMS, GET_DZONGKHAG_LIST_COST_INDEX, GET_GEWOG_LIST_COST_INDEX, GET_ITEM_DETAILS_LIST, GET_ITEM_RATE_WEIGHT_DETAILS_LIST, GET_PROJECT_LOCATION_LIST, GET_UNIT_LIST_COST_INDEX, GET_WORK_TYPE_LIST } from "../actions/ConstIndexActions";

  
  const initialState = {
    workTypeList: [],
    unitList: [],
    dzongkhagList: [],
    gewogList: [],
    currentCostIndexTab: 0,
    itemDetailsList: [],
    itemRateWeightDetailsList: [],
    bsrRateWeightDetailsList: [],
    projectLocationList: [],
    constructionNameList: [],
    isLoading: false
  };
  
  const CostIndexReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_WORK_TYPE_LIST: {
        return {
            ...state,
            isLoading: false,
            workTypeList: [...action.payload],
          };
      }

      case GET_ITEM_DETAILS_LIST: {
        return {
            ...state,
            itemDetailsList: [...action.payload],
          };
      }

      case GET_BSR_RATE_WEIGHT_DETAILS_LIST: {
        return {
            ...state,
            bsrRateWeightDetailsList: [...action.payload],
          };
      }

      case GET_ITEM_RATE_WEIGHT_DETAILS_LIST: {
        return {
            ...state,
            itemRateWeightDetailsList: [...action.payload],
          };
      }

      case GET_PROJECT_LOCATION_LIST: {
        return {
            ...state,
            isLoading: false,
            projectLocationList: [...action.payload],
          };
      }

      case GET_CONSTRUCTION_NAME_LIST_BY_PARAMS: {
        return {
          ...state,
          constructionNameList: [...action.payload]
        }
      }


      case GET_DZONGKHAG_LIST_COST_INDEX: {
        return {
          ...state,
          isLoading: false,
          dzongkhagList: [...action.payload],
        };
      }

      case GET_GEWOG_LIST_COST_INDEX: {
        return {
          ...state,
          isLoading: false,
          gewogList: [...action.payload],
        };
      }

      case GET_UNIT_LIST_COST_INDEX: {
        return {
          ...state,
          isLoading: false,
          unitList: [...action.payload],
        };
      }

      default: {
        state.currentCostIndexTab = 0
        return {...state}
      }
    }
  };
  
  export default CostIndexReducer;
  