import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getStatementOfMaterialConsumptionReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const MaterialConsumptionReport = () => {

  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  // const {userList}=useSelector((state)=>state.userList)
  const [tableData, setTableData] = useState([]);

  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {
    setLoading(true)
    setTableData([])
    getStatementOfMaterialConsumptionReport({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)
        if (res?.status < 300) {

          if (res?.data?.obj) {
            setState({
              ...state,
              header: res?.data?.obj.header_logo,
              footer: res?.data?.obj?.footer_logo,
              project_name: res?.data?.obj?.item?.project_name,
              company: res?.data?.obj?.agency_name,
              project_id: res?.data?.obj?.item?.project_id,
              upto_ra: ""
            })
            if (res?.data?.obj?.item?.statement_of_material_consumption?.length > 0) {
              setTableData(res?.data?.obj?.item?.statement_of_material_consumption)
              const grand_total = res?.data?.obj?.item?.statement_of_material_consumption.reduce((sum, item) => sum + parseFloat(parseFloat(item?.qty ? item?.qty : 0) * parseFloat(item?.rate ? item?.rate : 0)), 0)
              setTotalAmount(parseFloat(grand_total).toFixed(2))
            }

          }
          else {
            const key = enqueueSnackbar("No Material Consumption details found for this project", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })



  }

  const handleReportDownload = (isPrint) => {
    const doc = new jsPDF();
    const contentHeight = 150;
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10; // Adjust the value for vertical separation
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size

      doc.text("STATEMENT OF MATERIAL CONSUMPTION", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("STATEMENT OF MATERIAL CONSUMPTION") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      const textWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY+10);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.project_name}`, textX + textWidth -5, textY+10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY+15);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.company}`, textX + textWidth -5, textY+15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Id:", textX, textY+20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${state?.project_id}`, textX + textWidth -5, textY+20);
      doc.setFont('helvetica', 'normal');

      const headerSectionHeight = scaledHeight+35; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');
      const body = []


      tableData && tableData?.length > 0 && tableData?.forEach((elem) => {

        body.push(
          [
          {content:elem?.item_code,styles:{fontSize:8}},
          {content:elem?.description,styles:{fontSize:8}},
          { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.qty ? elem?.qty : 0).toFixed(2)), styles: { halign: "right",fontSize:8 } },
          {content:elem?.unit_name,styles:{fontSize:8}},
          { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.rate ? elem?.rate : 0).toFixed(2)), styles: { halign: "right",fontSize:8 } },
          { content: formatNumberWithCommasAndDecimals(parseFloat(parseFloat(elem?.qty ? elem?.qty : 0) * parseFloat(elem?.rate ? elem?.rate : 0)).toFixed(2)), styles: { halign: "right",fontSize:8 } }
          ]
        )



      })
      if (tableData?.length > 0) {
        body.push([{ content: "Grand Total", colSpan: 5, styles: { fontStyle: "bold", valign: "middle", halign: "right",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold",fontSize:8 } }])
      }
      doc.autoTable({
        startY: tableStartY ,
        head: [[
          { content: "BML Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          1: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }


      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Statement_Of_Material_Consumption_Report.pdf');

    });
  };

  return (
    <Container>

      <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>

          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Resource Consumption</b></Grid>
            </Grid>
            <Grid container spacing={3}>

              <Grid item sm={5} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  required
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {
          tableData && tableData?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(state?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  disabled={state?.header ? false : true}
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={state?.header ? false : true}
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}
        {/* table */}
        {
          tableData && tableData?.length > 0 &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>

                <Grid item xs={12} align='center' >
                  <FormLabel sx={{ fontSize: 22 }} ><b>STATEMENT OF MATERIAL CONSUMPTION</b></FormLabel>
                </Grid>


                <Grid container >
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{state?.company}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{state?.project_id}</FormLabel>
                  </Grid>


                </Grid>
              </div>

              <div id="tableData">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell>BML Code</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Rate (Nu)</TableCell>
                        <TableCell>Amount (Nu)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        tableData?.map((elem) => {
                          return (
                            <TableRow>
                              <TableCell>{elem?.item_code}</TableCell>
                              <TableCell>{elem?.description}</TableCell>
                              <TableCell>{formatNumberWithCommasAndDecimals(elem?.qty ? elem?.qty : 0)}</TableCell>
                              <TableCell>{elem?.unit_name}</TableCell>
                              <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(elem?.rate ? elem?.rate : 0).toFixed(2))}</TableCell>
                              <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(parseFloat(elem?.qty ? elem?.qty : 0) * parseFloat(elem?.rate ? elem?.rate : 0)).toFixed(2))}</TableCell>
                            </TableRow>
                          )
                        })

                      }
                      {tableData?.length > 0 &&
                        <TableRow>
                          <TableCell colSpan={5} align="right"><b>Grand Total</b></TableCell>
                          <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                        </TableRow>
                      }

                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
            </div>
          </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Please Wait While Generating PDF..."
        />
      )}

    </Container>

  )
}