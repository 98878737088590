import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload } from "@mui/icons-material";
import { Button, Card } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import logo from '../../../assets/img/header-logo.png';


const styles={
    tableCell:{
        textAlign:"center"
    },
    subHeader:{
        textAlign:"center",
        fontWeight:700
    }

}


export const PDFtest=()=>{

    const tableData = [
        {
          code: "123",
          description: "Product A",
          unit: "pcs",
          demo: "10",
          gp: "20",
          pl: "30",
        },
        {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
            code: "123",
            description: "Product A",
            unit: "pcs",
            demo: "10",
            gp: "20",
            pl: "30",
          },
          {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
            {
              code: "123",
              description: "Product A",
              unit: "pcs",
              demo: "10",
              gp: "20",
              pl: "30",
            },
        // Add more rows here
      ];
;

const handleDownload = () => {
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage =  document.getElementById("header");;
    const tableDataWithRateNU = tableData.map(({ code, description, unit, demo, gp, pl }) => [
        code,
        description,
        unit,
        "", // Empty string for Rate(NU) column
        demo,
        gp,
        pl,
      ]);
    doc.setFontSize(12); // Set font size
    
    html2canvas(headerImage).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
  
        // Calculate the width and height of the header image based on the PDF page width
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        const headerHeight = pdfHeight;
        const tableY = headerHeight + 15; 
        // Insert the header image into the PDF
        const topBorderPadding = 10; // Adjust the padding value as needed
    doc.setDrawColor(255,255,255); // Black color for border
    doc.setLineWidth(topBorderPadding); // Border width (padding)

    // Draw the top border (padding)
    doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

    // Insert the header image into the PDF
    doc.addImage(imgData, "PNG", 0, topBorderPadding, pdfWidth, pdfHeight);
  
        // doc.autoTable({
        //     startY: tableY,
        //     head: [
        //       [
        //         { content: "Code", rowspan: 2 },
        //         { content: "Description", rowspan: 2 },
        //         { content: "Unit", rowspan: 2 },
        //         { content: "Rate(NU)", colspan: 3 },
        //       ],
        //       ["DEMO", "GP", "PL"],
        //     ],
        //     body: tableData.map(({ code, description, unit, demo, gp, pl }) => [code, description, unit, demo, gp, pl]),
        //     theme: 'grid',
        //     styles: {
        //       // Styles here...
        //     },
        //     didDrawCell: (data) => {
        //       if (data.section === 'body' && data.column.index === 3) {
        //         // Skip the first three rows to create a blank space
        //         if (data.row.index <= 2) {
        //           doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'S');
        //         } else {
        //           // Draw borders for the Rate(NU) column
        //           doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'S');
        //         }
        //       }
        //     },
        //   });
        doc.autoTable({
            startY: tableY,
            head: [[
                { content: "Code", colSpan: 1,rowSpan:2, styles: { halign: "center",valign:"center" } },
                { content: "Description", colSpan: 1,rowSpan:2, styles: { halign: "center", valign:"center" } },
                { content: "Unit", colSpan: 1,rowSpan:2, styles: { halign: "center",valign:"center" } },
                { content: "Rate(NU)", colSpan: 3, styles: { halign: "center" } },
            
            ]],
            body: [
              [
                { content: "", colSpan: 3, styles: { halign: "center" } },
                { content: "DEMO",colSpan:1, styles: { halign: "center" } },
                { content: "GP",colSpan:1, styles: { halign: "center" } },
                { content: "PL",colSpan:1, styles: { halign: "center" } },
              ],
              ...tableData.map(({ code, description, unit, demo, gp, pl }) => [
                code,
                description,
                unit,
                demo,
                gp,
                pl,
              ]),
            ],
            theme: "grid",
            styles: {
              fillColor: [255, 255, 255], // Remove background color from the table
              lineColor: [0, 0, 0], // Set border color for main table
              lineWidth: 0.1, // Set border width for main table
              textColor: [0, 0, 0], // Set text color to black
              cellPadding: 2, // Add padding to cells
            },
            
          })
          doc.save('sample.pdf');  
      });
        
  }


  //... (rest of the code)
 

    return(
        <>
        <div style={{height:'auto',width:"100%",padding:'30px',display:"flex",flexDirection:"column",gap:"20px"}}>
            <Button 
            size="small"
            sx={{width:"150px"}}
            variant="contained"
            color="success"
            startIcon={<CloudDownload />}
            onClick={()=>{handleDownload()}}
            >
                DOWNLOAD
            </Button>
        <Card  sx={{padding:3}}>
            <div style={{width:"100%",display:'flex',flexDirection:"column",gap:"50px"}}>
           <div id="header" style={{width:"100%",display:"flex",justifyContent:"center",gap:"20px",alignItems:"center"}}>
                    <img style={{height:"150px",width:'150px'}} src={logo} alt="img"/>
                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:'center'}}>
                        <h1 style={{margin:0}}>Capcha Engineering Co. Pvt. Ltd.</h1>
                        <h2 style={{margin:0}}>Bhutan Schedule of Rates: 2001-2002</h2>
                        <h3 style={{margin:0}}>BASIC MATERIAL & LABOUR</h3>
                        <h4 style={{margin:0}}>Basic Rates- Brick & Hollow Block Materials</h4>
                    </div>
           </div>

            <div id="tableData">
            <TableContainer id="tableId" component={Paper}>

                <Table>
                <TableHead style={{ backgroundColor: '#87CEEB' }}>
                     <TableRow >
                         <TableCell style={styles.tableCell} rowSpan={2}>Code</TableCell>
                         <TableCell style={styles.tableCell} rowSpan={2}>Description</TableCell>
                        <TableCell style={styles.tableCell} rowSpan={2}>Unit</TableCell>
                        <TableCell style={styles.tableCell} colSpan={3}>Rate(NU)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
          <TableRow style={{ backgroundColor: '#87CEEB' }}>
            <TableCell style={styles.tableCell} colSpan={3}></TableCell>
            
            
                    <TableCell style={styles.subHeader}>DEMO</TableCell>
                    <TableCell style={styles.subHeader}>GP</TableCell>
                    <TableCell style={styles.subHeader}>PL</TableCell>
          </TableRow>
          
            {
                tableData?.map((elem)=>{
                    return(
                        <TableRow>
                        <TableCell style={styles.tableCell}>{elem.code}</TableCell>
                        <TableCell style={styles.tableCell}>{elem.description}</TableCell>
                        <TableCell style={styles.tableCell}>{elem.unit}</TableCell>
                        <TableCell style={styles.tableCell}>{elem.demo}</TableCell>
                        <TableCell style={styles.tableCell}>{elem.gp}</TableCell>
                        <TableCell style={styles.tableCell}>{elem.pl}</TableCell>
                        </TableRow>
                    )
                })
            }
          
          {/* Add more rows here */}
        </TableBody>
                </Table>
                </TableContainer>
            </div>
           </div>
        </Card>
        </div>
        
        </>
    )
}