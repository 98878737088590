import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const BuiltUpItemRate = Loadable(lazy(() => import('./BuiltUpItemRate')));

const builtUpItemRateRoutes = [
    {
      path: '/builtUpItemRate',
      element: <BuiltUpItemRate/>,
    }
  ];
  
  export default builtUpItemRateRoutes;