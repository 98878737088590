
import { ServiceUrl } from 'app/utils/Constants';
import axios from 'axios';

export const PRICE_ADJUSTMENT_LIST = 'PRICE_ADJUSTMENT_LIST';
export const PRICE_ADJUSTMENT_CATEGORY_LIST = 'PRICE_ADJUSTMENT_CATEGORY_LIST';
export const MATERIAL_LABOUR_INDEX = 'MATERIAL_LABOUR_INDEX';

export const GET_PROJECT_LOCATION_LIST_PRICE = 'GET_PROJECT_LOCATION_LIST_PRICE';


export const displayProjectDetailsForPriceAdjustment = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader()
  axios.post(ServiceUrl.displayProjectDetailsForPriceAdjustment, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      dispatch({
        type: GET_PROJECT_LOCATION_LIST_PRICE,
        payload: res.data,
      });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader !== false && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllPriceAdjustmentList = (project_location_id, status_id, showLoader, hideLoader, showToast, handlePriceAdjustmentSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(`/api/implementation/priceAdjustment/${project_location_id}/${status_id}${ServiceUrl.getAllPriceAdjustmentList}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader !== false && hideLoader()
    if (res?.status < 300) {
      // if (type === 'submission') {
      dispatch({ type: PRICE_ADJUSTMENT_LIST, payload: res?.data });
      // } else if (type === 'verification') {
      //   const filteredArray = res.data.filter(ra => {
      //     return ra.status === ProjectStatus.SUBMITTED
      //   });
      //   dispatch({ type: PRICE_ADJUSTMENT_LIST, payload: filteredArray});
      // } else {
      //     const filteredArray = res.data.filter(ra => {
      //       return ra.status === ProjectStatus.RECOMMENDED
      //     });
      //     dispatch({ type: PRICE_ADJUSTMENT_LIST, payload: filteredArray});
      // }
      handlePriceAdjustmentSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader !== false && hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};


export const getAllPriceAdjustmentCategoryList = (showLoader, hideLoader, showToast) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(ServiceUrl.getAllPriceAdjustmentCategoryList, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300) {
      dispatch({ type: PRICE_ADJUSTMENT_CATEGORY_LIST, payload: res.data });
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};

//getAllPriceAdjustmentCategoryList

export const saveOrUpdateMaterialLabourIndex = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdateMaterialLabourIndex, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
};

export const saveOrUpdatePriceAdjustment = (params) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.post(ServiceUrl.saveOrUpdatePriceAdjustment, params, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};
export const deleteMaterialLabourIndex = ({project_location_id,material_labour_index_id}) => {
  const accessToken = window.localStorage.getItem('accessToken')
  return axios.delete(`/api/implementation/priceAdjustment/${material_labour_index_id}/${project_location_id}${ServiceUrl.deleteMaterialLAbourIndex}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })
};


export const getAllMaterialLabourIndex = (project_location_id, showLoader, hideLoader, showToast, handlePriceAdjustmentSucces) => (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken')
  showLoader !== false && showLoader()
  axios.post(`/api/implementation/priceAdjustment/${project_location_id}${ServiceUrl.getAllMaterialLabourIndex}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  }).then((res) => {
    hideLoader()
    if (res?.status < 300 && res?.data?.success === true) {
      dispatch({ type: MATERIAL_LABOUR_INDEX, payload: res?.data?.obj });
      handlePriceAdjustmentSucces()
    } else {
      showToast(res?.data?.message)
    }
  }).catch(function (error) {
    hideLoader()
    showToast(error?.response?.data?.errorMessage ?? error?.message);;
  })
};