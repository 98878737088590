import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const MaterialBillSubmission = Loadable(lazy(() => import('./MaterialBillSubmission')));
const MaterialBillVerification = Loadable(lazy(() => import('./MaterialBillVerification')));
const MaterialBillRecommendation = Loadable(lazy(() => import('./MaterialBillRecommendation')));
const MaterialBillApproval = Loadable(lazy(() => import('./MaterialBillApproval')));

const materialBillRoutes = [
    {
        path: '/MaterialBillSubmission',
        element: <MaterialBillSubmission />,
    },
    {
        path: '/MaterialBillVerification',
        element: <MaterialBillVerification />,
    },
    {
        path: '/MaterialBillRecommendation',
        element: <MaterialBillRecommendation />,
    },
    {
        path: '/MaterialBillApproval',
        element: <MaterialBillApproval />,
    }

];

export default materialBillRoutes;