import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { Loader } from "app/components";
import { getMaterialBillDetailsReport } from "app/redux/actions/ReportsAction";
import { getAllMaterialBillBySupplyOrderId, getAllProjectLocationByAgency, getSupplierByProjectLocationReport } from "app/redux/actions/ResourceForeCastActions";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import jsPDF from "jspdf";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

export const MaterialBillDetailsReport = () => {
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [supplyOrderList, setSupplyOrderList] = useState()
  const [suppliers, setSuppliers] = useState()
  const { projectLocationListForAgency } = useSelector((state) => state?.resourceForecast)
  const [materialBillList, setMaterialBillList] = useState()
  const [tableData, setTableData] = useState([])
  const [totalAmount, setTotalAmount] = useState()
  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationByAgency(showLoader, hideLoader, showToast))
  }, []);

  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (message, type) => {
    const key = enqueueSnackbar(message ? message : 'Something went wrong', { variant: type === "info" ? 'info' : 'error' });
    type === "info" ? SnackBarProperty.info(key, closeSnackbar) : SnackBarProperty.error(key, closeSnackbar)

  }

  const hideLoader = () => {
    setLoading(false)
  }

  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      setSuppliers()
      setSupplyOrderList()
      setMaterialBillList()
      const filteredArray = projectLocationListForAgency.filter(project => {
        return project.project_location_id === event.target.value
      });


      setState({
        ...state, [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        project_location: filteredArray[0].project_location,
        supply_order_no: null,
        supplyorder_id: null,
        order_date: null,
        supplier: null,
        supplier_id: null,
        material_bill_id: null,
        bill_no: null,
        bill_date: null
      });
      setLoading(true)
      getSupplierByProjectLocationReport({ project_location_id: event.target.value }).then(res => {
        setLoading(false)

        if (res?.status < 300) {
          if (res?.data?.obj?.item?.length > 0) {
            setSupplyOrderList(res?.data?.obj?.item)
          }
          else {
            const key = enqueueSnackbar("No supply order found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }


        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })

    }

    else if (event.target.name === "supplyorder_id") {
      setTableData([])
      setMaterialBillList()
      setSuppliers()
      const filteredArray = supplyOrderList.filter(supplyOrder => {
        return supplyOrder.supplyorder_id === event.target.value
      });
      setState({
        ...state, [event.target.name]: event.target.value,
        supply_order_no: filteredArray[0].supplyorder_no,
        // req_no:filteredArray[0].requisition_no,
        order_date: moment(filteredArray[0].supplyorder_date).format("DD-MM-YYYY"),
        supplier: "",
        supplier_id: null,
        material_bill_id: null,
        bill_no: null,
        bill_date: null
        // req_year:filteredArray[0].req_year,
        // category:filteredArray[0].resourceforecast_category_name
      });
      setSuppliers(filteredArray)

    }
    else if (event.target.name === "supplier_id") {
      setTableData([])
      setMaterialBillList()
      const filteredArray = suppliers?.filter(supplyOrder => {
        return supplyOrder.supplier_id === event.target.value
      });
      setState({
        ...state, [event.target.name]: event.target.value,
        supplier: filteredArray[0].supplier,
        bill_no: "",
        bill_date: "",
        material_bill_id: null
      });
      setLoading(true)
      getAllMaterialBillBySupplyOrderId({ supplyorder_id: filteredArray[0].supplyorder_id }).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj?.length > 0) {
            setMaterialBillList(res?.data?.obj)
          }
          else {
            const key = enqueueSnackbar("No Material bill found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })

    }
    else {
      setTableData([])
      const filteredArray = materialBillList?.filter(bill => {
        return bill.materialbill_id === event.target.value
      });
      setState({
        ...state, [event.target.name]: event.target.value,
        bill_no: filteredArray[0]?.materialbill_no,
        bill_date: moment(filteredArray[0]?.materialbill_date).format("DD-MM-YYYY")
      });
    }

  }


  const handleFormSubmit = () => {
    setTableData([])
    setLoading(true)
    getMaterialBillDetailsReport(state?.project_location_id, state?.supplier_id, state?.supplyorder_id, state?.material_bill_id).then(res => {
      setLoading(false)
      if (res?.status < 300) {
        if (res?.data?.obj?.item?.material_Bill_details_report?.materialBill?.materialBillDetailsList?.length > 0) {
          setTableData(res?.data?.obj?.item?.material_Bill_details_report?.materialBill?.materialBillDetailsList)
        }
        else {
          const key = enqueueSnackbar("No items found", { variant: 'info' });
          SnackBarProperty.info(key, closeSnackbar);
        }

      }
      else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(error => {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);

    })
  }

  useEffect(() => {
    if (tableData?.length > 0) {
      setTotalAmount(tableData?.reduce((acc, item) => acc + item?.amount, 0))
    }
  }, [tableData])


  const handleReportDownload = (isPrint) => {
    const doc = new jsPDF();
    const contentHeight = 150;
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    // html2canvas(headerImage, {
    //   useCORS: true, // Add this line if the image is from a different domain
    //   allowTaint: true, // Add this line if the image is from a different domain
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");

    // Calculate the width and height of the header image based on the PDF page width
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 50;
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //   const headerHeight = pdfHeight;
    //   const tableY = headerHeight + 15;
    // Insert the header image into the PDF
    const topBorderPadding = 20; // Adjust the padding value as needed
    doc.setDrawColor(255, 255, 255); // Black color for border
    doc.setLineWidth(topBorderPadding); // Border width (padding)

    // Draw the top border (padding)
    // doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

    // Insert the header image into the PDF
    //   doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
    const textX = 15;
    const textY = 20 + 5;
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFontSize(12); // Set font size

    doc.text("MATERIAL/TOOLS AND PLANTS BILL DETAILS REPORT", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("MATERIAL BILL DETAILS REPORT") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding);


    const textWidth = doc.getStringUnitWidth("Supply Order Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;


    doc.setFontSize(8); // Set font size
    doc.text("Project Location:", 15, topBorderPadding + 20);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_location}`, 15 + textWidth - 5, topBorderPadding + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Name:", 15, topBorderPadding + 25);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_name}`, 15 + textWidth - 5, topBorderPadding + 25);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Id:", 15, topBorderPadding + 30);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_id}`, 15 + textWidth - 5, topBorderPadding + 30);
    doc.setFont('helvetica', 'bold');
    doc.text("Supplier Name:", 15, topBorderPadding + 35);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.supplier}`, 15 + textWidth - 5, topBorderPadding + 35);
    doc.setFont('helvetica', 'bold');
    doc.text("Supply Order No.:", 15, topBorderPadding + 40);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.supply_order_no}`, 15 + textWidth - 5, topBorderPadding + 40);
    doc.setFont('helvetica', 'bold');
    doc.text("Supply Order Date:", 15, topBorderPadding + 45);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.order_date}`, 15 + textWidth - 5, topBorderPadding + 45);
    doc.setFont('helvetica', 'bold');
    doc.text("Bill No:", 15, topBorderPadding + 50);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.bill_no}`, 15 + textWidth - 5, topBorderPadding + 50);
    doc.setFont('helvetica', 'bold');
    doc.text("Bill Date:", 15, topBorderPadding + 55);
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.bill_date}`, 15 + textWidth - 5, topBorderPadding + 55);

    doc.setFont('helvetica', 'normal');

    const headerSectionHeight = 60; // Adjust the value as needed

    // Calculate the starting Y-coordinate for the table
    const tableStartY = headerSectionHeight;
    doc.setFont('helvetica', 'normal');
    const body = []


    tableData && tableData?.length > 0 && tableData?.forEach((elem, index) => {

      body.push(
        [
          { content: index + 1, styles: { fontSize: 8 } },
          { content: elem?.bml_item, styles: { fontSize: 8 } },
          { content: elem?.unit_name, styles: { fontSize: 8 } },
          { content: parseFloat(elem?.received_quantity ? elem?.received_quantity : 0).toFixed(2), styles: { halign: "right", fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0), styles: { halign: "right", fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0), styles: { halign: "right", fontSize: 8 } }
        ]
      )



    })
    if (tableData?.length > 0) {
      body.push([{ content: "Total Amount (Nu)", colSpan: 5, styles: { fontStyle: "bold", valign: "middle", halign: "right", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
    }
    doc.autoTable({
      startY: topBorderPadding + 70,
      head: [[
        { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "BML Item", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } }
      ]],
      body: body, // Use the transformed data here
      theme: "grid",
      styles: {
        fillColor: [255, 255, 255], // Remove background color from the table
        lineColor: [0, 0, 0], // Set border color for main table
        lineWidth: 0.1, // Set border width for main table
        textColor: [0, 0, 0], // Set text color to black
        cellPadding: 2, // Add padding to cells
      },
      // columnStyles: {
      //   2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
      // },
    });

    // Function to add footer on each page
    //   function addFooter(pageNumber) {
    //     // const footerText = "Hello CMS User";
    //     // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
    //     const footerX = 0; // Starting X position for the footer
    //     const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
    //     const pdfWidth = doc.internal.pageSize.getWidth();
    //     const pdfHeight = 10;
    //   doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
    //   }

    // Get the total number of pages
    //   const totalNumPages = doc.internal.getNumberOfPages();

    //   // Loop through each page to add the footer with page numbers
    //   for (let i = 1; i <= totalNumPages; i++) {
    //     doc.setPage(i);
    //     addFooter(i);
    //   }


    const scaleFactor = 1.0; // Adjust this value as needed
    const pdfBlob = doc.output('blob', { scale: scaleFactor });

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open a new window with the PDF URL

    if (isPrint) {
      const newWindow = window.open(pdfUrl, '_blank');

      // After the new window is opened, you can close the URL object
      URL.revokeObjectURL(pdfUrl);
      if (newWindow) {
        newWindow.onload = () => {
          setIsPrinting(false)
          setIsDownloading(false)
          // Wait for the window to load and then trigger the print dialog
          newWindow.print();
        };
      }
    }

    setIsPrinting(false)
    setIsDownloading(false)

    isPrint === false && doc.save('Material_Bill_Details_Report.pdf');


  };


  return (
    <>
      <Container>
        <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

          <Grid container>

            <Grid item xs={12}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="error"
                onClick={() => { window.history.back() }}
              >
                <Icon color="primary" fontSize="small">
                  skip_previous
                </Icon>
                Back
              </Button>
            </Grid>
          </Grid>
          <SimpleCard>

            <ValidatorForm onSubmit={handleFormSubmit}>
              <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
                <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Material/Tools and Plants Bill Details</b></Grid>
              </Grid>
              <Grid container spacing={3} >

                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Project ID"
                    id="project_location_id"
                    variant="outlined"
                    name="project_location_id"
                    value={state?.project_location_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Project ID is required"]}
                  >
                    {projectLocationListForAgency?.map((item) => (
                      <MenuItem value={item?.project_location_id} key={item?.project_id}>
                        {item?.project_id}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Supply Order No."
                    id="supply_order_id"
                    variant="outlined"
                    name="supplyorder_id"
                    value={state?.supplyorder_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Supply Order No. is required"]}
                  >
                    {supplyOrderList?.map((item) => (
                      <MenuItem value={item?.supplyorder_id} key={item?.supplyorder_id}>
                        {item?.supplyorder_no}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Supplier"
                    id="supplier_id"
                    variant="outlined"
                    name="supplier_id"
                    value={state?.supplier_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Supplier is required"]}
                  >
                    {suppliers?.map((item) => (
                      <MenuItem value={item?.supplier_id} key={item?.supplyorder_id}>
                        {item?.supplier}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
              </Grid>

              <Grid container spacing={3} >
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Material Bill No."
                    id="material_bill_id"
                    variant="outlined"
                    name="material_bill_id"
                    value={state?.material_bill_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Material Bill No. is required"]}
                  >
                    {materialBillList?.map((item) => (
                      <MenuItem value={item?.materialbill_id} key={item?.materialbill_id}>
                        {item?.materialbill_no}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>

                <Grid item sm={4} xs={12}>


                  <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

                </Grid>

              </Grid>

            </ValidatorForm>
          </SimpleCard>
          {
            tableData && tableData?.length > 0 &&
            <Grid container>
              <Grid item xs={12}>
                <Box component="span" display="flex" justifyContent="flex-end">
                  <Button
                    size="small"
                    sx={{ width: "130px", marginLeft: 4 }}
                    variant="contained"
                    color="warning"
                    startIcon={<CloudDownload />}
                    onClick={() => { handleReportDownload(false) }}
                  >
                    Download
                  </Button>
                  <Button
                    size="small"
                    sx={{ width: "100px", marginLeft: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Print />}
                    onClick={() => { handleReportDownload(true) }}
                  >
                    Print
                  </Button>
                </Box>
              </Grid>

            </Grid>}
          {/* table */}
          {
            tableData && tableData?.length > 0 &&
            <Card sx={{ padding: 3 }}>

              <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                  {/* <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
          <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

        </div> */}

                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>Material/Tools and Plants Bill Details Report</b></FormLabel>
                  </Grid>


                  <Grid container >
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{state?.project_id}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Supplier Name: </b>{state?.supplier}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Supply Order No.: </b>{state?.supply_order_no}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Supply Order Date: </b>{state?.order_date}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Bill No: </b>{state?.bill_no}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Bill Date: </b>{state?.bill_date}</FormLabel>
                    </Grid>


                  </Grid>
                </div>

                <div id="tableData">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead style={{ backgroundColor: '#87CEEB' }}>
                        <TableRow>
                          <TableCell width={"5%"} align="center">SL No.</TableCell>
                          <TableCell width={"40%"} align="center">BML Item</TableCell>
                          <TableCell width={"15%"} align="center">Unit</TableCell>
                          <TableCell width={"10%"} align="right">Quantity</TableCell>
                          <TableCell width={"15%"} align="right">Rate (Nu)</TableCell>
                          <TableCell width={"15%"} align="right">Amount (Nu)&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          tableData?.map((elem, index) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="center">{elem?.bml_item}</TableCell>
                                <TableCell align="center">{elem?.unit_name}</TableCell>
                                <TableCell align="right">{parseFloat(elem?.received_quantity ? elem?.received_quantity : 0).toFixed(2)}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0)}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0)}</TableCell>
                              </TableRow>
                            )
                          })

                        }
                        {tableData?.length > 0 &&
                          <TableRow>
                            <TableCell colSpan={5} align="right"><b>Total Amount (Nu)</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                          </TableRow>
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>
            </Card>}

        </div>
        {loading && (
          <Loader
            show={loading}
          />
        )}
        {(isPrinting || isDownloading) && (
          <Loader
            show={isPrinting ? isPrinting : isDownloading}
            text="Please Wait While Generating PDF..."
          />
        )}
      </Container>
    </>
  )
}