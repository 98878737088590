import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PhysicalProgress = Loadable(lazy(() => import('./PhysicalProgress')));

const physicalProgressRoutes = [
  {
    path: '/physicalProgress',
    element: <PhysicalProgress/>,
  }
];

export default physicalProgressRoutes;