import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ProjectBoQ = Loadable(lazy(() => import('./ProjectBoQ')));

const projectBoQRoutes = [
  {
    path: '/projectBoQ',
    element: <ProjectBoQ/>,
  }
];

export default projectBoQRoutes;