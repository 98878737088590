import { Box, Button, styled } from '@mui/material';
import { SimpleCard } from 'components';
import { H2 } from 'components/Typography';
import { useNavigate } from 'react-router-dom';
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const StyledH2 = styled(H2)(() => ({ marginTop: 10 }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '0px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const FormContainer = styled(JustifyBox)(() => ({
  background: 'teal',
  minHeight: '100% !important',
  '& .card': {
    maxWidth: 800,
    minHeight: 300,
    margin: '1rem',
    display: 'flex',
    borderRadius: 12,
    alignItems: 'center',
  },
}));

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const PasswordResetProcess = () => {

  const navigate = useNavigate();

  const onPressOk = () => {
    const accessToken = window.localStorage.getItem('accessToken')
    console.log('my access token', accessToken)
    if (accessToken) {
      console.log('with token clicked')
      navigate('/reset-password');
    } else {
      console.log('without token clicked')
      navigate('/reset-password-step2')
    }
  }

  return (
    <Container>
      <ContentBox>
        <SimpleCard>
          <H2>One time token is sent to your registered email to be used for Password Reset.</H2>
          <H2>Your user name is the email provided during registration.</H2>
          <H2>Reset your password as follows:</H2>
          <StyledH2>1. Login into the system using the one time token</StyledH2>
          <H2>2. Change your password and log out</H2>
          <H2>3. Login with new password.</H2>
          <Box display="flex" justifyContent='flex-end' marginTop={5} >
            <Button size="medium" color="primary" variant="contained" style={{ width: '7%' }} onClick={() => onPressOk()}>Proceed</Button>
          </Box>
        </SimpleCard>


      </ContentBox>
    </Container>
  );
};

export default PasswordResetProcess;
