import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const StagePercentage = Loadable(lazy(() => import('./StagePercentage')));

const stagePercentageRoutes = [
  {
    path: '/stagePercentage',
    element: <StagePercentage />,
  }
];

export default stagePercentageRoutes;