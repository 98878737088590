import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Users = Loadable(lazy(() => import('./Users')));

const usersRoutes = [
  {
    path: '/users',
    element: <Users/>,
  }
];

export default usersRoutes;