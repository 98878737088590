import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationByAgency, getAllRecoveryStatementByProjectLocationId, getRecoveryStatementForDepartmentalWorks, getWorkDoneAmountByProjectLocationId } from "app/redux/actions/ResourceForeCastActions";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { SimpleCard } from "components";
import jsPDF from "jspdf";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));
  
  const styles = {
    tableCell: {
      textAlign: "center"
    },
    subHeader: {
      textAlign: "center",
      fontWeight: 700
    }
  
  }
  const DecoratedTextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
  }));

export const RecoveryStatementReport=()=>{
    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [totalAmount, setTotalAmount] = useState()
    const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
    // const { resourceForecastList} = useSelector((state) => state?.resourceForecast)
    const [tableData, setTableData] = useState([])
    // const { recoveryStatementList } = useSelector((state) => state?.resourceForecast)
    const { projectLocationListForAgency,resourceForecastList,recoveryStatementList} = useSelector((state) => state?.resourceForecast)
     const [workDoneAmountData,setWorkDoneAmountData]=useState()
    const [state, setState] = useState({
        project_location_id: '',
      })

      const fetchDataSequentially=async()=>{

        try {
          dispatch(getRecoveryStatementForDepartmentalWorks(state?.project_location_id, showLoader, false, showToast))
          // Simulate another asynchronous operation if needed
          await new Promise((resolve) => setTimeout(resolve, 2000));
          getWorkDoneAmountByProjectLocationId({project_location_id:state?.project_location_id}).then(res=>{
            getAllRecoveryStatementByProjectLocationId({project_location_id:state?.project_location_id}).then(res=>{
                hideLoader()
                if(res?.status<300&&res?.data?.success){
                  if(res?.data?.obj&&Object.keys(res?.data?.obj).length>0)
                  {
                    const obj={
                      recoverystatement_id:res?.data?.obj?.recoverystatement_id,
                      justification:res?.data?.obj?.justification
                  }
                setState({...state,...obj})
                }
                else{
                  const key = enqueueSnackbar("No recovery statement found", { variant: 'info' });
    SnackBarProperty.info(key, closeSnackbar);
                }
              }
                 }).catch(err=>{
                   hideLoader()
                   showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
                 })
            if(res?.status<300&&res?.data?.success){
             setWorkDoneAmountData(res?.data?.obj)
            }
            else{
             showToast(res?.data?.message)
            }
             }).catch(err=>{
             
               showToast(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong)
             })
            //  await new Promise((resolve) => setTimeout(resolve, 2000));
            
        }
        catch {
           const key = enqueueSnackbar(AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }

      function getTotalAmount() {
        if (recoveryStatementList?.length === 0) {
          return 0
        }
        const newRecoveryStatementList = [...recoveryStatementList]
        const totalCost = newRecoveryStatementList.reduce(
          (previousValue, currentValue, index) => previousValue + currentValue.expenditure_amount, 0);
        return parseFloat(totalCost).toFixed(2)
      }

      function getDifference() {
   
        return parseFloat(parseFloat(parseFloat(workDoneAmountData?.[0]?.workdone_amount?workDoneAmountData?.[0]?.workdone_amount:0)-getTotalAmount()).toFixed(2))
      }

    useEffect(() => {
        dispatch(getAllProjectLocationByAgency(showLoader, hideLoader, showToast))
      }, []);

      const showLoader = () => {
        setLoading(true)
      }
      const showToast = (message,type) => {
        const key = enqueueSnackbar(message ? message:AppConstants.somethingWentWrong, { variant: type==="info"?"info":'error' });
        type==="info"?SnackBarProperty.info(key, closeSnackbar):SnackBarProperty.error(key, closeSnackbar)
    
      }
    
      const hideLoader = () => {
        setLoading(false)
      }

      const handleChange = (event, source) => {

        if (event.target.name === "project_location_id") {
          setTableData([])
          const filteredArray = projectLocationListForAgency.filter(project => {
            return project.project_location_id === event.target.value
          });
          
    
          setState({ ...state, [event.target.name]: event.target.value,
            project_name : filteredArray[0].project_name,
            project_location_id : filteredArray[0].project_location_id,
            project_id : filteredArray[0].project_id,
            project_location:filteredArray[0].project_location
         });
    
        } else {
          setState({ ...state, [event.target.name]: event.target.value });
        }
      }
    
    
      const handleFormSubmit = () => {
        setTableData([])
       // dispatch(getResourceForecastDetails(state?.project_location_id,0,showLoader,hideLoader,showToast ))
       fetchDataSequentially()
      }

      useEffect(()=>{
        if(resourceForecastList?.length>0){
            setTableData(resourceForecastList)
        }
      },[resourceForecastList])


      const handleReportDownload = (isPrint) => {
        const doc = new jsPDF();
        const contentHeight = 150;
        if (isPrint) {
          setIsPrinting(true)
          setIsDownloading(false)
        }
        else {
          setIsPrinting(false)
          setIsDownloading(true)
        }
        const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image
    
        doc.setFontSize(12); // Set font size
    
    
        // html2canvas(headerImage, {
        //   useCORS: true, // Add this line if the image is from a different domain
        //   allowTaint: true, // Add this line if the image is from a different domain
        // }).then((canvas) => {
        //   const imgData = canvas.toDataURL("image/png");
    
          // Calculate the width and height of the header image based on the PDF page width
        //   const pdfWidth = doc.internal.pageSize.getWidth() - 50;
        //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        //   const headerHeight = pdfHeight;
        //   const tableY = headerHeight + 15;
          // Insert the header image into the PDF
          const topBorderPadding = 20; // Adjust the padding value as needed
          doc.setDrawColor(255, 255, 255); // Black color for border
          doc.setLineWidth(topBorderPadding); // Border width (padding)
    
          // Draw the top border (padding)
         // doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);
    
          // Insert the header image into the PDF
        //   doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
          const textX = 15;
          const textY = 20 + 5;
          doc.setFont('helvetica', 'bold'); // Set font to bold
          doc.setFontSize(12); // Set font size
    
          doc.text("Recovery Statement Report", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Recovery Statement Report") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding );
    
          doc.setFontSize(8); // Set font size
          doc.text("Project Location:", 15, topBorderPadding +20);
          const firstTextWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
          doc.setFont('helvetica', 'normal');
          doc.text(`${state?.project_location}`, 15 + firstTextWidth + 5, topBorderPadding +20);
          doc.setFont('helvetica', 'bold');
          doc.text("Project Name:", 15, topBorderPadding + 25);
          const secondTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
          doc.setFont('helvetica', 'normal');
          doc.text(`${state?.project_name}`, 15 + secondTextWidth + 5, topBorderPadding + 25);
          doc.setFont('helvetica', 'bold');
          doc.text("Project Id:", 15, topBorderPadding + 30);
          const thirdTextWidth = doc.getStringUnitWidth("Project Id:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
          doc.setFont('helvetica', 'normal');
          doc.text(`${state?.project_id}`, 15 + thirdTextWidth + 5, topBorderPadding + 30);
    
          doc.setFont('helvetica', 'normal');
    
          const headerSectionHeight = 60; // Adjust the value as needed
    
          // Calculate the starting Y-coordinate for the table
          const tableStartY = headerSectionHeight;
          doc.setFont('helvetica', 'normal');
          const body = []
    
    
          recoveryStatementList && recoveryStatementList?.length > 0 && recoveryStatementList?.forEach((elem,index) => {
    
            body.push(
              [index+1,
              elem?.description,
              { content:  parseFloat(elem?.expenditure_amount?elem?.expenditure_amount:0).toFixed(2) || 0.00},
              ]
            )

          
    
    
    
          })
          body.push([
            {content:"Total Expenditure Amount (Nu) A",colSpan:2,styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8,fontStyle:"bold" }},
            getTotalAmount()
        ])
        body.push([
            {content:"Work Done Amount (Nu) B",colSpan:2,styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8,fontStyle:"bold" }},
            parseFloat(workDoneAmountData?.[0]?.workdone_amount?workDoneAmountData?.[0]?.workdone_amount:0).toFixed(2)
        ])
        body.push([
            {content:"Difference Amount (Nu) (B-A)",colSpan:2,styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8,fontStyle:"bold" }},
            parseFloat(getDifference()?getDifference():0).toFixed(2)
        ])
        //   if (tableData?.length > 0) {
        //     body.push([{ content: "Grand Total", colSpan: 6, styles: { fontStyle: "bold", valign: "middle", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold" } }])
        //   }
          doc.autoTable({
            startY: topBorderPadding+40,
            head: [[
              { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Particulars", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
              { content: "Expenditure", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
             ]],
            body: body, // Use the transformed data here
            theme: "grid",
            styles: {
              fillColor: [255, 255, 255], // Remove background color from the table
              lineColor: [0, 0, 0], // Set border color for main table
              lineWidth: 0.1, // Set border width for main table
              textColor: [0, 0, 0], // Set text color to black
              cellPadding: 2, // Add padding to cells
            },
            columnStyles: {
              2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
            },
          });
    
          // Function to add footer on each page
        //   function addFooter(pageNumber) {
        //     // const footerText = "Hello CMS User";
        //     // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        //     const footerX = 0; // Starting X position for the footer
        //     const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        //     const pdfWidth = doc.internal.pageSize.getWidth();
        //     const pdfHeight = 10;
        //     doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
        //   }
    
          // Get the total number of pages
        //   const totalNumPages = doc.internal.getNumberOfPages();
    
        //   // Loop through each page to add the footer with page numbers
        //   for (let i = 1; i <= totalNumPages; i++) {
        //     doc.setPage(i);
        //     addFooter(i);
        //   }
    
    
          const scaleFactor = 1.0; // Adjust this value as needed
          const pdfBlob = doc.output('blob', { scale: scaleFactor });
    
          // Create a URL for the Blob
          const pdfUrl = URL.createObjectURL(pdfBlob);
    
          // Open a new window with the PDF URL
    
          if (isPrint) {
            const newWindow = window.open(pdfUrl, '_blank');
    
            // After the new window is opened, you can close the URL object
            URL.revokeObjectURL(pdfUrl);
            if (newWindow) {
              newWindow.onload = () => {
                setIsPrinting(false)
                setIsDownloading(false)
                // Wait for the window to load and then trigger the print dialog
                newWindow.print();
              };
            }
          }
    
          setIsPrinting(false)
          setIsDownloading(false)
    
          isPrint === false && doc.save('Recovery_Statement_Report.pdf');
    
       
      };
    

    return(
        <>
        <Container>
        <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

<Grid container>

  <Grid item xs={12}>
    <Button
      size="small"
      sx={{ width: "100px" }}
      variant="contained"
      color="error"
      onClick={() => { window.history.back() }}
    >
      <Icon color="primary" fontSize="small">
        skip_previous
      </Icon>
      Back
    </Button>
  </Grid>
</Grid>
<SimpleCard>

  <ValidatorForm onSubmit={handleFormSubmit}>
    <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
      <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Recovery Statement</b></Grid>
    </Grid>
    <Grid container spacing={3}>

      <Grid item sm={5} xs={12}>
        <DecoratedTextField
          select
          size="small"
          fullWidth
          sx={{ mb: 3 }}
          label="Project ID"
          id="project_location_id"
          variant="outlined"
          name="project_location_id"
          value={state?.project_location_id}
          onChange={handleChange}
          validators={["required"]}
          errorMessages={["Project ID is required"]}
        >
          {projectLocationListForAgency?.map((item) => (
            <MenuItem value={item?.project_location_id} key={item?.project_id}>
              {item?.project_id}
            </MenuItem>
          ))}
        </DecoratedTextField>
      </Grid>

      <Grid item sm={4} xs={12}>


        <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

      </Grid>

    </Grid>

  </ValidatorForm>
</SimpleCard>
{
  workDoneAmountData && workDoneAmountData?.length > 0 &&recoveryStatementList&&recoveryStatementList?.length>0&&
  <Grid container>
    <Grid item xs={12}>
      <Box component="span" display="flex" justifyContent="flex-end">
        <Button
          size="small"
          sx={{ width: "130px", marginLeft: 4 }}
          variant="contained"
          color="warning"
          startIcon={<CloudDownload />}
          onClick={() => { handleReportDownload(false) }}
        >
          Download
        </Button>
        <Button
          size="small"
          sx={{ width: "100px", marginLeft: 2 }}
          variant="contained"
          color="primary"
          startIcon={<Print />}
          onClick={() => { handleReportDownload(true) }}
        >
          Print
        </Button>
      </Box>
    </Grid>

  </Grid>}
{/* table */}
{
  workDoneAmountData && workDoneAmountData?.length > 0 &&recoveryStatementList&&recoveryStatementList?.length>0&&
  <Card sx={{ padding: 3 }}>

    <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
      <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
        {/* <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
          <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

        </div> */}

        <Grid item xs={12} align='center' >
          <FormLabel sx={{ fontSize: 22 }} ><b>Recovery Statement Report</b></FormLabel>
        </Grid>


        <Grid container >
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{state?.project_id}</FormLabel>
          </Grid>


        </Grid>
      </div>

      <div id="tableData">
        <TableContainer component={Paper}>
        <Table>
            <TableHead backgroundColor='#87CEEB'>
              <TableRow style={{ backgroundColor: "#87CEEB" }}>
                <TableCell width="10%" align="center">Sl No.</TableCell>
                <TableCell width="60%" >Particulars</TableCell>
                <TableCell width="30%" >Expenditure</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {recoveryStatementList?.map((item, index) => (
                <TableRow>

                  <TableCell sx={{ px: 0 }} align="center">
                    {(index + 1)}
                  </TableCell>
                  <TableCell sx={{ px: 0 }} >
                    {item?.description}
                  </TableCell>
                  <TableCell sx={{ px: 0 }} >
                    {parseFloat(item?.expenditure_amount?item?.expenditure_amount:0).toFixed(2) || 0.00}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <br />
            <TableHead>
              <TableRow style={{ backgroundColor: '#efefef' }}>
                <TableCell></TableCell>
                <TableCell rows="2" colSpan={"1"} align="left">Total Expenditure Amount (Nu) A</TableCell>
                <TableCell colSpan={"2"} align="left"><h3>{getTotalAmount()}</h3></TableCell>
              </TableRow>
            </TableHead>

            <TableHead>
              <TableRow style={{ backgroundColor: '#efefef' }}>
                <TableCell></TableCell>
                <TableCell rows="2" colSpan={"1"} align="left">Work Done Amount (Nu) B</TableCell>
                <TableCell colSpan={"2"} align="left"><h3>{parseFloat(workDoneAmountData?.[0]?.workdone_amount?workDoneAmountData?.[0]?.workdone_amount:0).toFixed(2)}</h3></TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow style={{ backgroundColor: '#fefefe' }}>
                <TableCell></TableCell>
                <TableCell rows="2" colSpan={"1"} align="left">Difference Amount (Nu) (B-A)</TableCell>
                <TableCell colSpan={"2"} align="left"><h3>{parseFloat(getDifference()?getDifference():0).toFixed(2)}</h3></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

      </div>
    </div>
  </Card>}

</div>
{loading && (
<Loader
  show={loading}
/>
)}
{(isPrinting || isDownloading) && (
<Loader
  show={isPrinting ? isPrinting : isDownloading}
  text="Please Wait While Generating PDF..."
/>
)}
        </Container>
        </>
    )
}