import { GET_ALL_UNITS_INVENTORY, GET_BML_ITEMS_GOOD_RECEIPT, GET_GOODS_ADJUSTMENT_LIST, GET_GOODS_ISSUE_LIST, GET_GOODS_RECEIPT_LIST, GET_ITEMS_GOOD_ISSUE, GET_ITEM_CATALOGUE_LIST, GET_PROJECT_LOCATION_LIST_INVENTORY } from "../actions/InvenoryActions";


const initialState = {
  projectLocationList: [],
  goodsReceiptList: [],
  goodsIssueList: [],
  goodsAdjustmentList: [],
  itemCodeList: [],
  itemList: [],
  itemCatalogueList: []
};

const InventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_UNITS_INVENTORY: {
      return {
        ...state,
        unitList: [...action.payload],
      };
    }
    case GET_ITEM_CATALOGUE_LIST: {
      return {
        ...state,
        itemCatalogueList: [...action.payload],
      };
    }
    case GET_ITEMS_GOOD_ISSUE: {
      return {
        ...state,
        itemList: [...action.payload],
      };
    }
    case GET_BML_ITEMS_GOOD_RECEIPT: {
      return {
        ...state,
        itemCodeList: [...action.payload],
      };
    }
    case GET_GOODS_RECEIPT_LIST: {
      return {
        ...state,
        goodsReceiptList: [...action.payload],
      };
    }

    case GET_PROJECT_LOCATION_LIST_INVENTORY: {
      return {
        ...state,
        projectLocationList: [...action.payload],
      };
    }

    case GET_GOODS_ISSUE_LIST: {
      return {
        ...state,
        goodsIssueList: [...action.payload],
      };
    }

    case GET_GOODS_ADJUSTMENT_LIST: {
      return {
        ...state,
        goodsAdjustmentList: [...action.payload],
      };
    }

    default: {
      return { ...state }
    }
  }
};

export default InventoryReducer;
