import { Button, Card, Divider, Grid, Icon, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Tooltip, createTheme, styled } from "@mui/material";
import { Box } from "@mui/system";
import { Breadcrumb, ConfirmationDialog, Loader, SimpleCard } from "app/components";
import { FlexBox } from "app/components/FlexBox";
import { approveNotApproveRateAnalysisForRABill, getAlRunningAccountBillDetailsListById, getRABillRateAnalysis } from "app/redux/actions/ImplementationActions";
import { deleteRunningAccountBillDetailsDOM, getAllProjectLocationListForBill, getAllRunningAccountBillByProjectLocation, getRABillDoMList, getRunningAccountBillByProjectLocationId } from "app/redux/actions/RABillActions";
import { AppConstants } from "app/utils/AppConstants";
import { ProjectStatus } from "app/utils/ProjectStatus";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, currencyFormat, getStatus, getTwoDigitDecimal, goToViolation } from "app/utils/utils";
import { H3, H4 } from "components/Typography";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import NewRABillDialog from "./NewRABillDialog";
import NewRABillVerificationDialog from "./NewRABillVerificationDialog";
import RABillAmendmentDialog from "./RABillAmendmentDialog";
import { RABillRateDetailsDialog } from "./RABillRateDetailsDialog";
import RABillRecoveryDialog from "./RABillRecoveryDialog";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const MyTable = styled(Table)(() => ({
  minWidth: 750,
  whiteSpace: "pre",
  overflowX: "auto",
  "& thead": { "& th:first-of-type": { paddingLeft: 16 } },
  "& tbody": { // Adding styles for table body
    "& tr:hover": {
      backgroundColor: "#f5f5f5", // Change this color to your desired hover background color
      cursor: "pointer", // Change cursor style on hover
    }
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" },
}));

const MyCard = styled(Card)(() => ({
  "& thead": { "& th": { padding: 10 } },
  "& td": { borderBottom: "true" },
  "& td": { padding: "5px !important", paddingLeft: "10px !important" },
}));

const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const theme = createTheme({

  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#87CEEB",
        }
      }
    }
  }
});

const Star = styled("small")(({ theme }) => ({
  color: "#f00",
  overflow: "hidden",
  fontWeight: "bolder",
}));


const RABillRecommended = () => {

  const [dialogTitle, setdialogTitle] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [raBill, setRABill] = useState({})
  const [showRABillList, setShowRABillList] = useState()
  const [raBillDetailsList, setRaBillDetailsList] = useState()
  const [shouldOpenRaBillDetailsList, setShouldOpenRaBillDetailsList] = useState(false)
  const [shouldShowDOM, setShouldShowDOM] = useState(false)
  const [domList, setDomList] = useState([])
  const [domDetailsData, setDomDetailsData] = useState(null)
  const [raBillDetails, setRaBillDetails] = useState()
  const [shouldOpenDOMDeleteDialog, setShouldOpenDOMDeleteDialog] = useState(false)
  const [subTotalDomQuantity, setSubTotalDomQuantity] = useState(0)

  const { projectLocationList, raBillList } = useSelector((state) => state.raBill);
  const [shouldOpenNewRABillDialog, setShouldOpenNewRABillDialog] = useState(false)
  const [selectedRABillDetailsIndex, setSelectedRABillDetailsIndex] = useState()
  const [shouldOpenRABillVerificationDialog, setShouldOpenRABillVerificationDialog] = useState(false)

  const [shouldOpenConfirmation, setShouldOpenConfirmation] = useState(false)
  const [raItemId, setRaItemId] = useState()
  const [statusType, setStatusType] = useState("APPROVED")

  const [shouldOpenDetailsDialog, setShouldOpenDetailsDialog] = useState(false)
  const [raBillRateDetails, setRaBillRateDetails] = useState(false)
  const [rateAnalysisDetails, setRateAnalysisDetails] = useState({})
  const [shouldOpenSentForReviewDialog, setShouldOpenSentForReviewDialog] = useState(false)

  const [shouldOpenRABillAmendmentDialog, setShouldOpenRABillAmendementDialog] = useState(false)
  const [shouldOpenRABillRecoveryDialog, setShouldOpenRABillRecoveryDialog] = useState(false)

  const [selectedProject, setSelectedProject] = useState()

  const [state, setState] = useState({
    project_name: '',
    project_location_id: '',
    project_location: '',
    project_id: ''
  })

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Return empty string for null or empty dates
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const raBillDetailsColumns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (rowIndex, dataIndex) => rowIndex + 1
      },
    }, {
      name: "constructiontype_name",
      label: "Construction Type",
      options: { filter: true, sort: false, },
    }, {
      name: "construction_name",
      label: "Construction Name",
      options: { filter: true, sort: false, },
    }, {
      name: "boq_name",
      label: "Name",
      options: { filter: true, sort: false, },
    }, {
      name: "stage_name",
      label: "Stage Name",
      options: { filter: true, sort: false, },
    }, {
      name: "item_code",
      label: "Item Code",
      options: { filter: true, sort: false, },
    }, {
      name: "contract_quantity",
      label: <div style={{ textAlign: 'right' }}>Con. Qty</div>,
      options: {
          filter: true,
          setCellProps: () => ({
              style: {
                  textAlign: 'right',
              },
          }),
          customBodyRender: (value) => (
              <div style={{ textAlign: 'right' }}>
                  {currencyFormat(value)}&nbsp;&nbsp;&nbsp;
              </div>
          )
      }
    }, {
      name: "contract_rate",
      label: <div style={{ textAlign: 'right' }}>Con. Rate(Nu)</div>,
      options: {
          filter: true,
          setCellProps: () => ({
              style: {
                  textAlign: 'right',
              },
          }),
          customBodyRender: (value) => (
              <div style={{ textAlign: 'right' }}>
                  {currencyFormat(value)}&nbsp;&nbsp;&nbsp;
              </div>
          )
      }
    }, {
      name: "prev_quantity",
      label: <div style={{ textAlign: 'right' }}>Prev. Qty</div>,
      options: {
          filter: true,
          setCellProps: () => ({
              style: {
                  textAlign: 'right',
              },
          }),
          customBodyRender: (value) => (
              <div style={{ textAlign: 'right' }}>
                  {currencyFormat(value)}&nbsp;&nbsp;&nbsp;
              </div>
          )
      }
    }, {
      name: "new_quantity",
      label: <div style={{ textAlign: 'right' }}>New Qty</div>,
      options: {
          filter: true,
          setCellProps: () => ({
              style: {
                  textAlign: 'right',
              },
          }),
          customBodyRender: (value) => (
              <div style={{ textAlign: 'right' }}>
                  {currencyFormat(value)}&nbsp;&nbsp;&nbsp;
              </div>
          )
      }
    }, {
      name: "deviation_quantity",
      label: <div style={{ textAlign: 'right' }}>Dev. Qty</div>,
      options: {
        filter: true, sort: false,
        setCellProps: () => ({
          style: {
              textAlign: 'right',
          },
      }),
        customBodyRenderLite: (dataIndex) => {
          const deviationQty = raBillDetailsList[dataIndex].deviation_quantity; // Access the deviation quantity
          return (
            <div
              style={{
                backgroundColor: deviationQty > 0 ? '#FFD580' : 'transparent', // Highlight with color if greater than 0
                padding: '5px', // Add some padding to the cell
                textAlign: 'center', // Center the content if necessary

              }}
            >
              {currencyFormat(deviationQty)}
            </div>
          );
        }
      },
    }, {
      name: "revised_rate",
      label: <div style={{ textAlign: 'right' }}>Rev. Rate(Nu)</div>,
      options: {
          filter: true,
          setCellProps: () => ({
              style: {
                  textAlign: 'right',
              },
          }),
          customBodyRender: (value) => (
              <div style={{ textAlign: 'right' }}>
                  {currencyFormat(value)}&nbsp;&nbsp;&nbsp;
              </div>
          )
      }
    }, {
      name: "contract_amount",
      label: <div style={{ textAlign: 'right' }}>Con. Amount(Nu)</div>,
      options: {
          filter: true,
          setCellProps: () => ({
              style: {
                  textAlign: 'right',
              },
          }),
          customBodyRender: (value) => (
              <div style={{ textAlign: 'right' }}>
                  {currencyFormat(value)}&nbsp;&nbsp;&nbsp;
              </div>
          )
      }
    }, {
      name: "deviation_amount",
      label: <div style={{ textAlign: 'right' }}>Dev. Amount(Nu)</div>,
      options: {
          filter: true,
          setCellProps: () => ({
              style: {
                  textAlign: 'right',
              },
          }),
          customBodyRender: (value) => (
              <div style={{ textAlign: 'right' }}>
                  {currencyFormat(value)}&nbsp;&nbsp;&nbsp;
              </div>
          )
      }
    },
    {
      name: "dom",
      label: "DoM",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => (
          <FlexBox alignItems="center">
            <Tooltip title="Manage DOM">
              <IconButton onClick={() => handleClickManageDom(raBillDetailsList[rowIndex])}>
                <Icon color="primary" fontSize="small">
                  tune
                </Icon>
              </IconButton>
            </Tooltip>
          </FlexBox>

        ),
      },
    },

    {
      name: "rate_analysis",
      label: "Rate Analysis",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => (
          <FlexBox alignItems="center">
            {raBillDetailsList[rowIndex]?.revised_rate > 0 && raBillDetailsList[rowIndex]?.contract_rate !== raBillDetailsList[rowIndex]?.revised_rate && <Tooltip title="Rate Analysis">
              <IconButton onClick={() => handleDetailsClick(raBillDetailsList[rowIndex], rowIndex)}>
                <Icon color="warning" fontSize="small">
                  rate_review
                </Icon>
              </IconButton>
            </Tooltip>}
          </FlexBox>

        ),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex, dataIndex) => (
          <FlexBox alignItems="center">
            {
              raBillDetailsList[rowIndex]?.revised_rate > 0 && raBillDetailsList[rowIndex]?.contract_rate !== raBillDetailsList[rowIndex]?.revised_rate &&
              <Tooltip title="Approve">
                <IconButton
                  onClick={() => {
                    setStatusType("APPROVE");
                    setShouldOpenConfirmation(true);
                    setRaItemId(raBillDetailsList[rowIndex]?.raBillRateAnalysis?.raBillRaItem?.rabillraitem_id)
                  }}
                >
                  <Icon color="success" fontSize="small">
                    done_all
                  </Icon>
                </IconButton>
              </Tooltip>}
            {
              raBillDetailsList[rowIndex]?.revised_rate > 0 && raBillDetailsList[rowIndex]?.contract_rate !== raBillDetailsList[rowIndex]?.revised_rate &&
              <Tooltip title="Reject">
                <IconButton
                  onClick={() => {
                    setStatusType("NOT_APPROVE");
                    setShouldOpenConfirmation(true);
                    setRaItemId(raBillDetailsList[rowIndex]?.raBillRateAnalysis?.raBillRaItem?.rabillraitem_id)
                  }}
                >
                  <Icon color="error" fontSize="small">
                    close
                  </Icon>
                </IconButton>
              </Tooltip>}
          </FlexBox>

        ),
      },
    }
  ]

  const columns = [
    {
      name: "index",
      label: "Sl No.",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (dataIndex) => dataIndex + 1
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: true, sort: false,
        customBodyRenderLite: (dataIndex) => raBillList[dataIndex].final_bill == true ? "FINAL" : ""
      },
    },
    {
      name: "rabill_no",
      label: "RA Bill No.",
      options: { filter: true, sort: false },
    },
    {
      name: "rabill_date",
      label: "RA Bill Date",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (dataIndex) => formatStartDate(raBillList[dataIndex].rabill_date)
      },
    },
    {
      name: "work_completion_date",
      label: "Work Completion Date",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (dataIndex) => !checkValidation(raBillList[dataIndex].work_completion_date) ? '' : formatDate(raBillList[dataIndex].work_completion_date)
      },
    }, {
      name: "bill_submission_date",
      label: "RA Bill Submission Date",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (dataIndex) => formatStartDate(raBillList[dataIndex].bill_submission_date)
      },
    }, {
      name: "verified_on",
      label: "RA Bill Verification Date",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        customBodyRenderLite: (dataIndex) => formatStartDate(raBillList[dataIndex].verified_on)
      },
    },
    {
      name: "status_id",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        Headers: "bold",
        //customBodyRenderLite: (dataIndex) => getStatus(raBillList[dataIndex].status)
        customBodyRenderLite: (dataIndex) => {
          const status = getStatus(raBillList[dataIndex].status) === "RECOMMENDED" ? 'PENDING' : getStatus(raBillList[dataIndex].status) === "REVIEWED" ? "RECOMMENDED" : getStatus(raBillList[dataIndex].status); // Replace this with your actual status data
          return (
            <span className={status === "PENDING" ? "status-cell" : ""}>
              {status}
            </span>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <FlexBox alignItems="center">
            {raBillList[dataIndex].status === ProjectStatus.RECOMMENDED && (
              <Tooltip title="View RA Bill Details">
                <IconButton onClick={() => handleClickDetails(raBillList[dataIndex], dataIndex)}>
                  <Icon color="primary" fontSize="small">
                    info
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            {raBillList[dataIndex].status === ProjectStatus.RECOMMENDED && (
              <Tooltip title="Review">
                <IconButton onClick={() => handleClickReview(raBillList[dataIndex], dataIndex)}>
                  <Icon color="success" fontSize="small">
                    visibility
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
          </FlexBox>
        ),
      },
    }
  ];


  const handleClickSendForReview = () => {
    setShouldOpenSentForReviewDialog(true)
  }

  const handleClickReview = (raBill, index) => {
    if (raBill) {
      setShouldOpenDOMDeleteDialog(false)
      setShouldOpenRaBillDetailsList(false)
      setRABill(raBill)
      setShouldOpenRABillVerificationDialog(true)
    } else {
      const key = enqueueSnackbar(AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
  }

  const handleDetailsClick = (billDetails, index) => {
    setRaBillDetails(billDetails)
    const params = {
      runningaccountbill_id: billDetails.runningaccountbill_id,
      runningaccountbill_details_id: billDetails.runningaccountbill_details_id,
      bml_item_id: billDetails.bml_item_id,
      projectlocation_id: state.project_location_id,
    }

    getRABillRateAnalysisByParams(params)

  }

  const getRABillRateAnalysisByParams = (params) => {
    setLoading(true)
    getRABillRateAnalysis(params).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success === true) {
        if (checkValidation(res?.data?.obj?.rabillrateanalysis_id) === true) {
          setRaBillRateDetails(res?.data?.obj?.raBillRaItem?.raBillRaItemDetailsList)
          setRateAnalysisDetails(res?.data?.obj?.raBillRaItem)
          setShouldOpenDetailsDialog(true)
        } else {
          setRaBillRateDetails(res?.data?.obj?.projectLocationBsrBuiltupItemsDetailsList)
          setRateAnalysisDetails(res?.data?.obj)
          setShouldOpenDetailsDialog(true)
        }

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const handleClickDetails = (raBill, index) => {
    setShouldShowDOM(false)
    setShouldOpenRaBillDetailsList(false)
    if (raBill) {
      setRABill(raBill)
      setSelectedRABillDetailsIndex(index)
      getAlRunningAccountBillDetailsListByParams(raBill.runningaccountbill_id)
    } else {
      const key = enqueueSnackbar(AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
  }

  function calculateRABillValues(billDetails, type = 'others') {
    console.log('called', billDetails, type)
    var newBillDetails = [...billDetails]
    newBillDetails?.forEach((details, index) => {

      const matchedDomItemCodes = newBillDetails?.filter((value, index) => {
        return ((value?.item_code === details?.item_code) && (value?.constructiontype_id === details?.constructiontype_id) && (value?.boq_name === details?.boq_name))
      })

      console.log('matchedDomItemCodes', matchedDomItemCodes)

      var dom_total_quantity = matchedDomItemCodes?.reduce(
        (previousValue, currentValue, index) => previousValue + currentValue?.dom_quantity, 0);

      var total_previous_quantity = matchedDomItemCodes?.reduce(
        (previousValue, currentValue, index) => previousValue + (currentValue?.prev_quantity ?? 0), 0);

      var total_contract_quantity = matchedDomItemCodes?.reduce(
        (previousValue, currentValue, index) => previousValue + (currentValue?.contract_quantity ?? 0), 0);

      dom_total_quantity = total_previous_quantity + dom_total_quantity

      console.log('dom_total_quantity', dom_total_quantity)


      if (details?.prev_is_deviated === true) {
        console.log('prev_is_deviated', details?.prev_is_deviated)
        var revisedRateMatched = ((checkValidation(details?.revised_rate) === true) && (details?.revised_rate) !== 0) ? details?.revised_rate : ((checkValidation(details?.prev_ra_rate) === true) && (details?.prev_ra_rate) !== 0) ? details?.prev_ra_rate : details?.contract_rate
        console.log('revisedRateMatched', revisedRateMatched)

        var deviatedQuantityMatched = details?.dom_quantity ?? 0
        var deviatedAmountMatched = getTwoDigitDecimal(deviatedQuantityMatched * revisedRateMatched)

        newBillDetails[index].new_quantity = 0
        newBillDetails[index].contract_amount = getTwoDigitDecimal(newBillDetails[index].new_quantity * details?.contract_rate)
        newBillDetails[index].deviation_quantity = deviatedQuantityMatched
        newBillDetails[index].deviation_amount = deviatedAmountMatched
        newBillDetails[index].revised_rate = (checkValidation(deviatedQuantityMatched) && deviatedQuantityMatched !== 0) ? getTwoDigitDecimal(revisedRateMatched) : ""
        newBillDetails[index].is_deviated = true

      } else {
        var appliedVariance = ((total_contract_quantity * state?.variance) / 100)
        var appliedNewQuantity = (appliedVariance ?? 0) + (total_contract_quantity ?? 0)

        console.log('appliedVariance', appliedVariance)
        console.log('appliedNewQuantity', appliedNewQuantity)


        if (dom_total_quantity > appliedNewQuantity && checkValidation(details?.dom_quantity) === true) {
          var revisedRate = ((checkValidation(details?.revised_rate) === true) && (details?.revised_rate) !== 0) ? details?.revised_rate : ((checkValidation(details?.prev_ra_rate) === true) && (details?.prev_ra_rate) !== 0) ? details?.prev_ra_rate : details?.contract_rate

          console.log('revisedRate', revisedRate)
          var deviatedQuantity = dom_total_quantity - appliedNewQuantity
          var deviatedAmount = getTwoDigitDecimal(deviatedQuantity * revisedRate)

          var amountB = ((state?.initial_contract_amount * state?.excess_initial_contract) / 100)

          var sum_total_quantity = 0
          matchedDomItemCodes?.forEach((elem, index) => {
            if (elem?.is_calculated === true) {
              sum_total_quantity += (elem?.dom_quantity ?? 0)
            }
          })
          sum_total_quantity = sum_total_quantity + total_previous_quantity


          console.log('new sum total quantity ', sum_total_quantity)

          if (deviatedAmount > amountB) {
            var cumQuantity = sum_total_quantity + (newBillDetails[index].dom_quantity ?? 0)
            console.log('my index', index)
            console.log('cumQuantity my index', cumQuantity)
            newBillDetails[index].new_quantity = cumQuantity < appliedNewQuantity ? (newBillDetails[index].dom_quantity ?? 0) : (sum_total_quantity >= appliedNewQuantity ? 0 : getTwoDigitDecimal(appliedNewQuantity - sum_total_quantity))
            newBillDetails[index].contract_amount = getTwoDigitDecimal(newBillDetails[index].new_quantity * details?.contract_rate)
            newBillDetails[index].deviation_quantity = cumQuantity < appliedNewQuantity ? 0 : getTwoDigitDecimal((newBillDetails[index].dom_quantity ?? 0) - (newBillDetails[index].new_quantity ?? 0))
            newBillDetails[index].deviation_amount = getTwoDigitDecimal(newBillDetails[index].deviation_quantity * revisedRate)
            newBillDetails[index].is_deviated = true
            newBillDetails[index].is_calculated = true
            newBillDetails[index].revised_rate = newBillDetails[index]?.deviation_quantity === 0 ? 0 : getTwoDigitDecimal(revisedRate)
          } else {
            newBillDetails[index].deviation_quantity = 0
            newBillDetails[index].revised_rate = 0
            newBillDetails[index].deviation_amount = 0
            newBillDetails[index].is_deviated = false
            newBillDetails[index].is_calculated = true
            newBillDetails[index].new_quantity = (newBillDetails[index].dom_quantity ?? 0) !== (newBillDetails[index].new_quantity ?? 0) ? (newBillDetails[index].dom_quantity ?? 0) : (newBillDetails[index].new_quantity ?? 0)
            newBillDetails[index].contract_amount = getTwoDigitDecimal(newBillDetails[index].new_quantity * details?.contract_rate)
          }
        } else {
          newBillDetails[index].is_deviated = false
          newBillDetails[index].is_calculated = true
          newBillDetails[index].deviation_quantity = 0
          newBillDetails[index].revised_rate = 0
          newBillDetails[index].deviation_amount = 0
          newBillDetails[index].new_quantity = (newBillDetails[index].dom_quantity ?? 0) !== (newBillDetails[index].new_quantity ?? 0) ? (newBillDetails[index].dom_quantity ?? 0) : (newBillDetails[index].new_quantity ?? 0)
          newBillDetails[index].contract_amount = getTwoDigitDecimal(newBillDetails[index].new_quantity * details?.contract_rate)
        }
      }
    })
    setRaBillDetailsList(newBillDetails)
  }


  const getAlRunningAccountBillDetailsListByParams = (rabill_id, type = 'others') => {
    setLoading(true)
    getAlRunningAccountBillDetailsListById(rabill_id, state.project_location_id).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success) {
        // calculateRABillValues(res?.data?.obj, type)
        setRaBillDetailsList(res?.data?.obj)
        setShouldOpenRaBillDetailsList(true)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    });;
  }

  useEffect(() => {
    dispatch(getAllProjectLocationListForBill(showLoader, hideLoader, showToast))
  }, []);

  const clearDatas = () => {
    setShowRABillList(false)
    setShouldShowDOM(false)
    setShouldOpenRaBillDetailsList(false)
  }

  const handleChange = (event, source) => {
    clearDatas()
    if (event.target.name === "project_id") {
      const filteredArray = projectLocationList.filter(project => {
        return project.project_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_location = filteredArray[0].project_location
      state.variance = filteredArray[0].variance

      state.initial_contract_amount = filteredArray[0].initial_contract_amount
      state.excess_initial_contract = filteredArray[0].excess_initial_contract
      state.amount = filteredArray[0].amount

      setSelectedProject(filteredArray[0])
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }

  const handleFormSubmit = () => {
    clearDatas()
    getAllRunningAccountBillList(state?.project_location_id)
  }

  const showLoader = () => {
    setLoading(true)
  }

  const hideLoader = () => {
    setLoading(false)
  }

  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }


  const getAllRunningAccountBillList = (project_location_id) => {
    setShouldOpenRaBillDetailsList(false)
    //  dispatch(getAllRunningAccountBillByProjectLocation(project_location_id, 'RECOMMENDED', 'RECOMMEND', showLoader, hideLoader, showToast, handleRABillSuccess))
    dispatch(getRunningAccountBillByProjectLocationId(project_location_id, ProjectStatus.RECOMMENDED, showLoader, hideLoader, showToast, handleRABillSuccess))
  }

  const handleRABillSuccess = () => {
    setShowRABillList(true)
  }

  function calculateSubTotalContractAmount() {
    if (raBillDetailsList?.length === 0) {
      return 0
    }
    const newRABillDetailsList = [...raBillDetailsList]
    const totalCost = newRABillDetailsList.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue?.contract_amount, 0);
    return getTwoDigitDecimal(totalCost)
  }

  function calculateSubTotalDeviationAmount() {
    if (raBillDetailsList?.length === 0) {
      return 0
    }
    const newRABillDetailsList = [...raBillDetailsList]
    const totalCost = newRABillDetailsList.reduce(
      (previousValue, currentValue, index) => previousValue + currentValue?.deviation_amount, 0);
    return getTwoDigitDecimal(totalCost)
  }

  function calculateTotalAmount() {

    var total_contract_amount = calculateSubTotalContractAmount()
    var total_deviation_amount = calculateSubTotalDeviationAmount()
    var total_amendment_amount = raBill?.total_amendment_amount ?? 0
    var total_recovery_amount = raBill?.total_recovery_amount ?? 0

    var sumOfAll = parseFloat(total_contract_amount) + parseFloat(total_deviation_amount) + parseFloat(total_amendment_amount)
    var totalAmount = sumOfAll - parseFloat(total_recovery_amount)
    return getTwoDigitDecimal(totalAmount ?? 0)

  }


  function formatStartDate(date) {
    const customDate = new Date(date)
    return moment(customDate).format('DD-MM-YYYY');
  }

  const handleNewRABillDialogClose = () => {
    setShouldOpenNewRABillDialog(false)
  }

  const handleNewRABillDialogSuccess = () => {
    setShouldOpenNewRABillDialog(false)
  }

  const handleRABillAmendmentDialogClose = () => {

  }

  const handleRABillAmendmentDialogSuccess = () => {
    // setShouldOpenRABillAmendementDialog(false)
    getAllRunningAccountBillList(state?.project_location_id)
  }

  const handleRABillRecoveryDialogClose = () => {
    setShouldOpenRABillRecoveryDialog(false)
  }

  const handleRABillRecoveryDialogSuccess = () => {
    setShouldOpenRABillRecoveryDialog(false)
    getAlRunningAccountBillDetailsListByParams(raBill.runningaccountbill_id, state.project_location_id)
  }

  const onClickCancelButton = () => {
    setShouldOpenRaBillDetailsList(false)
  }

  useEffect(() => {
    if (shouldShowDOM === true) {
      goToViolation("manage_dom")
    }
  }, [shouldShowDOM]);

  const onClickCloseDom = () => {
    setShouldShowDOM(false)
  }

  const deleteDomItem = (index) => {
    const newDomArray = [...domList]
    const filteredArray = newDomArray?.filter((item, itemIndex) => {
      return itemIndex !== index
    });
    setDomList(filteredArray)
  }

  const handleDOMDialogClose = () => {
    setShouldOpenDOMDeleteDialog(false)
    setShouldOpenConfirmation(false)
    setStatusType("APPROVE")
    setRaItemId()
  }

  const handleDOMConfirmationResponse = () => {
    deleteBoqDOM(domDetailsData?.id, domDetailsData?.index)
  }
  const handleApprove = () => {
    setLoading(true)
    if (raItemId) {
      console.log("ra_item_id:", raItemId, "status:", statusType)
      approveNotApproveRateAnalysisForRABill({ id: raItemId, status: statusType }).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
  }

  const deleteBoqDOM = (id, index) => {
    setLoading(true)
    deleteRunningAccountBillDetailsDOM(id).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        deleteDomItem(index)
        setShouldOpenDOMDeleteDialog(false)
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);

      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const handleClickManageDom = (raBill) => {
    setRaBillDetails(raBill)
    getRABillDoMListByParams(raBill.runningaccountbill_details_id)
  }

  const getRABillDoMListByParams = (params) => {
    setLoading(true)
    getRABillDoMList(params).then((res) => {
      setLoading(false)
      if (res?.status < 300) {
        setShouldShowDOM(false)
        setShouldShowDOM(true)
        setDomList(res?.data)
        calculateSubTotalQuantity(res?.data)
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  }

  const calculateSubTotalQuantity = (newDomArray) => {
    var amount = 0
    newDomArray.forEach((dom, index) => {
      if (dom?.item_sign === "-") {
        amount += -(dom?.quantity)
      } else {
        amount += dom?.quantity
      }
    });
    setSubTotalDomQuantity(getTwoDigitDecimal(amount))
  }

  const handleRABillVerificationDialogClose = (raBill) => {
    setShouldOpenRABillVerificationDialog(false)
  }

  const handleRABillVerificationDialogSuccess = (raBill) => {
    handleFormSubmit()
    setShouldOpenRABillVerificationDialog(false)
  }

  const handleRABillRateSuccessDialog = () => {
    setShouldOpenDetailsDialog(false)
    getAlRunningAccountBillDetailsListByParams(raBill.runningaccountbill_id)
  }

  const handleRABillRateCloseDialog = () => {
    setShouldOpenDetailsDialog(false)
  }

  useEffect(() => {
    console.log("Details:   ", raBillDetailsList)
  }, [raBillDetailsList])


  return (
    <Container>
      <div className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: "RA Bill", path: "#" }, { name: "Bill Recommendation" }]} />
      </div>
      <SimpleCard>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid container spacing={3} sx={{ marginLeft: 0.5 }}>

            <Grid item sm={4} xs={12}>
              <DecoratedTextField
                select
                size="small"
                fullWidth
                sx={{ mb: 3 }}
                label="Project ID"
                id="project_id"
                variant="outlined"
                name="project_id"
                value={state?.project_id}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["Project ID is required"]}
              >
                {projectLocationList?.map((item) => (
                  <MenuItem value={item?.project_id} key={item?.project_location_id}>
                    {item?.project_id}
                  </MenuItem>
                ))}
              </DecoratedTextField>
            </Grid>

            <Grid item sm={5} xs={12}>

              <DecoratedTextField
                size="small"
                disabled
                fullWidth
                sx={{ mb: 3 }}
                value={state?.project_name}
                label="Project Name"
                id="project_name"
                variant="outlined"
                name="project_name"
              >
              </DecoratedTextField>

            </Grid>
            <Grid item sm={2} xs={12}>
              <Box component="span" display="flex" justifyContent="flex-start" >
                <Button size="small" color="success" variant="contained" type="submit" >View</Button>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>
      </SimpleCard>
      <br />

      {showRABillList && (
        <div className="dv_ra_bill_submission">
          <ValidatorForm>
            <MyCard sx={{ width: "100%", overflow: "auto" }} elevation={6} >
              <ThemeProvider theme={theme}>
                <MUIDataTable
                  sx={{
                    backgroundColor: "yellow",
                    borderBottom: "2px solid black",
                    "& th": {
                      fontSize: "1.25rem",
                      color: "rgba(96, 96, 96)"
                    }
                  }}
                  data={raBillList}
                  columns={columns}
                  title={""}
                  options={{
                    fixedHeader: true,
                    responsive: 'scroll',
                    selectableRows: "none", // set checkbox for each row
                    filterType: "textField",
                    search: false, // set search option
                    filter: false, // set data filter option
                    download: false, // set download option
                    print: false, // set print option
                    pagination: true, //set pagination option
                    viewColumns: false,

                  }}
                />
              </ThemeProvider>
            </MyCard>
          </ValidatorForm>
        </div>
      )}
      <br />

      {shouldOpenRaBillDetailsList && (
        <Card sx={{ width: "100%", overflow: "auto" }} elevation={6}>
          <H3 style={{ marginLeft: 10, marginBottom: 10, marginTop: 15 }}><b>RA Bill Details</b></H3>
          <Divider sx={{ mb: 3 }} />
          <H4 style={{ marginLeft: 10, marginBottom: 10, marginTop: 15 }}>RA Bill No: {raBill?.rabill_no}</H4>

          <Divider></Divider>
          <div style={{ overflowX: "auto" }}>
            <ThemeProvider theme={theme}>
              <MUIDataTable
                sx={{
                  backgroundColor: "yellow",
                  borderBottom: "2px solid black",
                  "& th": {
                    fontSize: "1.25rem",
                    color: "rgba(96, 96, 96)"
                  }
                }}
                data={raBillDetailsList}
                columns={raBillDetailsColumns}
                title={""}
                options={{
                  fixedHeader: true,
                  responsive: 'scroll',
                  selectableRows: "none", // set checkbox for each row
                  filterType: "textField",
                  pagination: false, //set pagination option
                  rowsPerPageOptions: [10, 15, 20, 25, 50, 100],
                  search: false, // set search option
                  filter: false, // set data filter option
                  download: false, // set download option
                  print: false, // set print option
                  viewColumns: false,
                  setRowProps: (row, dataIndex) => {
                    // Access row data using the dataIndex to get the correct row object
                    const currentRow = raBillDetailsList[dataIndex];

                    // Highlight row if deviation_quantity is greater than 0
                    if (currentRow.deviation_quantity > 0) {
                      return {
                        style: {
                          backgroundColor: '#FFD580', // Highlight color
                        },
                      };
                    }
                    return {};
                  },
                }}
              />
            </ThemeProvider>

          </div>
          <Divider sx={{ mb: 3 }} />
          <div>
            <MyTable>
              <div style={{ pageBreakAfter: 'auto' }}></div>
              <TableHead style={{ backgroundColor: '#efefef' }}>
                <TableRow>
                  <TableCell colSpan={"3"}></TableCell>
                  <TableCell rows="4" align="left">Contract Amount (Nu)</TableCell>
                  <TableCell colSpan={"1"} sx={{ pr: 2 }} align="right">{currencyFormat(calculateSubTotalContractAmount())}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={"3"}></TableCell>
                  <TableCell rows="4" align="left">Deviated Amount (Nu)</TableCell>
                  <TableCell colSpan={"1"} sx={{ pr: 2 }} align="right">{currencyFormat(calculateSubTotalDeviationAmount())}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={"3"}></TableCell>
                  <TableCell rows="4" align="left">Amendment Amount (Nu)</TableCell>
                  <TableCell colSpan={"1"} sx={{ pr: 2 }} align="right">{currencyFormat(raBill?.total_amendment_amount)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={"3"}></TableCell>
                  <TableCell rows="4" align="left">Recovery Amount (Nu)</TableCell>
                  <TableCell colSpan={"1"} sx={{ pr: 2 }} align="right">{currencyFormat(raBill?.total_recovery_amount)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableHead style={{ backgroundColor: '#efefef' }}>
                <TableRow>
                  <TableCell colSpan={"3"}></TableCell>
                  <TableCell rows="4" align="left">Total Amount (Nu)</TableCell>
                  <TableCell colSpan={"1"} sx={{ pr: 2 }} align="right">{currencyFormat(calculateTotalAmount())}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            </MyTable>
            <br />
            <br />

            {/* <Box component="span" display="flex" justifyContent="flex-start" sx={{pl:2}} >
           { raBill?.total_amendment_amount!==undefined&&raBill?.total_amendment_amount!==null&&parseFloat(raBill?.total_amendment_amount)>0&&  <Button size="medium" color="success" variant="contained" onClick={() => handleShowRABillAmendmentDialog()}>Amendment</Button>}
           { raBill?.total_recovery_amount!==undefined&&raBill?.total_recovery_amount!==null&&parseFloat(raBill?.total_recovery_amount)>0&&   <Button size="medium" color="primary" variant="contained" onClick={() => handleShowRABillRecoveryDialog()}>Recovery</Button>}
            </Box> */}

            <Box component="span" display="flex" justifyContent="flex-end" sx={{ pr: 2 }} >
              <Button size="medium" color="error" variant="contained" onClick={() => onClickCancelButton()}>Close</Button>&nbsp;
            </Box>
          </div>
          <br />
        </Card>
      )}


      {
        shouldShowDOM && (

          <div className="manage_dom">
            <Card sx={{ width: "100%", marginTop: 5, overflow: "auto" }} elevation={6}>
              <Grid container spacing={5}>
                <Grid sx={{ marginLeft: 2 }} item md={12} sm={12} xs={12}>
                  <h2 >Manage DoM</h2>
                  <Divider sx={{ mb: 2 }} />
                  <h4>RA Bill No.: {raBill?.rabill_no}</h4>
                  <h4>Item Code: {raBillDetails?.item_code}</h4>
                  <p><b>Description: </b>{raBillDetails?.item_description}</p>
                  <p><b>Unit:</b> {raBillDetails?.unit_name}</p>
                </Grid>
              </Grid>
              <Box width="100%" overflow="auto">
                <MyTable>
                  <TableHead style={{ backgroundColor: "#87CEEB" }} >
                    <TableRow >
                      <TableCell align="center" width="8%">Sl No.</TableCell>
                      <TableCell align="center" width="8%">No.</TableCell>
                      <TableCell align="center" width="12%">Length</TableCell>
                      <TableCell align="center" width="10%">Breadth</TableCell>
                      <TableCell align="center" width="10%">Height</TableCell>
                      <TableCell align="center" width="10%">Deduction</TableCell>
                      <TableCell align="center" width="8%">Quantity</TableCell>
                      <TableCell align="center" width="8%">Remarks</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {domList?.map((dom, index) => (
                      <TableRow>
                        <TableCell sx={{ px: 0 }} align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ px: 0 }} align="center">
                          {dom?.item_no || ""}

                        </TableCell>

                        <TableCell sx={{ px: 0 }} align="center">
                          {dom?.length || ''}
                        </TableCell>

                        <TableCell sx={{ px: 0 }} align="center">
                          {dom?.breadth || ''}
                        </TableCell>

                        <TableCell sx={{ px: 0 }} align="center">
                          {dom?.height || ''}
                        </TableCell>

                        <TableCell sx={{ ml: 0 }} align="center">
                          {dom?.item_sign ?? ''}
                        </TableCell>

                        <TableCell sx={{ ml: 0 }} align="center">
                          {dom?.quantity ?? ''}
                        </TableCell>

                        <TableCell sx={{ px: 0 }} align="center">
                          {dom?.remarks || ""}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </MyTable>
                <h4 style={{ margin: 15 }}>Sub Total Quantity: {subTotalDomQuantity}</h4>
                <Box component="span" m={1} display="flex" justifyContent="flex-end" >
                  <Button size="medium" color="error" variant="contained" onClick={() => onClickCloseDom()}>Close</Button>
                </Box>
              </Box>
            </Card>
          </div>

        )
      }

      {shouldOpenNewRABillDialog && (
        <NewRABillDialog
          title={dialogTitle}
          projectLocationList={projectLocationList}
          open={shouldOpenNewRABillDialog}
          handleClose={handleNewRABillDialogClose}
          handleSuccess={handleNewRABillDialogSuccess}
        />
      )}

      {
        shouldOpenDOMDeleteDialog && (
          <ConfirmationDialog
            text="Are you sure to delete?"
            open={shouldOpenDOMDeleteDialog}
            onConfirmDialogClose={handleDOMDialogClose}
            onYesClick={handleDOMConfirmationResponse}
          />
        )
      }
      {
        shouldOpenConfirmation && (
          <ConfirmationDialog
            text={`Are you sure to ${statusType === "APPROVE" ? "Approve" : "Reject"}`}
            open={shouldOpenConfirmation}
            onConfirmDialogClose={handleDOMDialogClose}
            onYesClick={handleApprove}
          />
        )
      }


      {shouldOpenRABillVerificationDialog && (
        <NewRABillVerificationDialog
          type={'Recommendation'}
          title={dialogTitle}
          project={selectedProject}
          raBill={raBill}
          open={shouldOpenRABillVerificationDialog}
          handleClose={handleRABillVerificationDialogClose}
          handleSuccess={handleRABillVerificationDialogSuccess}
        />
      )}

      {shouldOpenDetailsDialog && (
        <RABillRateDetailsDialog
          isUpdated={false}
          title={dialogTitle}
          // isEvaluate={false}
          raBillDetails={raBillDetails}
          rateAnalysisDetails={rateAnalysisDetails}
          raBillRateDetails={raBillRateDetails}
          open={shouldOpenDetailsDialog}
          handleClose={handleRABillRateCloseDialog}
          handleSuccess={handleRABillRateSuccessDialog}
        />
      )}


      {shouldOpenRABillAmendmentDialog && (
        <RABillAmendmentDialog
          isUpdated={false}
          raBill={raBill}
          project={state}
          parentState={state}
          open={shouldOpenRABillAmendmentDialog}
          handleClose={handleRABillAmendmentDialogClose}
          handleSuccess={handleRABillAmendmentDialogSuccess}
        />
      )}


      {shouldOpenRABillRecoveryDialog && (
        <RABillRecoveryDialog
          raBill={raBill}
          project={state}
          open={shouldOpenRABillRecoveryDialog}
          handleClose={handleRABillRecoveryDialogClose}
          handleSuccess={handleRABillRecoveryDialogSuccess}
        />
      )}


      {loading && (
        <Loader
          show={loading}
        />
      )}
    </Container>
  );
};

export default RABillRecommended;


