import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getAdvancePaymentRecoveryReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const AdvanceReportRecovery = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState({})
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const { reportsProjectLocationList, projectLevelStagePercentage, constructionLevelStagePercentage } = useSelector((state) => state.projectLocation);
  // const {userList}=useSelector((state)=>state.userList)
  const [paymentTableData, setPaymentTableData] = useState([])
  const [recoveryList, setRecoveryList] = useState([])
  const [subTotalOfRecovery, setSubTotalOfRecovery] = useState(0)
  const [balance, setBalance] = useState()
    ;
  const [state, setState] = useState({
    project_location_id: '',
  })

  useEffect(() => {
    setBalance(parseFloat(totalAmount - subTotalOfRecovery).toFixed(2))
  }, [totalAmount, subTotalOfRecovery])
  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id
      setPaymentTableData([])
      setRecoveryList()
      setTotalAmount(0)
      setSubTotalOfRecovery(0)
      setProjectDetails({})
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {
    setLoading(true)
    setPaymentTableData([])
    getAdvancePaymentRecoveryReport({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)

        if (res?.status < 300) {
          console.log(res?.data)
          if (res?.data?.obj && Object.keys(res?.data?.obj)?.length > 0) {
            if (res?.data?.obj?.item && res?.data?.obj?.item?.payment_list && res?.data?.obj?.item?.payment_list?.length > 0) {
              const dataList = res?.data?.obj?.item?.payment_list?.map((elem, index) => {
                return {
                  slNo: index + 1,
                  raBillNo: elem?.advance_no,
                  advanceDate: moment(elem?.advance_date).format("DD-MM-YYYY"),
                  category: elem?.category,
                  amount: parseFloat(elem?.approved_amount).toFixed(2)
                }
              })
              console.log("data list: ", dataList)
              const SumOfAmount = dataList.reduce((acc, item) => acc + parseFloat(item?.amount), 0);
              console.log(parseFloat(SumOfAmount).toFixed(2))
              setPaymentTableData([dataList])
              setTotalAmount(parseFloat(SumOfAmount).toFixed(2))

            }

            if (res?.data?.obj?.item && res?.data?.obj?.item?.recovery_list && res?.data?.obj?.item?.recovery_list?.length > 0) {
              const dataList = res?.data?.obj?.item?.recovery_list?.map((elem, index) => {
                return {
                  slNo: index + 1,
                  raBillNo: elem?.rabill_no,
                  advanceDate: moment(elem?.rabill_date).format("DD-MM-YYYY"),
                  category: elem?.category,
                  amount: parseFloat(elem?.approved_amount).toFixed(2)
                }
              })
              console.log("data list: ", dataList)
              const SumOfAmount = dataList.reduce((acc, item) => acc + parseFloat(item.amount), 0);
              console.log(parseFloat(SumOfAmount).toFixed(2))
              setRecoveryList(dataList)
              setSubTotalOfRecovery(parseFloat(SumOfAmount).toFixed(2))

            }

            if (res?.data?.obj?.item) {
              setProjectDetails({
                company: res?.data?.obj?.item?.company,
                tenderId: res?.data?.obj?.item?.tender_id,
                project_name: res?.data?.obj?.item?.project_name,
                dzongkhag_name: res?.data?.obj?.item?.dzongkhagName,
                project_id: res?.data?.obj?.item?.project_id,

                header: res?.data?.obj?.header_logo,
                footer: res?.data?.obj?.footer_logo

              })
            }
            if ((res?.data?.obj?.item && res?.data?.obj?.item?.payment_list && res?.data?.obj?.item?.payment_list?.length === 0) || (res?.data?.obj?.item && res?.data?.obj?.item?.recovery_list && res?.data?.obj?.item?.recovery_list?.length === 0)) {
              if ((res?.data?.obj?.item && res?.data?.obj?.item?.payment_list && res?.data?.obj?.item?.payment_list?.length === 0) && (res?.data?.obj?.item && res?.data?.obj?.item?.recovery_list && res?.data?.obj?.item?.recovery_list?.length === 0)) {
                const key = enqueueSnackbar("No advance payment/recovery data found", { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }

              // else if (res?.data?.obj?.item && res?.data?.obj?.item?.payment_list && res?.data?.obj?.item?.payment_list?.length === 0) {
              //   const key = enqueueSnackbar("No payment data found", { variant: 'info' });
              //   SnackBarProperty.info(key, closeSnackbar);
              // }
              // else if (res?.data?.obj?.item && res?.data?.obj?.item?.recovery_list && res?.data?.obj?.item?.recovery_list?.length === 0) {
              //   const key = enqueueSnackbar("No recovery data found", { variant: 'info' });
              //   SnackBarProperty.info(key, closeSnackbar);
              // }

            }

          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }




        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(error => {
        setLoading(false)
        const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })


    function mergeByConstructionType(data) {
      console.log("data", data)
      const result = data.reduce((acc, curr) => {
        console.log("current", curr)
        const constructionType = curr.construcion_type;
        const totalAmount = curr.item_list.reduce((sum, item) => sum + item.amount, 0);

        // Create a new object with construction_type and total
        acc.push({ description: `Total for ${constructionType} (Nu)`, total: totalAmount });
        return acc;
      }, []);

      return result;
    }


  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
      // Calculate the width and height of the header image based on the PDF page width
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
        scaledHeight = pdfHeight;
        scaledWidth = pdfHeight * aspectRatio;
      }

      const textX = 15;
      const textY = scaledHeight + 10;
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);
      const textWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("ADVANCE / RECOVERY", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("ADVANCE / RECOVERY") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company, textX + textWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Id.:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id ? projectDetails?.project_id : ""}`, textX + textWidth + 5, textY + 20);
      const headerSectionHeight = scaledHeight + 35; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');

      const body = [[{ content: "Payment", colSpan: 5, styles: { fontStyle: "bold", fontSize: 8 } }]]
      paymentTableData[0]?.forEach((elem) => {
        body.push([
          { content: elem?.slNo, styles: { fontSize: 8 } },
          { content: elem?.raBillNo, styles: { fontSize: 8 } },
          { content: elem?.advanceDate, styles: { fontSize: 8 } },
          { content: elem?.category, styles: { fontSize: 8 } },
          { content: formatNumberWithCommasAndDecimals(elem?.amount), styles: { halign: "right", fontSize: 8 } }])
      })
      body.push([{ content: "Total Amount", colSpan: 4, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
      { recoveryList?.length > 0 && body.push([{ content: "Recovery", colSpan: 5, styles: { fontStyle: "bold", fontSize: 8 } }]) }
      recoveryList?.length > 0 && recoveryList?.map((elem) => {
        body.push([
          { content: elem?.slNo, styles: { fontSize: 8 } },
          { content: elem?.raBillNo, styles: { fontSize: 8 } },
          { content: elem?.advanceDate, styles: { fontSize: 8 } },
          { content: elem?.category, styles: { fontSize: 8 } },
          { content: elem?.amount, styles: { halign: "right", fontSize: 8 } }])
      })
      { recoveryList?.length > 0 && body.push([{ content: "Total Amount", colSpan: 4, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: subTotalOfRecovery, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }]) }
      body.push([{ content: "Balance", colSpan: 4, styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }, { content: formatNumberWithCommasAndDecimals(balance), styles: { halign: "right", fontStyle: "bold", fontSize: 8 } }])
      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "SL NO.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "RA Bill No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Category", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          5: { halign: "right" }, // "RATE" column width

        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }

      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Advance_Payment_Recovery_Report.pdf');

    });
  };



  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Advance Payment/Recovery</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {
          paymentTableData && paymentTableData[0]?.length > 0 &&
          <Grid container>
            <Grid xs={12} md={6}>
              {checkValidation(projectDetails?.header) === false && <FormLabel sx={{ color: "#ff9800" }} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  disabled={projectDetails?.header ? false : true}
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={projectDetails?.header ? false : true}
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}

        {/* table */}
        {
          paymentTableData && paymentTableData[0]?.length > 0 &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>

                <Grid container >
                  <Grid item xs={12} align='CENTER' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>ADVANCE/RECOVERY</b></FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                  </Grid>


                </Grid>
              </div>

              <div id="tableData">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell>Sl No.</TableCell>
                        <TableCell>Advance No. / RA Bill No.</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell align="right">Amount (Nu)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={5}><b>Payment</b></TableCell>
                      </TableRow>
                      {paymentTableData[0]?.length > 0 && paymentTableData[0]?.map((elem, index) => {
                        return (
                          <TableRow>
                            <TableCell >{index + 1}</TableCell>
                            <TableCell >{elem?.raBillNo}</TableCell>
                            <TableCell >{elem?.advanceDate}</TableCell>
                            <TableCell >{elem?.category}</TableCell>
                            <TableCell align="right" >{formatNumberWithCommasAndDecimals(elem?.amount)}</TableCell>
                          </TableRow>
                        )
                      }
                      )
                      }
                      <TableRow>
                        <TableCell colSpan={4} sx={{ fontWeight: 600 }} align="right"><b>Total</b></TableCell>
                        <TableCell align="right" sx={{ fontWeight: 600 }}>
                          <b>{formatNumberWithCommasAndDecimals(totalAmount)}</b>
                        </TableCell>
                      </TableRow>
                      {
                        recoveryList?.length > 0 &&
                        <> <TableRow>
                          <TableCell colSpan={5}><b>Recovery</b></TableCell>
                        </TableRow>
                          {recoveryList?.length > 0 && recoveryList?.map((elem, index) => {
                            return (
                              <TableRow>
                                <TableCell >{index + 1}</TableCell>
                                <TableCell >{elem?.raBillNo}</TableCell>
                                <TableCell >{elem?.advanceDate}</TableCell>
                                <TableCell >{elem?.category}</TableCell>
                                <TableCell align="right" >{formatNumberWithCommasAndDecimals(elem?.amount)}</TableCell>
                              </TableRow>
                            )
                          }
                          )
                          }
                        </>}
                      {recoveryList?.length > 0 &&
                        <TableRow>
                          <TableCell colSpan={4} sx={{ fontWeight: 600 }} align="right"><b>Total</b></TableCell>
                          <TableCell align="right" sx={{ fontWeight: 600 }}>
                            <b>{formatNumberWithCommasAndDecimals(subTotalOfRecovery)}</b>
                          </TableCell>
                        </TableRow>}

                    </TableBody>
                  </Table>

                </TableContainer>
                <Grid container sx={{ mt: 2 }}>
                  <Grid items>
                    <FormLabel sx={{ fontSize: "20px" }}><b>Balance:</b>{formatNumberWithCommasAndDecimals(balance)}</FormLabel>
                  </Grid>

                </Grid>
              </div>
            </div>
          </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}