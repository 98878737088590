import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getLiquidatedDamagesRecoveryReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const LiquidatedDamageRecoveryReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState({})
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  // const {userList}=useSelector((state)=>state.userList)
  const [tableData, setTableData] = useState()
  const [subTotalDelay, setSubTotalDelay] = useState()
  const [subTotalPenalty, setSubTotalPenalty] = useState()

  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id
      setTableData()
      setProjectDetails({})
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {


    if (state?.project_location_id === "") {
      const key = enqueueSnackbar("Please select project id", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else {
      setTableData([])
      setLoading(true)
      getLiquidatedDamagesRecoveryReport({ project_location_id: state?.project_location_id })
        .then((res) => {
          console.log(res?.data)
          setLoading(false)

          if (res?.status < 300) {
            if(res?.data?.success){

            
            if (res?.data?.obj) {
              if (res?.data?.obj?.item && res?.data?.obj?.item?.item_list && res?.data?.obj?.item?.item_list?.length > 0) {
                const dataList = res?.data?.obj?.item?.item_list?.map((elem, index) => {
                  return {
                    slNo: index + 1,
                    date: moment(elem?.bill_submission_date).format("DD-MM-YYYY"),
                    raBill_no: elem?.rabill_no,
                    duration_of_delay: parseFloat(elem?.total_delay_days).toFixed(2),
                    delay_penalty_amount: parseFloat(elem?.penalty_amount).toFixed(2)
                  }
                })
                console.log("data list: ", dataList)
                const SumOfDays = dataList.reduce((acc, item) => acc + item.duration_of_delay, 0);
                const SumOfPenalty = dataList.reduce((acc, item) => acc + item.delay_penalty_amount, 0);
                setSubTotalDelay(SumOfDays)
                setSubTotalPenalty(parseFloat(SumOfPenalty).toFixed(2))
                setTableData(dataList)

              }

              if (res?.data?.obj?.item) {
                setProjectDetails({
                  company: res?.data?.obj?.item?.company,
                  contract_amount: parseFloat(res?.data?.obj?.item?.initial_contract_amount).toFixed(2),
                  project_name: res?.data?.obj?.item?.project_name,
                  project_id: res?.data?.obj?.item?.project_id,
                  header: res?.data?.obj?.header_logo,
                  footer: res?.data?.obj?.footer_logo

                })
              }
            }
          }
          else{
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }
          }
          else {
              const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(error => {
          setLoading(false)
           const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

        })
    }



  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const footer = `${ServiceUrl?.showImageUrl}${projectDetails?.footer}`
     
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // A4 dimensions in mm
      const pdfWidth = 210;
      const pdfHeight = 297;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit within A4 while maintaining aspect ratio
      let scaledWidth = pdfWidth;
      let scaledHeight = pdfWidth / aspectRatio;

      if (scaledHeight > pdfHeight) {
          scaledHeight = pdfHeight;
          scaledWidth = pdfHeight * aspectRatio;
      }
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      const textX = 15;
      const textY = scaledHeight + 10; // Adjust the value for vertical separation

      // Add the text content below the header image
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("LIQUIDATED DAMAGES RECOVERY", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("LIQUIDATED DAMAGES RECOVERY") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      
      const textWidth = doc.getStringUnitWidth("Contract Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 10);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + textWidth -5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company, textX + textWidth -5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project Id.:", textX, textY + 20);
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id ? projectDetails?.project_id : ""}`, textX + textWidth -5, textY + 20);
      doc.setFont('helvetica', 'bold');
      doc.text("Contract Amount:", textX, textY + 25);
      doc.setFont('helvetica', 'normal');
      doc.text(`${formatNumberWithCommasAndDecimals(projectDetails?.contract_amount)}`, textX + textWidth -5, textY + 25);
      doc.setFont('helvetica', 'normal');


      const headerSectionHeight = scaledHeight+40; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 5;
      doc.setFont('helvetica', 'normal');

      const body = []


      tableData?.forEach((elem) => {
        body.push([
          {content:elem?.slNo,styles:{fontSize:8}},
          {content:elem?.date, styles:{fontSize:8}},
          {content:elem?.raBill_no, styles:{fontSize:8}},
          { content: elem?.duration_of_delay, styles: { halign: "right",fontSize:8 } },
          { content: formatNumberWithCommasAndDecimals(elem?.delay_penalty_amount), styles: { halign: "right",fontSize:8 } }
        ])
      })
      body.push([{ content: "Sub-Total", colSpan: 3, styles: { halign: "right", fontStyle: "bold",fontSize:8 } }, { content: subTotalDelay, styles: { fontStyle: "bold", halign: "right",fontSize:8 } }, { content: formatNumberWithCommasAndDecimals(subTotalPenalty), styles: { fontStyle: "bold", halign: "right",fontSize:8 } }])

      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Date", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "RA Bill No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
          { content: "Duration Of Delay (Days)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
          { content: "Delay Penalty Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } }
        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          4: { halign: "right" }
        },
      });

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(footer, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }

      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Liquidated_Damages_Recovery_Report.pdf');

    });
  };



  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>LD Recovery</b></Grid>
            </Grid>
            <Grid container spacing={3} >

              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {
          tableData && tableData?.length > 0 &&
          <Grid container>
             <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header)===false&&<FormLabel sx={{color:"#ff9800"}} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="span" display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  sx={{ width: "130px", marginLeft: 4 }}
                  variant="contained"
                  color="warning"
                  disabled={projectDetails?.header?false:true}
                  startIcon={<CloudDownload />}
                  onClick={() => { handleReportDownload(false) }}
                >
                  Download
                </Button>
                <Button
                  size="small"
                  sx={{ width: "100px", marginLeft: 2 }}
                  variant="contained"
                  color="primary"
                  disabled={projectDetails?.header?false:true}
                  startIcon={<Print />}
                  onClick={() => { handleReportDownload(true) }}
                >
                  Print
                </Button>
              </Box>
            </Grid>

          </Grid>}

        {/* table */}
        {
          tableData && tableData?.length > 0 &&
          <Card sx={{ padding: 3 }}>

            <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "30px" }}>
              <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                  <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

                </div>

                <Grid container >
                  <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                    <FormLabel sx={{ fontSize: 20 }}><b>LIQUIDATED DAMAGES RECOVERY</b></FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{projectDetails?.project_id}</FormLabel>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: 18 }}><b>Contract Amount: </b>{formatNumberWithCommasAndDecimals(projectDetails?.contract_amount)}</FormLabel>
                  </Grid>


                </Grid>
              </div>

              <div id="tableData">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#87CEEB' }}>
                      <TableRow>
                        <TableCell>SL No.</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>RA Bill No.</TableCell>
                        <TableCell align="right">Duration of Delay (Days)</TableCell>
                        <TableCell align="right">Delay Penalty Amount (Nu)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {tableData?.length > 0 && tableData?.map((elem, index) => {
                        return (
                          <TableRow>
                            <TableCell >{elem?.slNo}</TableCell>
                            <TableCell >{elem?.date}</TableCell>
                            <TableCell >{elem?.raBill_no}</TableCell>
                            <TableCell align="right">{elem?.duration_of_delay}</TableCell>
                            <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.delay_penalty_amount)}</TableCell>
                          </TableRow>
                        )
                      }
                      )
                      }
                      <TableRow>
                        <TableCell colSpan={3} align="right"><b>Total (Nu)</b></TableCell>
                        <TableCell align="right" >
                          <b>{subTotalDelay}</b>
                        </TableCell>
                        <TableCell align="right" >
                          <b>{formatNumberWithCommasAndDecimals(subTotalPenalty)}</b>
                        </TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                </TableContainer>

              </div>

            </div>
          </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}