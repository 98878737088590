import Loadable from 'app/components/Loadable';
import { element } from 'prop-types';
import { lazy } from 'react';

const BuiltUpItem = Loadable(lazy(() => import('./BuiltUpItem')));
const NewBuiltUpItem = Loadable(lazy(() => import('./BuiltUpItemLMC')));
const NewBuiltUpItemRate = Loadable(lazy(() => import('./BuiltUpItemRate')));

const builtUpItemRoutes = [
  {
    path: '/builtUpItem',
    element: <BuiltUpItem/>
  },
  {
    path: '/newBuiltUpItem',
    element: <NewBuiltUpItem/>
  },
  {
    path: '/newBuiltUpItemRate',
    element: <NewBuiltUpItemRate/>
  }


];

export default builtUpItemRoutes;
