import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const MusterRollPaymentRequest = Loadable(lazy(() => import('./MusterRollPaymentRequest')));
const MusterRollPaymentVerification = Loadable(lazy(() => import('./MusterRollPaymentVerification')));
const MusterRollPaymentApproval = Loadable(lazy(() => import('./MusterRollPaymentApproval')));

const musterRollPaymentRoutes = [
  {
    path: '/MRPaymentRequest',
    element: <MusterRollPaymentRequest/>,
  },
  {
    path: '/MRPaymentVerification',
    element: <MusterRollPaymentVerification/>,
  },
  {
    path: '/MRPaymentApproval',
    element: <MusterRollPaymentApproval/>,
  }
];

export default musterRollPaymentRoutes;