import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CloudDownload, ErrorOutline, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, TextField, styled } from "@mui/material";
import { Loader } from "app/components";
import { getAllProjectLocationListForReports } from "app/redux/actions/ProjectLocationActions";
import { getStatementOfDeviationReport } from "app/redux/actions/ReportsAction";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from 'app/utils/Constants';
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));




export const StatementOfDeviationReport = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const { reportsProjectLocationList } = useSelector((state) => state.projectLocation);
  const [grandTotal, setGrandTotal] = useState(0)
  const [tableData, setTableData] = useState()
    ;
  const [state, setState] = useState({
    project_location_id: '',
  })


  useEffect(() => {
    dispatch(getAllProjectLocationListForReports(showLoader, hideLoader, showToast))
  }, []);
  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (message) => {
    const key = enqueueSnackbar(message ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
  }

  const hideLoader = () => {
    setLoading(false)
  }
  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      setProjectDetails({})
      const filteredArray = reportsProjectLocationList.filter(project => {
        return project.project_location_id === event.target.value
      });
      state.project_name = filteredArray[0].project_name
      state.project_location_id = filteredArray[0].project_location_id
      state.project_id = filteredArray[0].project_id

      setState({ ...state, [event.target.name]: event.target.value });

    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }


  const handleFormSubmit = () => {
    setLoading(true)
    // setTableData([])
    getStatementOfDeviationReport({ project_location_id: state?.project_location_id })
      .then((res) => {
        setLoading(false)

        if (res?.status < 300) {
  if(res?.data?.success){
          //   if (res?.data?.obj && res?.data?.obj?.item && res?.data?.obj?.item?.item_list?.length > 0) {

          //     setTableData(res?.data?.obj?.item?.item_list)
          //     const total = res?.data?.obj?.item?.item_list.reduce((accumulator, item) => accumulator+item?.tds_amount, 0);
          //     setTotalAmount(parseFloat(total).toFixed(2))
          //   }

          if (res?.data?.obj && res?.data?.obj?.item && Object.keys(res?.data?.obj?.item?.deviation_report)?.length > 0) {
            const table = []
            Object.keys(res?.data?.obj?.item?.deviation_report).forEach(key => {
              let obj = {}
              const constructionName = key
              const itemList = res?.data?.obj?.item?.deviation_report[key];
              if (res?.data?.obj?.item?.deviation_report[key]?.length > 0) {
                const subTotalDeviatedRate = res?.data?.obj?.item?.deviation_report[key]?.reduce((sum, item) => sum + parseFloat(item.deviated_amount), 0);
                const subTotalDeviatedAmount = res?.data?.obj?.item?.deviation_report[key]?.reduce((sum, item) => sum + parseFloat(item.amount), 0);
                obj = {
                  construction_name: constructionName,
                  item_list: itemList,
                  sub_total_deviated_rate: parseFloat(subTotalDeviatedRate ? subTotalDeviatedRate : 0).toFixed(2),
                  sub_total_deviated_amount: parseFloat(subTotalDeviatedAmount ? subTotalDeviatedAmount : 0).toFixed(2)
                }
              }
              // console.log(`Key: ${key}, Value: ${value}`);
              table.push(obj)
            });
            console.log("table: ", table)
            const grand_total_deviated_rate = table.reduce((sum, item) => sum + parseFloat(item?.sub_total_deviated_rate), 0)
            const grand_total_deviated_amount = table.reduce((sum, item) => sum + parseFloat(item?.sub_total_deviated_amount), 0)
            const obj = {
              grand_deviated_rate: parseFloat(grand_total_deviated_rate ? grand_total_deviated_rate : 0).toFixed(2),
              grand_deviated_amount: parseFloat(grand_total_deviated_amount ? grand_total_deviated_amount : 0).toFixed(2)
            }
            setGrandTotal(obj)
            setTableData(table)
          }
          if (res?.data?.obj?.item) {
            setProjectDetails({
              ...projectDetails,
              company: res?.data?.obj?.item?.company,
              project_name: res?.data?.obj?.item?.project_name,
              project_id: res?.data?.obj?.item?.project_id,
              header: res?.data?.obj?.header_logo,
              footer: res?.data?.obj?.footer_logo

            })
          }
        }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
      SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        console.log(err)
         const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
  SnackBarProperty.error(key, closeSnackbar);

      })


  }

  const handleReportDownload = (isPrint) => {
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const pdfWidth = 297; // Landscape dimensions
    const pdfHeight = 210;
    const doc = new jsPDF();
    const contentHeight = 150;
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    html2canvas(headerImage, {
      useCORS: true, // Add this line if the image is from a different domain
      allowTaint: true, // Add this line if the image is from a different domain
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate the width and height of the header image based on the PDF page width
      const pdfWidth = doc.internal.pageSize.getWidth() - 50;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      const headerHeight = pdfHeight;
      const tableY = headerHeight + 15;
      // Insert the header image into the PDF
      const topBorderPadding = 10; // Adjust the padding value as needed
      doc.setDrawColor(255, 255, 255); // Black color for border
      doc.setLineWidth(topBorderPadding); // Border width (padding)

      // Draw the top border (padding)
      doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

      // Insert the header image into the PDF
      doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight, 'FAST');
      const textX = 15;
      const textY = tableY + 5; // Adjust the value for vertical separation

      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(12); // Set font size
      doc.text("STATEMENT OF DEVIATION", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("STATEMENT OF DEVIATION") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), textY);
      doc.setFontSize(8); // Set font size
      doc.setFont('helvetica', 'bold'); // Set font to bold
      doc.setFontSize(8); // Set font size
      doc.text("Project Name:", textX, textY + 10);
      const firstTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.project_name, textX + firstTextWidth + 5, textY + 10);
      doc.setFont('helvetica', 'bold');
      doc.text("Client:", textX, textY + 15);
      const secondTextWidth = doc.getStringUnitWidth("Client:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.setFont('helvetica', 'normal');
      doc.text(projectDetails?.company, textX + secondTextWidth + 5, textY + 15);
      doc.setFont('helvetica', 'bold');
      doc.text("Project ID:", textX, textY + 20);
      const thirdTextWidth = doc.getStringUnitWidth("Project ID:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.setFont('helvetica', 'normal');
      doc.text(`${projectDetails?.project_id}`, textX + thirdTextWidth + 5, textY + 20);
      // doc.text(`Name Of The Work: ${projectDetails?.project_name}`, textX, textY);
      //doc.text(`Client: ${projectDetails?.company}`, textX, textY + 5);
      // doc.text(`eGP Tender Id: ${projectDetails?.contractNo}`, textX, textY + 10);
      //   doc.setFont('helvetica', 'bold');
      //   doc.text("Contract Amount:", textX, textY + 25);
      //   const fourthTextWidth = doc.getStringUnitWidth("Contract Amount:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(`${projectDetails?.contract_amount}`, textX + fourthTextWidth + 5, textY + 25);
      //doc.setFontSize(5); 
      const headerSectionHeight = headerHeight + pdfHeight; // Adjust the value as needed

      // Calculate the starting Y-coordinate for the table
      const tableStartY = headerSectionHeight + 15;

      doc.setFont('helvetica', 'normal'); // Reset font to normal
      const body = []
      body.push([
        { content: "", colSpan: 2, styles: { fontStyle: "bold" } },
        { content: "BOQ", styles: { fontStyle: "bold", valign: "middle", halign: "center" } },
        { content: "Executed(Cumulative)", styles: { fontStyle: "bold", valign: "middle", halign: "center" } },
        { content: "Deviated", styles: { fontStyle: "bold", valign: "middle", halign: "center" } },
        { content: "Deviated", styles: { fontStyle: "bold", valign: "middle", halign: "center" } },
        { content: "Deviated", styles: { fontStyle: "bold", valign: "middle", halign: "center" } }
      ])
      tableData?.length > 0 && tableData?.forEach((item) => {
        body.push([
          { content: item?.construction_name, colSpan: 7, styles: { fontStyle: "bold" } }

        ])
        body.push([
          { content: `R.A ${item?.item_list[0]?.rabill_no}`, colSpan: 7, styles: { fontStyle: "bold" } }

        ])
        item?.item_list?.forEach((elem) => {
          body.push([
            elem?.item_code,
            elem?.description,
            formatNumberWithCommasAndDecimals(parseFloat(elem?.boq ? elem?.boq : 0).toFixed(2)),
            formatNumberWithCommasAndDecimals(parseFloat(elem?.executed_quantity ? elem?.executed_quantity : 0).toFixed(2)),
            formatNumberWithCommasAndDecimals(parseFloat(elem?.deviated_quantity ? elem?.deviated_quantity : 0).toFixed(2)),
            { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.deviated_amount ? elem?.deviated_amount : 0).toFixed(2)), styles: { halign: "right" } },
            { content: formatNumberWithCommasAndDecimals(parseFloat(elem?.amount ? elem?.amount : 0).toFixed(2)), styles: { halign: "right" } }
          ])
        })
        body.push([
          { content: "Sub-Total", colSpan: 5, styles: { fontStyle: "bold", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(item?.sub_total_deviated_rate), styles: { fontStyle: "bold", halign: "right" } }, { content: item?.sub_total_deviated_amount, styles: { fontStyle: "bold", halign: "right" } }
        ])
      })
      body.push([
        { content: "Grand Total", colSpan: 5, styles: { fontStyle: "bold", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(grandTotal?.grand_deviated_rate), styles: { fontStyle: "bold", halign: "right" } }, { content: grandTotal?.grand_deviated_amount, styles: { fontStyle: "bold", halign: "right" } }
      ])

      doc.autoTable({
        startY: tableStartY,
        head: [[
          { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
          { content: "Description", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
          { content: "Quantity", colSpan: 3, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
          { content: "Rate(Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },
          { content: "Amount(Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2 } },

        ]],
        body: body, // Use the transformed data here
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255], // Remove background color from the table
          lineColor: [0, 0, 0], // Set border color for main table
          lineWidth: 0.1, // Set border width for main table
          textColor: [0, 0, 0], // Set text color to black
          cellPadding: 2, // Add padding to cells
        },
        columnStyles: {
          1: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
          3: { cellWidth: 20 }
        },

      });
      //   doc.setFontSize(8);
      //   doc.setFont('helvetica', 'bold');// Set font size
      //   doc.text(`Balance Contract Amount :`, textX, doc.autoTable.previous.finalY + 10);
      //   const balanceText = doc.getStringUnitWidth("Balance Contract Amount :") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(parseFloat(parseFloat(projectDetails?.contract_amount)-parseFloat(sums?.sum_payment_to_contractor)).toFixed(2), textX + balanceText + 5, doc.autoTable.previous.finalY + 10);
      //   doc.setFont('helvetica', 'bold');// Set font size
      //   doc.text(`Balance Advance Amount :`, textX, doc.autoTable.previous.finalY + 15);
      //   const balanceAdvanceText = doc.getStringUnitWidth("Balance Advance Amount :") * doc.internal.getFontSize() / doc.internal.scaleFactor;
      //   doc.setFont('helvetica', 'normal');
      //   doc.text(parseFloat(sums?.sum_total_advances-sums?.sum_advance_recovered).toFixed(2), textX + balanceAdvanceText + 5, doc.autoTable.previous.finalY + 15);

      // Function to add footer on each page
      function addFooter(pageNumber) {
        // const footerText = "Hello CMS User";
        // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
        const footerX = 0; // Starting X position for the footer
        const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = 10;
        // const availableWidth = pdfWidth - 2 * footerX;

        // doc.setTextColor(0, 0, 0); // Set text color to black
        // doc.setFontSize(10); // Set font size for the footer

        // // Calculate the width of the text and page numbers
        // const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        // const pageWidth = doc.getStringUnitWidth(pageString) * doc.internal.getFontSize() / doc.internal.scaleFactor;

        // // Calculate the space needed for equal spacing
        // const spaceWidth = (availableWidth - textWidth - pageWidth) / 2;

        // // Add footer text and page numbers in the same row, justified with space between them
        // doc.text(footerX, footerY, footerText);
        // doc.text(footerX + spaceWidth + textWidth, footerY, pageString);
        doc.addImage(`${ServiceUrl?.showImageUrl}${projectDetails?.footer ? projectDetails?.footer : 'moit_footer.jpg'}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
      }

      // Get the total number of pages
      const totalNumPages = doc.internal.getNumberOfPages();

      // Loop through each page to add the footer with page numbers
      for (let i = 1; i <= totalNumPages; i++) {
        doc.setPage(i);
        addFooter(i);
      }
      const scaleFactor = 1.0; // Adjust this value as needed
      const pdfBlob = doc.output('blob', { scale: scaleFactor });

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open a new window with the PDF URL

      if (isPrint) {
        const newWindow = window.open(pdfUrl, '_blank');

        // After the new window is opened, you can close the URL object
        URL.revokeObjectURL(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            setIsPrinting(false)
            setIsDownloading(false)
            // Wait for the window to load and then trigger the print dialog
            newWindow.print();
          };
        }
      }

      setIsPrinting(false)
      setIsDownloading(false)

      isPrint === false && doc.save('Statement_Of_Deviation_Report.pdf');

    });
  };










  return (
    <>
      <div style={{ height: 'auto', width: "100%", padding: '30px', display: "flex", flexDirection: "column", gap: "20px" }}>

        <Grid container>

          <Grid item xs={12}>
            <Button
              size="small"
              sx={{ width: "100px" }}
              variant="contained"
              color="error"
              onClick={() => { window.history.back() }}
            >
              <Icon color="primary" fontSize="small">
                skip_previous
              </Icon>
              Back
            </Button>
          </Grid>
        </Grid>
        <SimpleCard>
          <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
              <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Statement of Deviation</b></Grid>
            </Grid>
            <Grid container spacing={3} >
              {/* <Grid item xs={12}>
                <RadioGroup
                  row
                  name="reportType"
                  value={reportType}
                  onChange={(e) => { setReportType(e.target.value); setTableData([]) }}
                >&nbsp;&nbsp;
                  <FormControlLabel
                    label="Project BoQ Report"
                    value="report"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ mr: 3, height: 20 }}
                  />
                  <FormControlLabel
                    label="Project BoQ Report Summary"
                    value="summaryReport"
                    control={<Radio size="small" color="secondary" />}
                    sx={{ height: 20 }}
                  />
                </RadioGroup>

              </Grid> */}
              <Grid item sm={4} xs={12}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Project ID"
                  id="project_location_id"
                  variant="outlined"
                  name="project_location_id"
                  value={state?.project_location_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={["Project ID is required"]}
                >
                  {reportsProjectLocationList?.map((item) => (
                    <MenuItem value={item?.project_location_id} key={item?.project_id}>
                      {item?.project_id}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={4} xs={12}>


                <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

              </Grid>

            </Grid>

          </ValidatorForm>
        </SimpleCard>
        {tableData && tableData?.length > 0 && <Grid container>
          <Grid xs={12} md={6}>
            {checkValidation(projectDetails?.header)===false&&<FormLabel sx={{color:"#ff9800"}} color="warning">! Please upload header and footer for download/print</FormLabel>}
            </Grid>
          <Grid item xs={12} md={6}>
            <Box component="span" display="flex" justifyContent="flex-end">
              <Button
                size="small"
                sx={{ width: "130px", marginLeft: 4 }}
                variant="contained"
                color="warning"
                 disabled={projectDetails?.header?false:true}
                startIcon={<CloudDownload />}
                onClick={() => { handleReportDownload(false) }}
              >
                Download
              </Button>
              <Button
                size="small"
                sx={{ width: "100px", marginLeft: 2 }}
                variant="contained"
                color="primary"
                 disabled={projectDetails?.header?false:true}
                startIcon={<Print />}
                onClick={() => { handleReportDownload(true) }}
              >
                Print
              </Button>
            </Box>
          </Grid>

        </Grid>}
        {tableData && tableData?.length > 0 && <Card sx={{ padding: 3 }}>

          <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
            <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px", paddingLeft: "100px", paddingRight: "100px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
                <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${projectDetails?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

              </div>

              <Grid container >
                <Grid item xs={12} sx={{ marginTop: 2, textAlign: "center" }}>
                  <FormLabel sx={{ fontSize: 20 }}><b>STATEMENT OF DEVIATION</b></FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Client: </b>{projectDetails?.company}</FormLabel>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{projectDetails?.project_id}</FormLabel>
                </Grid>
                {/* <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormLabel sx={{ fontSize: 18 }}><b>Contract Amount : </b>{projectDetails?.contract_amount}</FormLabel>
                </Grid> */}


              </Grid>
            </div>

            <div id="tableData">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead style={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      <TableCell >Item Code</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell colSpan={3} align="center">Quantity</TableCell>
                      <TableCell align="center">Rate(Nu)</TableCell>
                      <TableCell align="center">Amount(Nu)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={0} >
                      <TableCell colSpan={2}></TableCell>
                      <TableCell ><b>BOQ</b></TableCell>
                      <TableCell><b>Executed(Cumulative)</b></TableCell>
                      <TableCell><b>Deviated</b></TableCell>
                      <TableCell><b>Deviated</b></TableCell>
                      <TableCell><b>Deviated</b></TableCell>
                    </TableRow>
                    {
                      tableData?.map((item, index) => (
                        <React.Fragment >

                          <TableRow key={index + 1} >
                            <TableCell colSpan={7}><b>{item?.construction_name}</b></TableCell>
                          </TableRow>
                          <TableRow key={index} >
                            <TableCell colSpan={7}><b>R.A {item?.item_list[0]?.rabill_no}</b></TableCell>
                          </TableRow>
                          {

                            item?.item_list?.map((elem, index) => {
                              return (
                                <TableRow key={index} >
                                  <TableCell >{elem?.item_code}</TableCell>
                                  <TableCell >{elem?.description}</TableCell>
                                  <TableCell >{formatNumberWithCommasAndDecimals(parseFloat(elem?.boq ? elem?.boq : 0).toFixed(2))}</TableCell>
                                  <TableCell >{formatNumberWithCommasAndDecimals(parseFloat(elem?.executed_quantity ? elem?.executed_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell >{formatNumberWithCommasAndDecimals(parseFloat(elem?.deviated_quantity ? elem?.deviated_quantity : 0).toFixed(2))}</TableCell>
                                  <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(elem?.deviated_amount ? elem?.deviated_amount : 0).toFixed(2))}</TableCell>
                                  <TableCell align="right">{formatNumberWithCommasAndDecimals(parseFloat(elem?.amount ? elem?.amount : 0).toFixed(2))}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                          <TableRow key={index + 2}>
                            <TableCell colSpan={5} align="right"><b>Sub-Total</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(item?.sub_total_deviated_rate)}</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(item?.sub_total_deviated_amount)}</b></TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    <TableRow >
                      <TableCell colSpan={5} align="right"><b>Grand Total</b></TableCell>
                      <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(grandTotal?.grand_deviated_rate)}</b></TableCell>
                      <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(grandTotal?.grand_deviated_amount)}</b></TableCell>
                    </TableRow>


                  </TableBody>
                </Table>

              </TableContainer>

            </div>
          </div>
        </Card>}

      </div>
      {loading && (
        <Loader
          show={loading}
        />
      )}
      {(isPrinting || isDownloading) && (
        <Loader
          show={isPrinting ? isPrinting : isDownloading}
          text="Processing...Please wait."
        />
      )}

    </>
  )
}