import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ReleaseOfRetentionSubmission = Loadable(lazy(() => import('./ReleaseOfRetentionSubmission')));
const ReleaseOfRetentionVerification = Loadable(lazy(() => import('./ReleaseOfRetentionVerification')));
const ReleaseOfRetentionRecommendation = Loadable(lazy(() => import('./ReleaseOfRetentionRecommendation')));
const ReleaseOfRetentionApproval = Loadable(lazy(() => import('./ReleaseOfRetentionApproval')));

const releaseOfRetentionRoutes = [
    {
        path: '/RetentionSubmission',
        element: <ReleaseOfRetentionSubmission />,
    },
    {
        path: '/RetentionVerification',
        element: <ReleaseOfRetentionVerification />,
    },
    {
        path: '/RetentionRecommendation',
        element: <ReleaseOfRetentionRecommendation />,
    },
    {
        path: '/RetentionApproval',
        element: <ReleaseOfRetentionApproval />,
    }
];

export default releaseOfRetentionRoutes;