import { UPDATE_CURRENT_TAB } from "../actions/BSRActions";
import { GET_ASSIGNED_ROLE_LIST, GET_BSR_PROJECT_LIST, GET_CONSTRUCTION_LEVEL_STAGE_PERCENTAGE_LIST, GET_CONSTRUCTION_NAME_LIST_BY_PARAMS, GET_DZONGKHAG_LIST, GET_FUNDING_SOURCE_LIST, GET_ONGOING_PROJECT_LIST, GET_PENDING_HOPA_LIST, GET_PROJECT_IMPLEMENTATION_LIST, GET_PROJECT_LEVEL_STAGE_PERCENTAGE_LIST, GET_PROJECT_LOCATION_LIST, GET_PROJECT_WORK_PLAN_LIST, GET_REPORT_PROJECT_LOCATION_LIST, GET_ROLES_PROJECT, GET_USERS_PROJECT, GET_WORK_PLAN_LIST, RESET_PENDING_HOPA_LIST } from "../actions/ProjectLocationActions";
import { SHOW_LOADING } from "../actions/RoleActions";
import { GET_STAGE_INFORMATION_LIST } from "../actions/StageInformationAction";


const initialState = {
  projectLocationList: [],
  fundingSourceList: [],
  constructionNameList: [],
  workPlanList: [],
  projectLevelStagePercentage: [],
  stageInformationList: [],
  projectWorkPlanList: [],
  constructionLevelStagePercentage: [],
  isLoading: false,
  userList: [],
  roleList: [],
  currentProjectLocationTab: 0,
  dzongkhagList: [],
  assignedProjectList: [],
  bsrProjectList: [],
  ongoingProjectList: [],
  pendingHopaUserList: [],
  projectImplementations: [],
  reportsProjectLocationList: []
};

const ProjectLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONGOING_PROJECT_LIST: {
      return {
        ...state,
        ongoingProjectList: [...action.payload],
      };
    }
    case GET_PROJECT_IMPLEMENTATION_LIST: {
      console.log("results:", action.payload)
      return {
        ...state,
        isLoading: false,
        projectImplementations: action.payload,
      }
    }

    case GET_BSR_PROJECT_LIST: {
      return {
        ...state,
        bsrProjectList: [...action.payload],
      };
    }

    case GET_PENDING_HOPA_LIST: {
      return {
        ...state,
        pendingHopaUserList: [...action.payload],
      };
    }
    case RESET_PENDING_HOPA_LIST: {
      return {
        ...state,
        pendingHopaUserList: [],
      };
    }

    case GET_ASSIGNED_ROLE_LIST: {
      return {
        ...state,
        isLoading: false,
        assignedProjectList: [...action.payload],
      };
    }

    case GET_ROLES_PROJECT: {
      return {
        ...state,
        isLoading: false,
        roleList: [...action.payload],
      };
    }

    case GET_STAGE_INFORMATION_LIST: {
      return {
        ...state,
        isLoading: false,
        stageInformationList: [...action.payload],
      };
    }

    case GET_CONSTRUCTION_LEVEL_STAGE_PERCENTAGE_LIST: {
      return {
        ...state,
        isLoading: false,
        constructionLevelStagePercentage: [...action.payload],
      };
    }

    case GET_WORK_PLAN_LIST: {
      return {
        ...state,
        isLoading: false,
        workPlanList: [...action.payload],
      };
    }

    case GET_PROJECT_WORK_PLAN_LIST: {
      return {
        ...state,
        isLoading: false,
        projectWorkPlanList: [...action.payload],
      };
    }

    case GET_PROJECT_LEVEL_STAGE_PERCENTAGE_LIST: {
      return {
        ...state,
        isLoading: false,
        projectLevelStagePercentage: [...action.payload],
      };
    }

    case GET_USERS_PROJECT: {
      return {
        ...state,
        isLoading: false,
        userList: [...action.payload],
      };
    }

    case GET_PROJECT_LOCATION_LIST: {
      return {
        ...state,
        isLoading: false,
        projectLocationList: [...action.payload],
      };
    }
    case GET_REPORT_PROJECT_LOCATION_LIST: {
      return {
        ...state,
        isLoading: false,
        reportsProjectLocationList: [...action.payload],
      };
    }

    case GET_CONSTRUCTION_NAME_LIST_BY_PARAMS: {
      return {
        ...state,
        constructionNameList: [...action.payload]
      }
    }

    case GET_FUNDING_SOURCE_LIST: {
      return {
        ...state,
        isLoading: false,
        fundingSourceList: [...action.payload],
      };
    }

    case GET_DZONGKHAG_LIST: {
      return {
        ...state,
        isLoading: false,
        dzongkhagList: [...action.payload],
      };
    }

    case SHOW_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case UPDATE_CURRENT_TAB: {
      return {
        ...state,
        currentProjectLocationTab: action.payload.currentTab
      };
    }
    default: {
      state.currentProjectLocationTab = 0
      return { ...state };
    }
  }
};

export default ProjectLocationReducer;
