import { GET_BSR_PROJECT_LIST } from "../actions/ProjectLocationActions";
import { GET_ALL_BUILDING_TYPE } from "../actions/QuickCostActions";
import { UPDATE_CURRENT_QUICK_COST_GENERAL_TAB } from "../actions/QuickCostGeneralActions";


const initialState = {
    currentQuickCostGeneralTab: 0,
    projectLocationList: [],
    buildingTypeList: []
}

const QuickCostGeneralReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_QUICK_COST_GENERAL_TAB: {
            return {
                ...state,
                currentQuickCostGeneralTab: action.payload.currentTab
            };
        }
        case GET_ALL_BUILDING_TYPE: {
            return {
                ...state,
                buildingTypeList: [...action.payload]
            };
        }
        case GET_BSR_PROJECT_LIST: {
            return {
                ...state,
                projectLocationList: [...action.payload]
            };
        }
        default: {
            return { ...state }
        }
    }
};

export default QuickCostGeneralReducer;
