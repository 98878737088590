import { GET_ALL_BASE_LOCATION } from "../actions/BSRActions";
import { GET_ALL_BUILDING_CONSTRUCTION, GET_ALL_BUILDING_TYPE, UPDATE_CURRENT_QUICK_COST_TAB } from "../actions/QuickCostActions";


const initialState = {
    currentQuickCostTab: 0,
    baseLocationList: []
}

const QuickCostReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_QUICK_COST_TAB: {
            return {
                ...state,
                currentQuickCostTab: action.payload.currentTab
            };
        }
        case GET_ALL_BASE_LOCATION: {
            return {
                ...state,
                baseLocationList: [...action.payload]
            };
        }
        case GET_ALL_BUILDING_CONSTRUCTION: {
            return {
                ...state,
                buildingConstructionList: [...action.payload]
            };
        }
        case GET_ALL_BUILDING_TYPE:{
            return {
                ...state,
                buildingTypeList: [...action.payload]
            };
        }
        default: {
            return { ...state }
        }
    }
};

export default QuickCostReducer;
