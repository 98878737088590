import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const StageInformation = Loadable(lazy(() => import('./StageInformation')));

const stageInformationRoutes = [
    {
      path: '/stageInformation',
      element: <StageInformation/>,
    }
  ];
  
  export default stageInformationRoutes;