import Loadable from 'app/components/Loadable';
import React from 'react';
import { lazy } from 'react';

const HiringAgency = Loadable(lazy(() => import('./HiringAgency')));
const HiringAgencyRegistry = Loadable(lazy(() => import('./HiringAgencyRegistry')));
const MachineryRegistry = Loadable(lazy(() => import('./MachineryRegistry')));
const MaterialReceipt = Loadable(lazy(() => import('./MaterialReceipt')));
const HiringBillVerification = Loadable(lazy(() => import('./HiringBillVerification')));
const HiringBillReview = Loadable(lazy(() => import('./HiringBillReview')));
const HiringBillApproval = Loadable(lazy(() => import('./HiringBillApproval')));
const SupplyOrderVerification = Loadable(lazy(() => import('./SupplyOrderVerification')));
const SupplyOrderApproval = Loadable(lazy(() => import('./SupplyOrderApproval')));


const hiringAgencyRoutes = [
    {
        path: '/HiringAgency',
        element: <HiringAgencyRegistry />,
    }, {
        path: '/HiringBill',
        element: <HiringAgency />,
    },
    {
        path: '/MachineryRegistry',
        element: <MachineryRegistry />,
    },
    {
        path: '/MaterialReceipt',
        element: <MaterialReceipt />
    },
    {
        path: '/HiringBillVerification',
        element: <HiringBillVerification />
    },
    {
        path: '/HiringBillReview',
        element: <HiringBillReview />
    },
    {
        path: '/HiringBillApproval',
        element: <HiringBillApproval />
    },
    {
        path: '/SupplyOrderVerification',
        element: <SupplyOrderVerification />
    },
    {
        path: '/SupplyOrderApproval',
        element: <SupplyOrderApproval />
    }

];

export default hiringAgencyRoutes;