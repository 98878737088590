import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import AdvancePaymentRecommendation from './AdvancePaymentRecommendation';

const AdvancePaymentRequest = Loadable(lazy(() => import('./AdvancePaymentRequest')));
const AdvancePaymentVerification = Loadable(lazy(() => import('./AdvancePaymentVerification')));
const AdvancePaymentApproval = Loadable(lazy(() => import('./AdvancePaymentApproval')));
const AdvancePaymentRecovery = Loadable(lazy(() => import('./AdvancePaymentRecovery')));


const advancePaymentRoutes = [
  {
    path: '/APRequest',
    element: <AdvancePaymentRequest />,
  },
  {
    path: '/APVerification',
    element: <AdvancePaymentVerification />,
  },
  {
    path: '/APApproval',
    element: <AdvancePaymentApproval />
  },
  {
    path: '/AdvanceRecovery',
    element: <AdvancePaymentRecovery />
  },
  {
    path: '/APRecommendation',
    element: <AdvancePaymentRecommendation />
  }
];

export default advancePaymentRoutes;