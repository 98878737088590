import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ParameterConfiguration = Loadable(lazy(() => import('./ParameterConfiguration')));
const LiquidityDamage = Loadable(lazy(() => import('./LiquidityDamage')));
const TDS = Loadable(lazy(() => import('./TDS')));
const LDLimit = Loadable(lazy(() => import('./LDLimit')));
const InitialContract = Loadable(lazy(() => import('./InitialContract')));
const Retention = Loadable(lazy(() => import('./Retention')));
const Variance = Loadable(lazy(() => import('./Variance')));

const parameterConfigurationRoutes = [
    {
        path: '/parameterConfiguration',
        element: <ParameterConfiguration />
    },
    {
        path: '/liquidityDamage',
        element: <LiquidityDamage />
    },
    {
        path: '/tds',
        element: <TDS />
    },{
        path: '/ldLimit',
        element: <LDLimit />
    },
    {
        path: '/initialContract',
        element: <InitialContract />
    },
    {
        path: '/retention',
        element: <Retention />
    },
    {
        path: '/variance',
        element: <Variance />
    }


];

export default parameterConfigurationRoutes;
