import { GET_AGENCY_LOGO, GET_ALL_AGENCY_LIST_BY_ID, GET_ALL_AGENCY_TYPE_LIST, GET_ALL_DEPARTMENT_LIST, GET_ALL_DIVISION_LIST, GET_ALL_PARENT_AGENCY_LIST, GET_ALL_SECTION_LIST, SAVE_AGENCY_LOGO, SAVE_UPDATE_AGENCY_DETAILS, UPDATE_CURRENT_AGENCY_TAB } from "../actions/AgencyActions";
  
  const initialState = {
    logoList: [],
    parentAgencyList: [],
    agencyTypeList: [],
    
    agencyList: [],
    success: false,
    isLoading: false,
    currentAgencyTab: 0,
    departmentList: [],
    divisionList: [],
    sectionList: [],
  };
  
  const AgencyReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CURRENT_AGENCY_TAB: {
        return {
            ...state,
            currentAgencyTab: action.payload.currentAgencyTab
          };
      }
      case GET_ALL_AGENCY_TYPE_LIST: {
        return {
            ...state,
            agencyTypeList: [...action.payload],
          };
      }

      case GET_ALL_SECTION_LIST: {
        return {
            ...state,
            sectionList: [...action.payload],
          };
      }

      case GET_ALL_DEPARTMENT_LIST: {
        return {
            ...state,
            departmentList: [...action.payload],
          };
      }

      case GET_ALL_DIVISION_LIST: {
        return {
            ...state,
            divisionList: [...action.payload],
          };
      }

      case GET_ALL_PARENT_AGENCY_LIST: {
        return {
            ...state,
            parentAgencyList: [...action.payload],
          };
      }

      case SAVE_AGENCY_LOGO: {
        const newArray = [...state.logoList];
        if (action.payload.feature === "Add") {
          newArray.push(action.payload.logoList)
        } else {
          if (action.payload.logoList.agencylogoId !== null || action.payload.logoList.agencylogoId !== undefined) {
            var index = newArray.findIndex(x => x.agencylogoId === action.payload.logoList.agencylogoId);
            console.log('updated logo list new index', index)
            newArray[index] = action.payload.logoList
          } else {
            var index = newArray.findIndex(x => x.logo_name === action.payload.logoList.logo_name || x.header_name === action.payload.logoList.header_name || x.footer_name === action.payload.logoList.footer_name);
            console.log('updated logo list new index while id is nil', index)
            newArray[index] = action.payload.logoList
          }
          // var index = newArray.indexOf(action.payload.logoList);
          // console.log('updated logo list new index', index)

          // if (index > -1) {
          //   newArray.splice(index, 1);
          // }
        }
        console.log('updated logo list new array', newArray)

        return {
            ...state,
            logoList: newArray
          };
      }

      case GET_AGENCY_LOGO: {
        return {
            ...state,
            logoList: [...action.payload]
          };
      }
      
      case GET_ALL_AGENCY_LIST_BY_ID: {
        return {
            ...state,
            isLoading: false,
            agencyList: [...action.payload],
          };
      }
 
      case SAVE_UPDATE_AGENCY_DETAILS: {
        return {
          ...state,
          success: action.payload.success
        };
      }
     
      default: {
        state.currentAgencyTab = 0
        return {...state}
      }
    }
  };
  
  export default AgencyReducer;
  