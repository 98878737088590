import { Backdrop, Button, CircularProgress, Dialog, styled } from '@mui/material';
import { MatxLoading } from '.';

const DialogBox = styled('div')(() => ({
  width: 100,
  padding: '32px',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: 'transparent'
}));

const   Loader = ({show, text}) => {
  return (
    <Dialog maxWidth="lg" open={show}>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
>
  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} 
>
    <CircularProgress   color="inherit" />
    <h4 style={{marginTop: 15,fontFamily: "Arial, sans-serif"}}>{text}</h4>
  </div>
</Backdrop>
</Dialog>
  );
};

export default Loader;
