import { Button, Card, Grid, Icon, IconButton, MenuItem, TextField, ThemeProvider, Tooltip, createTheme, styled } from "@mui/material";
import { Box } from "@mui/system";
import { Breadcrumb, Loader, SimpleCard } from "app/components";
import { FlexBox } from "app/components/FlexBox";
import { getAllAdvancePaymentListByProjectLocationIdOrStatus, getAllProjectLocationListForContractDetails } from "app/redux/actions/ImplementationActions";
import { AppConstants } from "app/utils/AppConstants";
import { ProjectStatus } from "app/utils/ProjectStatus";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import AdvancePaymentVerificationDialog from "./AdvancePaymentVerificationDialog";
import { checkValidation, currencyFormat } from "app/utils/utils";


const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "10px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "10px" },
    },
}));

const MyCard = styled(Card)(() => ({
    "& thead": { "& th": { padding: 10 } },
    "& td": { borderBottom: "none" },
    "& td": { padding: "5px !important", paddingLeft: "10px !important" },
}));

const DecoratedTextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));

const theme = createTheme({

    components: {
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    backgroundColor: "#87CEEB",
                }
            }
        }
    }
});

const AdvancePaymentRecommendation = () => {

    const [dialogTitle, setdialogTitle] = useState('');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [advancePayment, setAdvancePayment] = useState({})
    const [showAdvancePaymentList, setShowAdvancePaymentList] = useState(false)

    const { projectLocationList, constructionNameList, advancePaymentRequestList, advanceTypeList } = useSelector((state) => state.implementation);
    const [shouldOpenAdvancePaymentVerificationDialog, setShouldOpenAdvancePaymentVerificationDialog] = useState(false)

    const [state, setState] = useState({
        project_name: '',
        project_location_id: '',
        project_location: '',
        project_id: '',
        constructionname_id: '',
        construction_name: '',
        completion_date: ''
    })


    const columns = [
        {
            name: "index",
            label: "Sl No.",
            options: {
                filter: false,
                sort: false,
                Headers: "bold",
                customBodyRenderLite: (dataIndex) => dataIndex + 1
            },
        },
        {
            name: "advancetype_name",
            label: "Advance Type",
            options: { filter: true, sort: false },
        },
        {
            name: "advance_no",
            label: "Advance No",
            options: {
                filter: true, sort: false,

            },
        },
        {
            name: "advance_date",
            label: "Advance Date",
            options: {
                filter: false,
                sort: false,
                Headers: "bold",
                customBodyRenderLite: (dataIndex) => formatStartDate(advancePaymentRequestList[dataIndex].advance_date)
            },
        },

        {
            name: "verified_on",
            label: "Verified Date",
            options: {
                filter: false,
                sort: false,
                Headers: "bold",
                customBodyRenderLite: (dataIndex) => checkValidation(advancePaymentRequestList[dataIndex].verified_on) ? formatStartDate(advancePaymentRequestList[dataIndex].verified_on) : ""
            },
        },
        {
            name: "approved_date",
            label: "Approved Date",
            options: {
                filter: false,
                sort: false,
                Headers: "bold",
                customBodyRenderLite: (dataIndex) => !checkValidation(advancePaymentRequestList[dataIndex].approved_on) ? "" : formatStartDate(advancePaymentRequestList[dataIndex].approved_on)
            },
        },
        {
            name: "recommended_amount",
            label: "Recommended Amount (Nu)",
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({
                    style: { textAlign: 'right' }
                }),
                setCellProps: () => ({
                    style: {
                        textAlign: 'right',
                    },
                }),
                customBodyRender: (value) => (
                    <div style={{ textAlign: 'right' }}>
                        {currencyFormat(value)}&nbsp;&nbsp;
                    </div>
                )
            },
        }, {
            name: "status_name",
            label: "Status",
            options: {
                filter: true,
                customBodyRenderLite: (dataIndex) => {
                    const status = advancePaymentRequestList[dataIndex].status_id === ProjectStatus.REVIEWED ? 'PENDING' : advancePaymentRequestList[dataIndex].status_name; // Replace this with your actual status data
                    return (
                        <span className={status === "PENDING" ? "status-cell" : ""}>
                            {status}
                        </span>
                    );
                },
            },
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => (
                    <FlexBox alignItems="center">
                        {advancePaymentRequestList[dataIndex].status_id === ProjectStatus.REVIEWED && (
                            <Tooltip title="Verification">
                                <IconButton onClick={() => handleClickReview(advancePaymentRequestList[dataIndex])}>
                                    <Icon color="primary" fontSize="small">
                                        domain_verification
                                    </Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </FlexBox>
                ),
            },
        }

    ];

    const handleClickReview = (advancePayment) => {
        console.log('viewed')
        if (advancePayment) {
            setAdvancePayment(advancePayment)
            setShouldOpenAdvancePaymentVerificationDialog(true)
        } else {
            const key = enqueueSnackbar(AppConstants.somethingWentWrong, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);
        }
    }

    useEffect(() => {
        dispatch(getAllProjectLocationListForContractDetails(showLoader, hideLoader, showToast))
    }, []);

    const clearDatas = () => {
        setShowAdvancePaymentList(false)
    }

    const handleChange = (event, source) => {
        clearDatas()
        if (event.target.name === "project_id") {
            const filteredArray = projectLocationList.filter(project => {
                return project.project_id === event.target.value
            });
            console.log('selected', filteredArray)
            state.project_name = filteredArray[0].project_name
            state.project_location_id = filteredArray[0].project_location_id
            state.project_location = filteredArray[0].project_location
            setState({ ...state, [event.target.name]: event.target.value });
            // dispatch(getAllConstructionNameListByParams(0, filteredArray[0].project_location_id, showLoader, hideLoader, showToast))
        } else if (event.target.name === "construction_name") {
            const filteredArray = constructionNameList.filter(construction => {
                return construction.construction_name === event.target.value
            });
            console.log('selected', filteredArray)
            state.constructionname_id = filteredArray[0].constructionname_id
            state.construction_name = filteredArray[0].construction_name
            setState({ ...state, [event.target.name]: event.target.value });

        } else {
            
            setState({ ...state, [event.target.name]: event.target.value });
        }
    }

    const handleFormSubmit = () => {
        getAllAdvancePaymentList(state?.project_location_id, state?.constructionname_id)
    }

    const showLoader = () => {
        setLoading(true)
    }

    const hideLoader = () => {
        setLoading(false)
    }

    const showToast = (msg) => {
        const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
    }


    const getAllAdvancePaymentList = (project_location_id, constructionname_id) => {
        dispatch(getAllAdvancePaymentListByProjectLocationIdOrStatus(project_location_id, 0, handleAdvancePaymentSuccess, showLoader, hideLoader, showToast, true))
    }

    const handleAdvancePaymentSuccess = () => {
        setShowAdvancePaymentList(true)
    }


    function formatStartDate(date) {
        const customDate = new Date(date)

        return moment(customDate).format('DD-MM-YYYY');
    }

    const handleAdvancePaymentVerificationDialogClose = () => {
        setShouldOpenAdvancePaymentVerificationDialog(false)
    }

    const handleAdvancePaymentVerificationDialogSuccess = () => {
        setShouldOpenAdvancePaymentVerificationDialog(false)
        getAllAdvancePaymentList(state?.project_location_id, state?.constructionname_id)

    }

    return (
        <Container>
            <div className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Advance Payment", path: "#" }, { name: "Payment Recommendation" }]} />
            </div>
            <SimpleCard>
                <ValidatorForm onSubmit={handleFormSubmit}>
                    <Grid container spacing={3} sx={{ marginLeft: 0.5 }}>

                        <Grid item sm={4} xs={12}>
                            <TextField
                                select
                                size="small"
                                fullWidth
                                sx={{ mb: 3 }}
                                label="Project ID"
                                id="project_id"
                                variant="outlined"
                                name="project_id"
                                value={state?.project_id}
                                onChange={handleChange}
                                validators={["required"]}
                                errorMessages={["Project ID is required"]}
                            >
                                {projectLocationList?.map((item) => (
                                    <MenuItem value={item?.project_id} key={item?.project_location_id}>
                                        {item?.project_id}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item sm={4} xs={12}>

                            <TextField
                                size="small"
                                disabled
                                fullWidth
                                sx={{ mb: 3 }}
                                value={state?.project_name}
                                label="Project Name"
                                id="project_name"
                                variant="outlined"
                                name="project_name"
                            >
                            </TextField>

                        </Grid>
                        <Grid item sm={2} xs={12}>
                            <Box component="span" display="flex" justifyContent="flex-start" >
                                <Button size="small" color="success" variant="contained" type="submit" >View</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </SimpleCard>
            <br />

            {showAdvancePaymentList && (
                <div className="dv_advance_payment">
                    <ValidatorForm>
                        <MyCard sx={{ width: "100%", overflow: "auto" }} elevation={6}>
                            <ThemeProvider theme={theme}>
                                <MUIDataTable
                                    sx={{
                                        backgroundColor: "yellow",
                                        borderBottom: "2px solid black",
                                        "& th": {
                                            fontSize: "1.25rem",
                                            color: "rgba(96, 96, 96)"
                                        }
                                    }}
                                    data={advancePaymentRequestList}
                                    columns={columns}
                                    title={""}
                                    options={{
                                        fixedHeader: true,
                                        sort: false,
                                        responsive: 'scroll',
                                        selectableRows: "none", // set checkbox for each row
                                        filterType: "textField",
                                        pagination: false, //set pagination option
                                        search: false,
                                        print: false,
                                        filter: false,
                                        download: false,
                                        viewColumns: false,
                                    }}
                                />
                            </ThemeProvider>
                        </MyCard>
                    </ValidatorForm>
                </div>
            )}
            <br />
            {shouldOpenAdvancePaymentVerificationDialog && (
                <AdvancePaymentVerificationDialog
                    title={dialogTitle}
                    project={state}
                    advancePayment={advancePayment}
                    open={shouldOpenAdvancePaymentVerificationDialog}
                    handleClose={handleAdvancePaymentVerificationDialogClose}
                    handleSuccess={handleAdvancePaymentVerificationDialogSuccess}
                />
            )}

            {loading && (
                <Loader
                    show={loading}
                />
            )}
        </Container>
    );
};

export default AdvancePaymentRecommendation;


