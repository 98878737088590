import { GET_CONSTRUCTION_TYPE } from "../actions/ConstructionActions";
import { GET_ADVANCE_PAYMENT_RECEIPT_LIST, GET_ADVANCE_PAYMENT_REQUEST_LIST, GET_ADVANCE_TYPE_LIST, GET_GOODS_RECEIPT_LIST_VERIFICATION, GET_PROJECT_LOCATION_LIST_CONTRACT } from "../actions/ImplementationActions";
import { GET_ITEM_CATALOGUE_LIST_PAYMENT } from "../actions/InvenoryActions";
import { GET_CONSTRUCTION_LEVEL_STAGE_PERCENTAGE_LIST, GET_CONSTRUCTION_NAME_LIST_BY_PARAMS } from "../actions/ProjectLocationActions";


const initialState = {
    projectLocationList: [],
    constructionTypeList: [],
    constructionNameList: [],
    constructionLevelStagePercentage: [],
    advancePaymentRequestList: [],
    advanceTypeList: [],
    receiptList: [],
    goodsReceiptList: []
};

const ImplementationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GOODS_RECEIPT_LIST_VERIFICATION: {
            return {
                ...state,
                goodsReceiptList: [...action.payload],
            };
        }
        case GET_ADVANCE_PAYMENT_RECEIPT_LIST: {
            return {
                ...state,
                receiptList: [...action.payload],
            };
        }
        case GET_ITEM_CATALOGUE_LIST_PAYMENT: {
            return {
                ...state,
                itemCatalogueList: [...action.payload],
            };
        }

        case GET_PROJECT_LOCATION_LIST_CONTRACT: {
            return {
                ...state,
                projectLocationList: [...action.payload],
            };
        }

        case GET_ADVANCE_PAYMENT_REQUEST_LIST: {
            return {
                ...state,
                advancePaymentRequestList: [...action.payload],
            };
        }
        case GET_ADVANCE_TYPE_LIST: {
            return {
                ...state,
                advanceTypeList: [...action.payload],
            };
        }

        case GET_CONSTRUCTION_NAME_LIST_BY_PARAMS: {
            return {
                ...state,
                constructionNameList: [...action.payload],
            };
        }

        case GET_CONSTRUCTION_LEVEL_STAGE_PERCENTAGE_LIST: {
            return {
                ...state,
                isLoading: false,
                constructionLevelStagePercentage: [...action.payload],
            };
        }

        case GET_CONSTRUCTION_TYPE: {
            return {
                ...state,
                constructionTypeList: [...action.payload],
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default ImplementationReducer;
