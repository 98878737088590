import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PublishBSR = Loadable(lazy(() => import('./PublishBSR')));

const publishBSRRoutes = [
  {
    path: '/publishBSR',
    element: <PublishBSR/>,
  }
];

export default publishBSRRoutes;