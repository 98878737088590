export const ProjectStatus = {
    ALL: 0,
    YES: 1,
    NO: 2,
    CREATED: 3,
    SUBMITTED: 4,
    RECOMMENDED: 5,
    REVIEWED: 6,
    APPROVED: 7,
    REJECTED: 8,
    PUBLISHED: 9,
    EDITED: 10,
    CLOSED: 11,
    EGP: 12,
    SENT_FOR_REVIEW: 13,
    REJECTED_BY_RECOMMENDER: 14,
    REJECTED_BY_APPROVER: 15,
    CANCELLED: 16
}

export const BsrType = {
    BML: 'BML',
    BUILT_UP: 'BUILT_UP'
}

export const UserRole = {
    ADMIN: 1,
    GENERAL_USER: 2,
    BSR_FOCAL: 3,
    HOPA: 4,
    ESTIMATOR: 5,
    PROJECT_ENGINEER: 6,
    PROJECT_MANAGER: 7,
    PROJECT_DIRECTOR: 8,
    CONTRACTOR: 9,
    DFDFD: 14,
    SUPPLIER: 10,
    AUDIT: 12,
    NEW_ROLE: 17,
    VERIFIER: 11
}