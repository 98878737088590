import { ServiceUrl } from "app/utils/Constants";
import axios from "axios";

export const CHECK_EMAIL = 'CHECK_EMAIL';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';



export const checkEmailAvailability = (email) => {
   return axios.get(ServiceUrl.checkEmailAvailability + email, {})
}

export const callUserRegistration = (params) => {
   return axios.post(ServiceUrl.signup, params, {
      headers: {
         'Content-Type': 'application/json',
      }
   })
}

export const callUserRegistrationHopa = (params) => {
   const accessToken = window.localStorage.getItem('accessToken')
   return axios.post(ServiceUrl.signupForHopa, params, {
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${accessToken}`,
         'Content-Type': 'multipart/form-data'
      }
   })
};

export const getGeneratedToken = (value) => {
   return axios.post(`${ServiceUrl.generateToken}${value}/generateToken`, {}, {
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
      }
   })
};
//external/auth/generateToken
// export const getGeneratedToken = (params) => {
//    const formData = new URLSearchParams();
//    formData.append('grant_type', "client_credentials");

//    return axios.post(`${ServiceUrl.generateToken} `, formData, {
//       headers: {
//          'Authorization': `${params?.bearer} `,
//          'Content-Type': 'application/x-www-form-urlencoded'
//       }
//    })

// return fetch(`${ ServiceUrl.tokenBaseURL }${ ServiceUrl.generateToken } `, {
//    method: "POST",
//    body: formData,
//    headers: {
//       'Authorization': `${ params?.bearer } `,
//       'Content-Type': 'application/x-www-form-urlencoded'
//    }
// })
//};

export const validateCIDNumber = (params, cidNumber) => {
   var token = 'Bearer ' + params?.access_token
   return axios.get(`${ServiceUrl.validateCIDNumber}${cidNumber} `, {
      headers: {
         'Authorization': token,
      }
   })
};

export const callForgotPassword = (cid_workpermit, usernameOrEmail) => {
   return axios.post(ServiceUrl.forgotPassword, { cid_workpermit, usernameOrEmail }, {
      headers: {
         'Content-Type': 'application/json'
      }
   })
}


