import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const BuiltUpItemLMC = Loadable(lazy(() => import('./BuiltUpItemLMC')));

const builtUpItemLMCRoutes = [
    {
      path: '/builtUpItemLMC',
      element: <BuiltUpItemLMC/>,
    }
  ];
  
  export default builtUpItemLMCRoutes;